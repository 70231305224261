import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {store} from './store.js';
import {configureAxios} from './axios';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createBrowserHistory} from 'history';
import {configureAmplify} from './amplify';

const progressScript = document.createElement('script');
progressScript.src = '/assets/UnityProjectDesigner/TemplateData/UnityProgress.js';
document.head.appendChild(progressScript);

export const history = createBrowserHistory();
const location = window.location;
const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) {
  history.replace(path);
}

configureAxios();
configureAmplify();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
