import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import './projectCard.scss';
import routes from '../routes';
import {generatePath, useHistory} from 'react-router';

const decimalsInCoordinates = 3;

function formatLocation(projectLocation) {
  if (!projectLocation) return ('Lat: - | Long: -');
  return `Lat: ${formatCoordinate(projectLocation.lat)} | Long: ${formatCoordinate(projectLocation.lng)}`;
}

function formatCoordinate(coordinate) {
  if (!coordinate || isNaN(coordinate)) return '-';
  try {
    coordinate = parseFloat(coordinate);
    return coordinate.toFixed(decimalsInCoordinates);
  } catch {
    return '-';
  }
}
export const ProjectCard = ({ project, loadProject }) => {
  const history = useHistory();
  let settingsName = '';
  if (project.settings?.name) settingsName = project.settings.name;
  let projectLocationName = '';
  if (project.projectLocation?.name) projectLocationName = project.projectLocation.name;
  let image = '/images/example-site-thumbnail.png';
  if (project.settings?.image) image = project.settings?.image;

  const loadProjectHandler = () => {
    loadProject(project).then(() => {
      history.push(generatePath(routes.designGenerateReport, {projectId: project.id}));
    });
  };

  return (
    <Card className="project-card" onClick={loadProjectHandler}>
      {/* Card.Img has no cropping option */}
      <div className="card-img-top project-card__image" style={{backgroundImage: `url(${image})`}} />
      <Card.Body>
        <span className="project-card__project-name">{settingsName}</span><br />
        <span className="project-card__location-name">{projectLocationName}</span>
      </Card.Body>
      <Card.Footer className="project-card__coordinates">{formatLocation(project.projectLocation)}</Card.Footer>
    </Card>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string,
    settings: PropTypes.object.isRequired,
    projectLocation: PropTypes.object.isRequired
  }).isRequired,
  loadProject: PropTypes.func.isRequired
};
