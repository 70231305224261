import React from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import './styles.scss';

const SelectedObject = ({selectedObject, onRotationChange, deleteObject, deleteAreaCorner}) => (
  <div className="submenu">
    <p className="section-title mt-2 mb-0">Selected object:</p>
    <div className="submenu__item">
      <div className="submenu__item__text">GUID:</div>
      <div className="submenu__item__text">{selectedObject.guid}</div>
    </div>
    <br/>
    { !selectedObject.cornerPoints &&
      <div className="submenu__item">
        <div className="submenu__item__text">Rotation:</div>
        <div className="submenu__item__input submenu__item__input--wide">
          <Form.Control
            plaintext
            type="number"
            value={Math.round(selectedObject.rotation)}
            name="rotation"
            onChange={onRotationChange}
          />
        </div>
      </div>
    }
    <div className="controls__row mt-5">
      <div className="controls__button controls__button--accent" onClick={() => deleteObject(selectedObject)}>
        <div className="controls__button__text">
          Delete
        </div>
      </div>
      { selectedObject.cornerPoints &&
        <div className="controls__button controls__button--accent" onClick={() => deleteAreaCorner(selectedObject)}>
          <div className="controls__button__text">
            Delete corner
          </div>
        </div>
      }
    </div>
  </div>
);

SelectedObject.propTypes = {
  selectedObject: PropTypes.object,
  onRotationChange: PropTypes.func,
  deleteObject: PropTypes.func,
  deleteAreaCorner: PropTypes.func
};

export default SelectedObject;
