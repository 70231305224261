import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './styles.scss';
import MenuHaltProduction from './MenuHaltProduction';
import MenuHaltProgress from './MenuHaltProgress';
import MenuHaltEmergency from './MenuHaltEmergency';


export class MenuInfoBase extends Component {

  render() {
    const {menuHaltProduction, menuHaltProgress, menuHaltEmergency} = this.props;

    return (<div className={'menu-info-wrapper'}>

      <div className="menu-info">
        {menuHaltProduction && <MenuHaltProduction />}
        {menuHaltProgress && <MenuHaltProgress />}
        {menuHaltEmergency && <MenuHaltEmergency />}
      </div>
    </div>
    );
  }
}

MenuInfoBase.propTypes = {
  menuHaltProduction: PropTypes.bool,
  menuHaltProgress: PropTypes.bool,
  menuHaltEmergency: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    menuHaltProduction: state.menu.menuHaltProduction,
    menuHaltProgress: state.menu.menuHaltProgress,
    menuHaltEmergency: state.menu.menuHaltEmergency,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuInfoBase);
