import canAutoPlay from 'can-autoplay';

export const checkVideoAutoPlay = (onSuccess, onError) => {
  // Some browsers block loading video in background without user interaction
  canAutoPlay
    .video({timeout: 500})
    .then(({result, error}) => {
      if(result === false) {
        console.log('Cannot autoplay video: ', error);
        onError();
      } else {
        console.log('Autoplay verified, loading video');
        onSuccess();
      }
    });
};
