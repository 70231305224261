import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import './styles.scss';
import {lightningIcon, keyIcon} from '../../svg';
import {menuActions} from '../../../store/actions/menu';
import {HeliostatActions} from '../../../store/actions/heliostat';

export class InnerMenuItem extends Component {

  handleClick = () => {
    const {id, dispatch} = this.props;
    dispatch(HeliostatActions.setActiveHeliostat(id));
    dispatch(menuActions.setMenuHeliostat());
  };

  render() {
    const {isActive, isRepair, label} = this.props;
    return (
      <div className={'inner-item'} onClick={this.handleClick.bind(this)}>
        <div
          className={isActive ? 'inner-item__text inner-item__text--active' : 'inner-item__text'}>
          {label}
        </div>
        <div className="inner-item__icons">
          {isActive ? isRepair ? <div>{keyIcon()}
            <div className={'green-circle'} />
          </div> : <div className={'green-circle'} /> : lightningIcon()}
        </div>
      </div>
    );
  }
}

InnerMenuItem.propTypes = {
  id: PropTypes.number,
  isActive: PropTypes.bool,
  isRepair: PropTypes.bool,
  label: PropTypes.string,
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    innerMenu: state.menu.innerMenu,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnerMenuItem);
