import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button, Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import './WizardStepMenuItem.scss';
import '../../styles/svg.scss';
import {useSelector} from 'react-redux';
import {generatePath} from 'react-router';

function getClassName(selected) {
  if (selected === true)
    return 'wizard-step-menu-item selected';
  return 'wizard-step-menu-item';
}

function getButtonClassName(selected) {
  if (selected === true)
    return 'wizard-step-menu-item__btn selected';
  return 'wizard-step-menu-item__btn';
}

function getTextClassName(selected) {
  if (selected === true)
    return 'wizard-step-menu-item__text selected';
  return 'wizard-step-menu-item__text';
}

function getIconClassName(selected) {
  if (selected === true)
    return 'accented-icon';
  return 'dim-icon';
}

export const WizardStepMenuItem = ({mdi, text, href, selected, isMenuCollapsed}) => {
  const projectId = useSelector(state => state.projects.projectId);
  if (!projectId) return <div/>;
  const fullHref = generatePath(href, {projectId});

  return (
    <Row className={getClassName(selected)}>
      <Col md={isMenuCollapsed ? 12 : 2} sm={isMenuCollapsed ? 12 : 2}>
        <Nav.Item>
          <LinkContainer to={fullHref}>
            <Button className={getButtonClassName(selected)}>
              <div className={getIconClassName(selected)}>{mdi}</div>
            </Button>
          </LinkContainer>
        </Nav.Item>
      </Col>
      {!isMenuCollapsed && (
        <Col sm={10} md={10} className="wizard-step-menu-item__text-link">
          <LinkContainer to={fullHref}>
            <Button variant="link"
              className={getTextClassName(selected)}>{text}</Button>
          </LinkContainer>
        </Col>
      )}
    </Row>
  );
};

WizardStepMenuItem.propTypes = {
  mdi: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  isMenuCollapsed: PropTypes.bool
};
