import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import TileItem from '../../../components/operate/TileItem/index';
import EmptyTileItem from '../../../components/operate/EmptyTileItem/index';
import './styles.scss';
import OperateMenu from '../../../containers/operate/menu/EnterMenu';
import {plantActions} from '../../../store/actions/plant';
import config from '../../../config';
import {overrideUrls} from '../../../helpers/dynamicConfig';
import CustomSpinner from '../../../components/operate/CustomSpinner';


export class OperateMain extends Component {

  componentDidMount() {
    this.props.dispatch(plantActions.getList());
  }

  selectPlant(plant) {
    if (plant.disabled === true) {
      alert('Selected plant is disabled');
      return false;
    }
    if (config.IS_DEMO) overrideUrls(plant.api_url, plant.ws_url, plant.api_key);
    return true;
  }

  render() {
    const {plants, loading, loadingError} = this.props;
    if (!plants) return <div />;

    const allPlants = [];
    if (plants.length < 6) {
      for (let i = 0; i < 6; i++) {
        if (plants[i]) {
          allPlants.push(plants[i]);
        } else {
          allPlants.push({});
        }
      }
    } else {
      for (let i = 0; i < plants.length; i++) {
        allPlants.push(plants[i]);
      }
    }

    return (
      <div className={'page'}>
        <OperateMenu />
        <div className={'tile-wrapper'}>
          <h1>
            Current plants&nbsp;
            {loading && <CustomSpinner size={24} color={'#212529'} />}
          </h1>
          {config.IS_DEMO &&
            <div style={{marginBottom: '20px'}}>
              You&apos;re in demo mode. We&apos;re collecting data from all servers so it could take longer than usual.
            </div>
          }
          {!loading && loadingError &&
            <div style={{marginBottom: '20px'}} className={'error-text'}>
              Something went wrong while loading plants: &nbsp;
              {loadingError.response?.data?.message || loadingError.toString()}
            </div>
          }
          {!loadingError &&
            <div className={'tile-grid'}>
              {allPlants.map((item, index) => {
                if (Object.keys(item).length === 0) {
                  return (<EmptyTileItem key={index}/>);
                }
                return (<TileItem item={item} key={index} beforeLinkTo={this.selectPlant.bind(this)}/>);
              })}
            </div>
          }
        </div>
      </div>
    );
  }
}

OperateMain.propTypes = {
  dispatch: PropTypes.func,
  plants: PropTypes.array,
  loading: PropTypes.bool,
  loadingError: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    plants: state.plant.plants,
    loading: state.plant.loadingPlants,
    loadingError: state.plant.loadingPlantsError,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OperateMain));
