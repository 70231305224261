import {plantConstants} from '../constants/plant';
import {plantTesConstants} from '../constants/plantTes';
import config from '../../config';

const initialStage = {
  loadingPlants: false,
  loadingPlantsError: null,
  plants: [],
  loadingPlant: false,
  loadingPlantError: null,
  plant: null,
  streams: [],
  selectedStream: 0,
  status: {},
  heliostats: [],
  tes: {
    modes: {},
    status: {}
  },
  selectedDevices: [],
  cloudImages: {
    images: [],
    currentDate: new Date(),
    isLoading: false
  }
};

export const getOptions = (valuesList) => {
  let options = [];
  for (let option of valuesList) {
    options.push({value: option.id, label: option.mode_name, isDisabled: option.is_readonly,
      config: option.config});
  }
  return options;
};

const prepareTesStatus = (status) => {
  return {
    ...status,
    global: status.global ? status.global[0]:{},
    camera: status.camera ? status.camera[0]:{}
  };
};

const mockTesStatus = (deviceName, newStatus) => {
  if (deviceName === 'tank') {
    newStatus[deviceName].fill_volume =  Math.random();
    newStatus[deviceName].average_temperature = (100 + (400 - 100) * Math.random()).toFixed(0);
  }
};

// TODO: tech debt: what if update will be received before initial status
const updateTesStatus = (status, statusUpdates) => {
  const newStatus = {
    ...status
  };
  const singleObjectKeys = ['global', 'camera'];
  for (let deviceName of Object.keys(statusUpdates)) {
    const newObject = statusUpdates[deviceName][0];
    if (singleObjectKeys.includes(deviceName)) {
      newStatus[deviceName] = newObject;
      if (config.MOCK_DATA) mockTesStatus(deviceName, newStatus);
    } else {
      const newObjects = [];
      let found = false;
      if (newStatus[deviceName]) {
        for (let object of newStatus[deviceName]) {
          if (object.object_id === newObject.object_id) {
            newObjects.push(newObject);
            found = true;
          } else newObjects.push(object);
          if (config.MOCK_DATA) mockTesStatus(deviceName, newStatus);
        }
        if (!found) newObjects.push(newObject);
        newStatus[deviceName] = newObjects;
      }
    }
  }
  return newStatus;
};

export const plant = (state = initialStage, action) => {
  let status = {};
  let isOnline;
  switch (action.type) {
    case plantConstants.GETTING_LIST:
      return {
        ...state,
        loadingPlants: true,
        loadingPlantsError: null
      };
    case plantConstants.GET_LIST_ERROR:
      return {
        ...state,
        loadingPlants: false,
        loadingPlantsError: action.payload
      };
    case plantConstants.GET_LIST_SUCCEEDED:
      return {
        ...state,
        plants: action.payload,
        loadingPlants: false,
        loadingPlantsError: null
      };
    case plantConstants.GET_DETAILS:
      return {
        ...state,
        loadingPlant: true,
        loadingPlantError: null
      };
    case plantConstants.GET_DETAILS_SUCCEEDED:
      return {
        ...state,
        loadingPlant: false,
        loadingPlantsError: null,
        streams: [],
        plant: action.payload,
        status: {}
      };
    case plantConstants.GET_DETAILS_ERROR:
      return {
        ...state,
        loadingPlant: false,
        loadingPlantsError: action.payload
      };

    case plantConstants.GET_STREAMS_SUCCEEDED:
      return {
        ...state,
        streams: action.payload,
        selectedStream: 0
      };
    case plantConstants.NEXT_STREAM:
      if (state.streams && state.streams.length === 0) return state;
      return {
        ...state,
        selectedStream: (state.selectedStream + 1) % state.streams.length
      };
    case plantConstants.GET_STATUS_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload
        }
      };
    case plantConstants.UPDATE_ONLINE:
      isOnline = action.payload;
      return {
        ...state,
        status: {
          ...state.status,
          online: isOnline
        }
      };
    case plantTesConstants.GET_MODES_SUCCEEDED:
      return {
        ...state,
        tes: {
          ...state.tes,
          modes: {
            operational: getOptions(action.payload['operational']),
            maintenance: getOptions(action.payload['maintenance'])
          }
        }
      };
    case plantTesConstants.GET_TES_STATUS_SUCCEEDED:
      // TODO: for MVP we showing very specified info but back-end is prepared for more complicated structures
      status = prepareTesStatus(action.payload);
      return {
        ...state,
        tes: {
          ...state.tes,
          status
        }
      };
    case plantTesConstants.UPDATE_TES_STATUS:
      status = updateTesStatus(state.tes.status, action.payload);
      return {
        ...state,
        tes: {
          ...state.tes,
          status
        }
      };
    case plantTesConstants.UPDATE_TES_ONLINE:
      isOnline = action.payload;
      return {
        ...state,
        tes: {
          ...state.tes,
          status: {
            ...state.tes.status,
            online: isOnline
          }
        }
      };
    case plantConstants.UPDATE_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload
        }
      };
    case plantTesConstants.SELECT_DEVICE:
      return {
        ...state,
        selectedDevices: [...state.selectedDevices, action.payload]
      };
    case plantTesConstants.UNSELECT_DEVICE:
      return {
        ...state,
        selectedDevices: state.selectedDevices.filter((deviceInfo) => (
          deviceInfo.type !== action.payload.type ||
            deviceInfo.device.object_id !== action.payload.device.object_id))
      };
    case plantConstants.GET_SKY_IMAGES:
      return {
        ...state,
        cloudImages: {
          ...state.cloudImages,
          isLoading: true
        }
      };
    case plantConstants.GET_SKY_IMAGES_SUCCEEDED:
      return {
        ...state,
        cloudImages: {
          ...state.cloudImages,
          isLoading: false,
          images: action.payload.files,
          currentDate: action.payload.date
        }
      };
    case plantConstants.GET_SKY_IMAGES_ERROR:
      return {
        ...state,
        cloudImages: {
          ...state.cloudImages,
          isLoading: false
        }
      };
    case plantConstants.NEW_SKY_IMAGES:
      return {
        ...state,
        cloudImages: {
          ...state.cloudImages,
          images: [...state.cloudImages.images, ...action.payload]
        }
      };
    default:
      return state;
  }
};

