import React, {Component} from 'react';
import {Button, Container, Form, Table} from 'react-bootstrap';
import EnergyHoursTable from '../../components/design/EnergyHoursTableRow';
import {priceByYear} from '../../helpers/priceByYear';
import {projectEnergyPropertyConstants} from '../../helpers/projectEnergyPropertyConstants';
import PropTypes from 'prop-types';
import routes from '../../routes';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {generatePath} from 'react-router-dom';
import {menuActions} from '../../store/actions/menu';
import {projectsActions} from '../../store/actions/projects';


export class EnergyUsage extends Component {

  componentDidMount() {
    this.props.dispatch(menuActions.setDesignMenuCollapse(false));
  }

  setStorageFeed = (min, max, data, value) => {
    for (let i = min; i <= max; i++) {
      data[i].storageFeed = value;
    }
  };

  calculate = (name, value, item, index) => {
    const {projectEnergyProperty, dispatch} = this.props;

    let newData = {...projectEnergyProperty || projectEnergyPropertyConstants};
    let newItem = {...item};

    newItem[name] = +value;
    if (name === 'year') {
      newData['price'] = priceByYear[value];
    }

    if (name === 'clientLoad' || name === 'renewable') {
      newData.hours[index] = newItem;
      for (let i = 0; i<=newData.hours.length-1; i++) {
        newData.hours[i].excessEnergy = newData.hours[i].renewable - newData.hours[i].clientLoad > 0 ?
          newData.hours[i].renewable - newData.hours[i].clientLoad : 0;
      }
    } else {
      newData[name] = +value;
    }

    Array.prototype.sum = function (prop) {
      let total = 0;
      for (let i = 0, _len = this.length; i < _len; i++) {
        total += +this[i][prop];
      }
      return total;
    };

    let excessEnergySum = newData.hours.sum('excessEnergy');

    let oneFourth = excessEnergySum / 4;
    this.setStorageFeed(18, 21, newData.hours, oneFourth);

    let clientLoadSum = newData.hours.sum('clientLoad');
    let renewableSum = newData.hours.sum('renewable');
    let storageFeedSum = newData.hours.sum('storageFeed');
    
    newData['totalExcessEnergy'] = excessEnergySum;
    newData['totalClientLoad'] = clientLoadSum;
    newData['totalRenewable'] = renewableSum;
    newData['totalStorageFeed'] = storageFeedSum;

    if (name === 'clientLoad' || name === 'renewable') {
      for (let i = 0; i <= newData.hours.length - 1; i++) {
        newData.hours[i].gridDemand = newData.hours[i].clientLoad - newData.hours[i].renewable - newData.hours[i].excessEnergy - newData.hours[i].storageFeed > 0 ? newData.hours[i].clientLoad - newData.hours[i].renewable - newData.hours[i].excessEnergy - newData.hours[i].storageFeed : 0;
      }
    }
    newData['totalGridDemand'] = newData.hours.sum('gridDemand');
    dispatch(projectsActions.updateProjectEnergyProperty(newData));
  };

  designSite = () => {
    const {projectId} = this.props;
    this.props.history.push(generatePath(routes.designSiteDesign, {projectId}));
  }

  render() {
    const {projectEnergyProperty, isReadonly} = this.props;
    return (
      <Container className="form-page">
        <Button onClick={this.designSite} style={{float: 'right', padding: '0.375rem 3.5rem'}}>
          Design the site
        </Button>
        <h1>Energy usage</h1>
        <Form>
          <Table borderless hover className={'custom-table'} size={'sm'}>
            <tbody>
              <tr>
                <td>Year</td>
                <td colSpan="2">
                  <Form.Control
                    as="select"
                    value={projectEnergyProperty ? projectEnergyProperty.year : projectEnergyPropertyConstants.year}
                    onChange={(event) => this.calculate('year', event.target.value)}
                    disabled={isReadonly}
                  >
                    <option>2020</option>
                    <option>2025</option>
                  </Form.Control>
                </td>
                <td>Select year</td>
              </tr>
              <tr>
                <td>Price</td>
                <td colSpan="2">
                  <Form.Control
                    type="number"
                    value={projectEnergyProperty ? projectEnergyProperty.price : projectEnergyPropertyConstants.price}
                    onChange={(event) => this.calculate('price', event.target.value)}
                    disabled={isReadonly}
                  />
                </td>
                <td>USD</td>
              </tr>
              <tr>
                <td>Charge Cost</td>
                <td colSpan="2">
                  <Form.Control
                    type="number"
                    value={projectEnergyProperty ? projectEnergyProperty.chargeCost : projectEnergyPropertyConstants.chargeCost}
                    onChange={(event) => this.calculate('chargeCost', event.target.value)}
                    disabled={isReadonly}
                  />
                </td>
                <td>USD/MWh</td>
              </tr>
              <tr>
                <td>Roundtrip Thermal Efficiency</td>
                <td colSpan="2">
                  <Form.Control
                    type="number"
                    value={projectEnergyProperty ? projectEnergyProperty.efficiency : projectEnergyPropertyConstants.efficiency}
                    min={0} max={100} disabled={isReadonly}
                    onChange={(event) => this.calculate('efficiency', event.target.value)}/>
                </td>
                <td>%</td>
              </tr>
              <tr>
                <td>Availability</td>
                <td colSpan="2">
                  <Form.Control
                    type="number"
                    value={projectEnergyProperty ? projectEnergyProperty.availability : projectEnergyPropertyConstants.availability}
                    onChange={(event) => this.calculate('availability', event.target.value)}
                    disabled={isReadonly}
                  />
                </td>
                <td>%</td>
              </tr>
              <tr>
                <td>Dual Cycle</td>
                <td colSpan="2">
                  <Form.Control
                    type="number"
                    value={projectEnergyProperty ? projectEnergyProperty.dualCycle : projectEnergyPropertyConstants.dualCycle}
                    onChange={(event) => this.calculate('dualCycle', event.target.value)}
                    disabled={isReadonly}
                  />
                </td>
                <td>%</td>
              </tr>
            </tbody>
          </Table>
        </Form>
        <div>
          <h2>Hourly usage</h2>
          <p>
            Fill in the hourly energy requirement. All values should be in MWh.
            Based on the statistical data, we know that the highest energy surge during the day happens
            between 18 and 21 so during that time we use storage as the energy source.
          </p><br/><br/>
        </div>
        <Form>
          <Table borderless hover className={'custom-table'} size={'sm'}>
            <thead>
              <tr>
                <th>time</th>
                <th>client energy usage</th>
                <th>renewable energy</th>
                <th>excess energy</th>
                <th>storage feed</th>
                <th>grid demand</th>
              </tr>
            </thead>
            <tbody>
              {
                projectEnergyProperty ? projectEnergyProperty.hours.map((item, index) => {
                  return <EnergyHoursTable key={index} hour={index} item={item} disabled={isReadonly}
                    calculate={(name, value) => this.calculate(name, value, item, index)}/>;
                }) : projectEnergyPropertyConstants.hours.map((item, index) => {
                  return <EnergyHoursTable key={index} hour={index} item={item} disabled={isReadonly}
                    calculate={(name, value) => this.calculate(name, value, item, index)}/>;
                })
              }
              <tr className={'total'}>
                <td>Total MWh</td>
                <td align={'center'}>{projectEnergyProperty ? projectEnergyProperty.totalClientLoad : projectEnergyPropertyConstants.totalClientLoad}</td>
                <td align={'center'}>{projectEnergyProperty ? projectEnergyProperty.totalRenewable : projectEnergyPropertyConstants.totalRenewable}</td>
                <td align={'center'}>{projectEnergyProperty ? projectEnergyProperty.totalExcessEnergy : projectEnergyPropertyConstants.totalExcessEnergy}</td>
                <td align={'center'}>{projectEnergyProperty ? projectEnergyProperty.totalStorageFeed : projectEnergyPropertyConstants.totalStorageFeed}</td>
                <td align={'center'}>{projectEnergyProperty ? projectEnergyProperty.totalGridDemand : projectEnergyPropertyConstants.totalGridDemand}</td>
              </tr>
            </tbody>
          </Table>
        </Form>
      </Container>
    );
  }
}

EnergyUsage.propTypes = {
  projectId: PropTypes.string,
  projectEnergyProperty: PropTypes.object.isRequired,
  isReadonly: PropTypes.bool,
  history: PropTypes.object,
  dispatch: PropTypes.func
};

const WrappedEnergyUsage = withRouter(EnergyUsage);

const mapStateToProps = (state) => {
  return {
    projectId: state.projects.projectId,
    projectEnergyProperty: state.projects.project?.projectEnergyProperty
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedEnergyUsage);
