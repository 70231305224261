import config from '../../config';

const initialState = {
  isDemo: config.IS_DEMO,
  isReadonly: config.IS_DEMO
};

export const general = (state = initialState) => {
  return state;
};
