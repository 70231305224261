import axios from 'axios';
import {heliostatUrls} from '../constants/heliostat';
import {getPlantId} from '../../helpers/store';


export const heliostatService = {
  getHeliostat,
  getHeliostatList,
  setMaintenanceMode,
  resumeProduction
};

function getHeliostat(id) {
  return axios.request({
    method: 'GET',
    url: `${heliostatUrls.GET_DETAIL}${id}?projectId=${getPlantId()}`
  });
}

function getHeliostatList() {
  return axios.request({
    method: 'GET',
    url: `${heliostatUrls.GET_LIST}?projectId=${getPlantId()}`
  });
}

function setMaintenanceMode(id) {
  return axios.request({
    method: 'POST',
    url: `${heliostatUrls.GET_DETAIL}${id}/set-maintenance-mode?projectId=${getPlantId()}`
  });
}

function resumeProduction(id) {
  return axios.request({
    method: 'POST',
    url: `${heliostatUrls.GET_DETAIL}${id}/resume-production?projectId=${getPlantId()}`
  });
}
