import React, {Component} from 'react';
import {Col, Container, Form, Image, Row, Table, Button} from 'react-bootstrap';
import {ReportCard} from '../../components/reportCard';
import values from '../../values';
import NumberFormat from 'react-number-format';
import {ProjectControlChecklistCard} from '../../components/ProjectControlChecklistCard';
import {calculationService} from '../../store/services/calculations';
import {calculationsHelper} from '../../helpers/calculations';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {menuActions} from '../../store/actions/menu';
import {projectsActions} from '../../store/actions/projects';
import {projectService} from '../../store/services/project';
import FileSaver from 'file-saver';
import {downloadFromUrl} from '../../helpers/utils';

const {lookupWeather, lookupWacc} = values;
const calcStat = {
  IDLE: 'IDLE',
  REQUESTED: 'REQUESTED',
  UPDATING: 'UPDATING',
  FINISHED: 'FINISHED'
};

const shouldCalculateAnnualProduction = props => (props.settings && props.site && props.settings.dni && !isNaN(props.settings.dni) && props.site.heliostats && !isNaN(props.site.heliostats)) === true;
const shouldCalculateCost = props => (props.site && props.site.towers && !isNaN(props.site.towers) && props.site.heliostats && !isNaN(props.site.heliostats)) === true;
const shouldCalculateLcoe = props => (props && props.settings && props.site && props.financial && props.site.annualProduction && !isNaN(props.site.annualProduction) && props.financial.totalCost && !isNaN(props.financial.totalCost) && !!props.settings.waccCategory) === true;
const isAnnualProductionCalculated = props => (props.site && props.site.annualProduction && !isNaN(props.site.annualProduction)) === true;
const isCostCalculated = props => (props.site && props.financial.totalCost && !isNaN(props.financial.totalCost)) === true;
const isLcoeCalculated = props => (props.site && props.site.lcoe && !isNaN(props.site.lcoe)) === true;

const isPendingStatus = status => status === calcStat.REQUESTED || status === calcStat.UPDATING;



export class GenerateReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calculateCostStatus: calcStat.IDLE,
      calculateAnnualProductionStatus: calcStat.IDLE,
      calculateLcoeStatus: calcStat.IDLE,
      lcos: 0,
      models: [],
      // TODO: split into separate for each button
      downloading: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    if (prevState.calculateCostStatus === calcStat.UPDATING && isCostCalculated(nextProps)) {
      newState.calculateCostStatus = calcStat.FINISHED;
    } else if (prevState.calculateCostStatus === calcStat.IDLE && shouldCalculateCost(nextProps)) {
      newState.calculateCostStatus = calcStat.REQUESTED;
    }

    if (prevState.calculateAnnualProductionStatus === calcStat.UPDATING && isAnnualProductionCalculated(nextProps)) {
      newState.calculateAnnualProductionStatus = calcStat.FINISHED;
    } else if (prevState.calculateAnnualProductionStatus === calcStat.IDLE && shouldCalculateAnnualProduction(nextProps)) {
      newState.calculateAnnualProductionStatus = calcStat.REQUESTED;
    }

    if (prevState.calculateLcoeStatus === calcStat.UPDATING && isLcoeCalculated(nextProps)) {
      newState.calculateLcoeStatus = calcStat.FINISHED;
    } else if (prevState.calculateLcoeStatus === calcStat.IDLE && shouldCalculateLcoe(nextProps)) {
      newState.calculateLcoeStatus = calcStat.REQUESTED;
    }

    return newState;
  }

  componentDidMount() {
    const {settings, projectEnergyProperty, dispatch} = this.props;
    try {
      const dataForRequest = calculationsHelper.getLcosRequestPayload(settings, projectEnergyProperty);
      calculationService.calculateLcos(dataForRequest).then(response => {
        this.setState({
          lcos: response.data.lcos
        });
      });
    } catch(e) {
      console.log('Cannot calculate LCOS:', e);
    }

    projectService.getModels(this.props.project.id).then(response => {
      let models_n = response.data.models.length;
      this.setState({
        models: response.data.models,
        isDownloading: new Array(models_n).fill(false),
        isBomDownloading: false
      });
    }).catch((error) => {
      console.log('Could not fetch 3d models from s3');
      console.log(error);
    });

    dispatch(menuActions.setDesignMenuCollapse(false));
  }

  componentDidUpdate() {
    const {settings, site, dispatch} = this.props;
    if (this.state.calculateCostStatus === calcStat.REQUESTED) {
      this.setState({calculateCostStatus: calcStat.UPDATING});
      const dataForRequest = calculationsHelper.getCostRequestPayload(site);
      calculationService.calculateCost(dataForRequest).then(response =>
        dispatch(projectsActions.updateProjectFinancials({totalCost: response.data.totalCost}))
      );
    }

    if (this.state.calculateAnnualProductionStatus === calcStat.REQUESTED) {
      this.setState({calculateAnnualProductionStatus: calcStat.UPDATING});
      let dataForRequest = calculationsHelper.getAnnualProductionRequestPayload(settings, site);
      calculationService.calculateAnnualProduction(dataForRequest).then(response => {
        let {estimatedAnnualProduction} = response.data;
        dispatch(projectsActions.updateProjectSite({annualProduction: estimatedAnnualProduction}));
      });
    }

    if (this.state.calculateLcoeStatus === calcStat.REQUESTED) {
      const {settings, site, financial} = this.props;
      const dataForRequest = calculationsHelper.getLcoeRequestPayload(settings, site, financial);
      if (dataForRequest) {
        this.setState({calculateLcoeStatus: calcStat.UPDATING});
        calculationService.calculateLcoe(dataForRequest).then(response =>
          dispatch(projectsActions.updateProjectSite({lcoe: response.data.lcoe}))
        );
      }
    }

    if (this.props.site.peakCapacity === undefined) {
      let dataForRequest = calculationsHelper.getPeakCapacityRequestPayload(site);
      const peakCapacity = calculationService.calculatePeakCapacity(dataForRequest);
      dispatch(projectsActions.updateProjectSite({peakCapacity}));
    }
  }

  onNotesChange = e => {
    this.props.dispatch(projectsActions.updateProjectSettings({note: e.target.value}));
  };

  render() {
    const totalCost = (this.props.financial || {}).totalCost || '-';
    const irrInPercent = (this.props.financial || {}).irrInPercent || '-';
    const interestInPercent = (this.props.financial || {}).interestInPercent || '-';
    const dni = (this.props.settings || {}).dni || '-';
    const annualProduction = isNaN((this.props.site || {}).annualProduction) ? '-' :
      (this.props.site.annualProduction / 1000).toFixed(2);
    const lcoe = (this.props.site || {}).lcoe;
    const {lcos, models} = this.state;
    const waccCategory = lookupWacc((this.props.settings || {}).waccCategory);
    console.log(waccCategory);
    const waccCategoryDisplay = waccCategory ? waccCategory.display : '-';
    const weatherCategory = lookupWeather((this.props.settings || {}).weatherCategory);
    const weatherCategoryDisplay = weatherCategory ? weatherCategory.display : '-';
    const latitude = (this.props.projectLocation || {}).lat || 0;
    const longitude = (this.props.projectLocation || {}).lng || 0;
    const locationName = (this.props.projectLocation || {}).name || '-';
    const projectName = (this.props.settings || {}).name || 'Untitled Project';
    const peakCapacity = (this.props.site || {}).peakCapacity;
    const checklist = this.props.checklist;
    let storageCapacity = '-';
    let storagePower = '-';
    if (this.props.projectEnergyProperty?.storageSize) {
      storageCapacity = this.props.projectEnergyProperty.storageSize;
      storagePower = 5;
    }
    let image = '/images/example-site-thumbnail.png';
    const isReadonly = this.props.settings.readonly;
    if (this.props.settings?.image) image = this.props.settings?.image;
    const note = this.props.settings.note;

    const setBomDownloading = (value) => {
      this.setState({ isBomDownloading: value });
    };

    const downloadBom = () => {
      console.log(this.props.project);
      setBomDownloading(true);
      projectService.getBomFile(this.props.project.id).then((resp) => {
        // TODO: get file name from Content-Disposition header
        FileSaver.saveAs(resp.data, 'designBOM.csv');
        setBomDownloading(false);
      }).catch((error) => {
        setBomDownloading(false);
        let message = 'Error while downloading BOM';
        console.log(message, error);
        alert(message);
      });
    };

    const setDownloading = (i, value) => {
      let isDownloading = [...this.state.isDownloading];
      isDownloading[i] = value;
      this.setState({ isDownloading: isDownloading });
    };

    const downloadModel = (i, modelName) => {
      setDownloading(i, true);
      projectService.getModelDownloadUrl(modelName).then((resp) => {
        downloadFromUrl(resp.data);
        setDownloading(i, false);
      }).catch((error) => {
        setDownloading(i, false);
        let message = 'Error while downloading model';
        console.log(message, error);
        alert(message);
      });
    };

    const BomRow = () => ( 'unityData' in this.props.project ?
      <tr>
        <td>BOM file</td>
        <td>
          <Button block className="design-menu-button design-menu-button__subtle button-to-left"
            onClick={() => downloadBom()} disabled={this.state.isBomDownloading}
            variant="link">{this.state.isBomDownloading ? 'Downloading...' : 'Download'}</Button>
        </td>
      </tr> : null
    );

    return (
      <Container className="form-page">
        <h1>{projectName} Report</h1>
        {}
        <Row>
          <Col md={4}>
            <Form.Text>Snapshot</Form.Text>
            <Image src={image} width="100%"/>
          </Col>
          <Col md={8}>
            <Form.Text>Additional notes</Form.Text>
            <Form.Control type="text" as="textarea" placeholder="" value={note}
              onChange={this.onNotesChange.bind(this)} disabled={isReadonly} />
          </Col>
        </Row>
        <Row>
          <Col className="form-section-header-container"><p className="form-section-header">Project
                      summary</p></Col>
        </Row>
        <Row>
          <Col md={4}><ReportCard variant={'yellow'} header="Total cost of the project"
            body={<div>$ <NumberFormat value={parseFloat(totalCost).toFixed(2)} displayType={'text'}
              thousandSeparator={' '}/></div>}
            isUpdating={isPendingStatus(this.state.calculateCostStatus)}/></Col>
          <Col md={4}><ReportCard variant={'yellow'} header="Pre tax IRR"
            body={<div><NumberFormat value={irrInPercent} displayType={'text'}
              thousandSeparator={' '}/> %</div>}/></Col>
          <Col md={4}><ReportCard variant={'yellow'} header="Interest"
            body={<div><NumberFormat value={interestInPercent} displayType={'text'}
              thousandSeparator={' '}/> %</div>}/></Col>
        </Row>
        <Row>
          <Col md={4}><ReportCard variant={'orange'} header="LCOE"
            body={<div><NumberFormat value={lcoe ? (lcoe * 1000).toFixed(2) : '-'}
              displayType={'text'}
              thousandSeparator={' '}/> $/MWh</div>}
            isUpdating={isPendingStatus(this.state.calculateLcoeStatus)}/></Col>
          <Col md={4}><ReportCard variant={'orange'} header="Annual production"
            body={<div><NumberFormat value={annualProduction}
              displayType={'text'}
              thousandSeparator={' '}/> MWh</div>}
            isUpdating={isPendingStatus(this.state.calculateAnnualProductionStatus)}/></Col>
          <Col md={4}><ReportCard variant={'orange'} header="Peak capacity"
            body={<div><NumberFormat value={peakCapacity ? peakCapacity.toFixed(2) : '-'}
              displayType={'text'}
              thousandSeparator={' '}/> MWp</div>}
            isUpdating={isPendingStatus(this.state.calculateAnnualProductionStatus)}/></Col>
          <Col md={4}><ReportCard variant={'orange'} header="LCOS"
            body={<div><NumberFormat value={lcos ? lcos.toFixed(2) : '-'}
              displayType={'text'}
              thousandSeparator={' '}/> $/MWh</div>}
            isUpdating={isPendingStatus(this.state.calculateLcoeStatus)}/></Col>
          <Col md={4}><ReportCard variant={'orange'} header="Storage Capacity"
            body={<div><NumberFormat value={storageCapacity}
              displayType={'text'}
              thousandSeparator={' '}/> MWh</div>} /></Col>
          <Col md={4}><ReportCard variant={'orange'} header="Storage Power"
            body={<div><NumberFormat value={storagePower}
              displayType={'text'}
              thousandSeparator={' '}/> MW</div>} /></Col>
        </Row>
        <Row>
          <Col md={4}><ReportCard header="DNI" body={<div><NumberFormat value={dni}
            displayType={'text'}
            thousandSeparator={' '}/> kWh/m²/day</div>}/></Col>
          <Col md={4}><ReportCard header="WACC category" body={waccCategoryDisplay}/></Col>
          <Col md={4}><ReportCard header="Weather category" body={weatherCategoryDisplay}/></Col>
        </Row>
        <Row>
          <Col md={4}><ReportCard header="Chosen location" body={locationName}/></Col>
          <Col md={4}><ReportCard header="Latitude" body={latitude.toFixed(5)}/></Col>
          <Col md={4}><ReportCard header="Longitude" body={longitude.toFixed(5)}/></Col>
        </Row>
        <Row>
          {/* TODO: this part was copied from describe.jsx (CSP 20.1 crunch!) */}
          {checklist?.heatUse &&
          <Col md={12}>
            <ProjectControlChecklistCard id={'heatUse'} label={'Heat use >200 days/year'} checked={checklist.heatUse}
              disabled={true} handleChange={() => {return true;}}
              helpContents={'The client or project have a demand for thermal energy similar to the project size for at least 800 hours per year (4 hours daily, 200 days/year). The thermal demand must be above 75 degrees celcius and can be in any form such as hot water, hot air, steam or similar capable of interfacing a salt based heat exchanger.'}/>
          </Col>
          }
          {checklist?.varyingHeat &&
          <Col md={12}>
            <ProjectControlChecklistCard id={'varyingHeat'} label={'Heat consumption vary every 24h'}
              checked={checklist.varyingHeat}
              disabled={true} handleChange={() => {return true;}}
              helpContents={'The client’s or project’s heat load varies in intensity over a single day either small variation, or, full start/stop of consumption for periods of time.'}/>
          </Col>
          }
          {checklist?.heatSource &&
          <Col md={12}>
            <ProjectControlChecklistCard id={'heatSource'} label={'Heat source or generator on site'}
              checked={checklist.heatSource}
              disabled={true} handleChange={() => {return true;}}
              helpContents={'The client or project has means to produce heat today or there is a heat source producing excess heat not utilised today.'}/>
          </Col>
          }
        </Row>
        <Row>
          <Col className="form-section-header-container"><p className="form-section-header">Downloads</p></Col>
          <Table borderless hover className={'custom-table'} size={'sm'}>
            <thead>
              <tr>
                <th>File</th>
                <th>Download link</th>
              </tr>
            </thead>
            <tbody>
              <BomRow/>
              {
                models.map((modelName, i) => (
                  <tr key={'model-' + i}>
                    <td>{modelName} 3d model</td>
                    <td>
                      <Button block className="design-menu-button design-menu-button__subtle button-to-left"
                        onClick={() => downloadModel(i, `${modelName}.fbx`)} disabled={this.state.isDownloading[i]}
                        variant="link">{this.state.isDownloading[i] ? 'Downloading...':'Download'}</Button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Row>
      </Container>
    );
  }
}

GenerateReport.propTypes = {
  dispatch: PropTypes.func,
  settings: PropTypes.object,
  projectLocation: PropTypes.object,
  financial: PropTypes.object,
  site: PropTypes.object,
  checklist: PropTypes.object,
  projectEnergyProperty: PropTypes.object,
  project: PropTypes.object
};

const mapStateToProps = (state) => {
  const project = state.projects.project;
  return {
    settings: project?.settings,
    financial: project?.financial,
    checklist: project?.checklist,
    projectLocation: project?.projectLocation,
    site: project?.site,
    projectEnergyProperty: project?.projectEnergyProperty,
    project: project
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GenerateReport));
