import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import './ProjectStageSelected.scss';
import {arrowLeft} from '../arrows';

export const ProjectStageSelected = ({ title, description, backLink }) => (
  <Card className="project-stage-selection">
    <Card.Title>{title}</Card.Title>
    <Card.Body>{description}</Card.Body>
    {backLink && (
      <Card.Footer>
        <a href={backLink} className="menu__item-back">{arrowLeft()} Back</a>
      </Card.Footer>
    )}
  </Card>);

ProjectStageSelected.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  backLink: PropTypes.string
};
