import React from 'react';
import PropTypes from 'prop-types';
import CustomSpinner from '../operate/CustomSpinner';

import './styles.scss';

const FullPageLoader = ({text}) => (
  <div className={'fullPage'}>
    <CustomSpinner color="black" />
    <div className={'fullPage__text'}>{text || 'Loading...'}</div>
  </div>
);

FullPageLoader.propTypes = {
  text: PropTypes.string
};

export default FullPageLoader;
