import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {
  Alert,
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  FormCheck,
  FormControl,
  InputGroup,
  Modal,
  Row
} from 'react-bootstrap';
import {connect} from 'react-redux';
import {generatePath} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';

import {ProjectControlChecklistCard} from '../../components/ProjectControlChecklistCard';
import routes from '../../routes';
import values from '../../values';
import './describe.scss';
import {menuActions} from '../../store/actions/menu';
import {projectsActions} from '../../store/actions/projects';
import {getWeatherCategoryByLat} from '../../modules/weatherCategory';

const { waccValues, weatherValues } = values;

export class Describe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dniWarning: false,
      dniInput: (props.settings || {}).dni
    };
  }

  componentDidMount() {
    const { site, dispatch } = this.props;
    dispatch(menuActions.setDesignMenuCollapse(false));
    if (site && site.id) {
      console.log('There seems to be a project site connected...', site.id);
    }
  }

  dniHelper = () => {
    alert('Click fetch DNI to get direct normal irradiance for selected location.\n' +
      'On the opened page select "Per day" option and copy DNI value.');
  }

  openDniWindow = () => {
    const { projectLocation } = this.props;
    const url = `https://globalsolaratlas.info/detail?s=${projectLocation.lat},${projectLocation.lng}`;
    if (projectLocation && projectLocation.lat && projectLocation.lng) {
      window.open(url, '_blank');
    } else {
      alert('Missing project coordinates');
    }
  };

  verifyDni = dni => {
    const dniValid = dni !== undefined && dni !== null && !isNaN(dni) && dni >= 0;
    const dniWarning = dniValid && dni < 4.5;
    const dniUnitWarning = dniValid && dni > 20;
    const correctedDni = dniUnitWarning && (dni / 365).toFixed(3);
    this.setState({ dniValid, dniWarning, dniUnitWarning, correctedDni });
  };

  onWaccChange = e => {
    const {dispatch} = this.props;
    console.log('Chaning WACC', e);
    dispatch(projectsActions.updateProjectSettings({ waccCategory: e }));
    const financials = {};
    if (e === 'low') {
      financials.irrInPercent = 11;
      financials.interestInPercent = 6;
    } else if (e === 'medium') {
      financials.irrInPercent = 16.5;
      financials.interestInPercent = 9;
    } else if (e === 'high') {
      financials.irrInPercent = 19;
      financials.interestInPercent = 14;
    }
    dispatch(projectsActions.updateProjectFinancials(financials));
  };

  onWeatherChange = e => {
    this.props.dispatch(projectsActions.updateProjectSettings({ weatherCategory: e }));
  };

  handleDniChange = e => {
    const newDniValue = e.target.value;
    if (newDniValue && !isNaN(newDniValue)) {
      const parsedDniValue = parseFloat(newDniValue);
      this.verifyDni(parsedDniValue);
      this.props.dispatch(projectsActions.updateProjectSettings({ dni: parsedDniValue }));
    }
    this.setState({ dniInput: newDniValue });
  };

  handleDniUnitDialogClose = () => {
    this.setState({ dniUnitWarning: false });
  }

  toEnergy = () => {
    const {projectId} = this.props;
    this.props.history.push(generatePath(routes.designEnergy, {projectId}));
  }

  updateChecklist = (name, event) => {
    const {dispatch} = this.props;
    console.log('Update checklist', name, event.target.checked);
    dispatch(projectsActions.updateProjectChecklist({[name]: event.target.checked}));
  }

  render() {
    const { projectLocation, settings, checklist, dispatch } = this.props;
    const { name: projectName, dni, offTaker, waccCategory: selectedWacc,
      weatherCategory } = settings;
    const { dniValid, dniWarning, dniInput } = this.state;
    const isReadonly = settings.readonly;
    const selectedWeatherCategory = weatherCategory ? weatherCategory:
      (projectLocation ? getWeatherCategoryByLat(projectLocation.lat):null);
    return (
      <Container className="form-page">
        <h1>Describe the project</h1>
        <Form>
          {!projectLocation && (<Row><Col><Alert variant="warning">
            Please select a location on the map in the previous step.</Alert></Col></Row>)}
          <Row>
            <Col md={4}>
              <Form.Text>Chosen location</Form.Text>
              <Form.Control plaintext readOnly type="text" value={(projectLocation || {}).name || '-'} />
            </Col>
            <Col md={4}>
              <Form.Text>Latitude</Form.Text>
              <Form.Control plaintext readOnly type="text" value={(projectLocation || {}).lat || '-'} />
            </Col>
            <Col md={4}>
              <Form.Text>Longitude</Form.Text>
              <Form.Control plaintext readOnly type="text" value={(projectLocation || {}).lng || '-'} />
            </Col>
          </Row>
          <Row>
            <Col className="form-section-header-container">
              <p className="form-section-header">Project settings</p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Text>Project name <sup>*</sup></Form.Text>
              <Form.Control
                type="text"
                onChange={e => dispatch(projectsActions.updateProjectSettings({ name: e.target.value }))}
                value={projectName}
                disabled={isReadonly}
              />
            </Col>
            <Col md={4}>
              <Form.Text>
                DNI<sup>*</sup>
                <Button variant="link" className="form-label-info-button" onClick={this.dniHelper}>
                  <Icon path={mdiInformationOutline} size='10px' />
                </Button>
              </Form.Text>
              <InputGroup>
                <Form.Control type="text" value={dniInput} onChange={this.handleDniChange} disabled={isReadonly} />
                <InputGroup.Append>
                  <Button onClick={this.openDniWindow}>Fetch DNI</Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col md={4}>
              <Form.Text>Show on demo page</Form.Text>
              <InputGroup>
                <FormCheck.Input
                  type="checkbox"
                  onChange={e =>
                    dispatch(
                      projectsActions.updateProjectSettings({ show_on_demo: e.target.checked })
                    )}
                  checked={settings.show_on_demo}
                  className="demo-checkbox"
                  disabled={isReadonly} />
              </InputGroup>
            </Col>
            <Col>
              {dniValid && dniWarning && (<Alert variant="warning">The DNI should be greater than 4.5!</Alert>)}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Text>Off-Taker Name</Form.Text>
              <Form.Control
                type="text" onChange={e =>
                  dispatch(projectsActions.updateProjectSettings({ offTaker: e.target.value }))}
                value={offTaker}
                disabled={isReadonly}
              />
            </Col>
            <Col md={4}>
              <Form.Text>WACC Category</Form.Text>
              <InputGroup>
                <FormControl readOnly value={selectedWacc ? waccValues[selectedWacc].display : 'Choose...'} />
                <DropdownButton alignRight as={InputGroup.Append}
                  onSelect={this.onWaccChange} title="" disabled={isReadonly}>
                  {Object.keys(waccValues).map((k, i) =>
                    <Dropdown.Item key={i} eventKey={waccValues[k].key}
                      active={waccValues[k].key === selectedWacc}>{waccValues[k].display}
                    </Dropdown.Item>)}
                </DropdownButton>
              </InputGroup>
            </Col>
            <Col md={4}>
              <Form.Text>Weather Category</Form.Text>
              <InputGroup>
                <FormControl
                  readOnly
                  value={selectedWeatherCategory ? weatherValues[selectedWeatherCategory].display : 'Choose...'} />
                <DropdownButton alignRight as={InputGroup.Append}
                  onSelect={this.onWeatherChange} title="" disabled={isReadonly}>
                  {Object.keys(weatherValues).map((k, i) =>
                    <Dropdown.Item key={i} eventKey={weatherValues[k].key}
                      active={weatherValues[k].key === selectedWeatherCategory}>{weatherValues[k].display}
                    </Dropdown.Item>)}
                </DropdownButton>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col className="form-section-header-container">
              <p className="form-section-header">Control checklist</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ProjectControlChecklistCard id={'heatUse'}
                label={'Heat use >200 days/year'}
                disabled={isReadonly}
                checked={checklist.heatUse === true}
                helpContents={'The client or project have a demand for thermal energy similar to the ' +
                  'project size for at least 800 hours per year (4 hours daily, 200 days/year). The thermal demand ' +
                  'must be above 75 degrees celcius and can be in any form such as hot water, hot air, steam or ' +
                  'similar capable of interfacing a salt based heat exchanger.'}
                handleChange={(event) => this.updateChecklist('heatUse', event)} />
            </Col>
            <Col md={12}>
              <ProjectControlChecklistCard id={'varyingHeat'}
                label={'Heat consumption vary every 24h'}
                checked={checklist.varyingHeat === true}
                disabled={isReadonly}
                helpContents={'The client’s or project’s heat load varies in intensity over a single day either ' +
                  'small variation, or, full start/stop of consumption for periods of time.'}
                handleChange={(event) => this.updateChecklist('varyingHeat', event)} />
            </Col>
            <Col md={12}>
              <ProjectControlChecklistCard id={'heatSource'} label={'Heat source or generator on site'}
                disabled={isReadonly}
                checked={checklist.heatSource === true}
                helpContents={'The client or project has means to produce heat today or there is a ' +
                  'heat source producing excess heat not utilised today.'}
                handleChange={(event) => this.updateChecklist('heatSource', event)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ paddingTop: '20px' }}>
              <Button onClick={this.toEnergy}>Energy usage</Button>
            </Col>
          </Row>
        </Form>
        <Modal show={this.state.dniUnitWarning} onHide={() => this.setState({ dniUnitWarning: false })}>
          <Modal.Header closeButton>
            <Modal.Title>DNI unit correction</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You entered a DNI of {dni}. Are you sure it is in the correct unit (kWh/m²/day)? It looks like it could
            be in kWh/m². I can convert it for you. The DNI would then be {this.state.correctedDni} kWh/m²/day.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDniUnitDialogClose}>
              Close
            </Button>
            <Button variant="primary"
              onClick={() => this.handleDniChange({ target: { value: this.state.correctedDni } })}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

Describe.propTypes = {
  projectId: PropTypes.string,
  settings: PropTypes.object,
  projectLocation: PropTypes.object,
  site: PropTypes.object,
  checklist: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object
};

const WrapperDescribe =  withRouter(Describe);

const mapStateToProps = (state) => {
  const project = state.projects.project;
  return {
    projectId: state.projects.projectId,
    settings: project?.settings,
    projectLocation: project?.projectLocation,
    site: project?.site,
    checklist: project?.checklist,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrapperDescribe);
