import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ReactSVGPanZoom, TOOL_AUTO} from 'react-svg-pan-zoom';
import {OPERATE_STYLES} from '../../../styles/constants';
import SchemaMenu from '../SchemaMenu';
import {svgPanZoomBaseConfig} from './config';
import {plantTesService} from '../../../store/services/plantTes';
import InlineSVG from 'react-inlinesvg';
import {deviceStatus, deviceTypes} from '../../../store/constants/plantTes';
import SchemaContextMenu from '../SchemaContextMenu';
import {getIsMaintenanceMode} from '../../../store/selectors/plant';

const SVG_NS = 'http://www.w3.org/2000/svg';
const COLORS = {
  INACTIVE: '#3D4046',
  ACTIVE: '#00ff73',
  ERROR: '#ff2f2f',
  TANK_FILL_PATTERN: '#ededed',
  TEMPERATURE_HIGHEST: '#ff0000',
  TEMPERATURE_HIGH: 'orange',
  TEMPERATURE_LOW: 'yellow',
  TEMPERATURE_LOWEST: 'grey'
};

class Schema extends Component {
  viewer = React.createRef();
  firstLoad = true;
  state = {
    value: {},
    tool: TOOL_AUTO,
    width: null,
    height: null,
    svgContent: null,
    svgSize: {},
    contextMenuPosition: null,
    contextMenuObject: null
  }

  constructor(props) {
    super(props);
    this.changeTool = this.changeTool.bind(this);
    this.fitToViewer = this.fitToViewer.bind(this);
    this._calculateViewerDimensions = this._calculateViewerDimensions.bind(this);
  }

  componentDidMount() {
    this._calculateViewerDimensions();
    window.addEventListener('resize', this._calculateViewerDimensions);
    this._loadSvg();
  }

  componentDidUpdate(prevProps) {
    const {menuVisible, status, url, config} = this.props;
    if (prevProps && prevProps.menuVisible !== menuVisible) this._calculateViewerDimensions();
    if (prevProps && prevProps.url !== url) this._loadSvg();
    if (prevProps && prevProps.config !== config) this._loadConfig();
    if (status.global) this._updateSvg();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._calculateViewerDimensions);
  }

  changeTool(tool) {
    this.setState({tool});
  }

  fitToViewer() {
    if (!this.viewer.current) return;
    this.viewer.current.fitToViewer('center', 'center');
  }

  _loadSvg() {
    const {url} = this.props;
    plantTesService.loadSchema(url).then(data => {
      const svgContent = data;
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgContent, 'image/svg+xml');
      const element = doc.getElementsByTagName('svg')[0];
      let width = element.getAttribute('width');
      if (width.includes('px')) width = width.substring(0, width.length - 2);
      let height = element.getAttribute('height');
      if (height.includes('px')) height = height.substring(0, height.length - 2);
      element.setAttribute('id', 'schema');
      this._addDefs(element);
      this.setState({
        svgSize: {width, height},
        svgContent: doc.documentElement.outerHTML
      }, () => this._afterSvgLoad());

    }).catch(error => {
      console.log('Error while loading schema', error);
      alert('Cannot load schema!');
    });
  }

  _addDefs(svg) {
    const defsList = svg.getElementsByTagName('defs');
    let defs;
    if (defsList.length === 0) {
      defs = document.createElementNS(SVG_NS, 'defs');
      svg.appendChild(defs);
    } else {
      defs = defsList[0];
    }
    defs.appendChild(this._createFillTankPattern());
  }

  _createFillTankPattern() {
    const line = document.createElementNS(SVG_NS, 'line');
    line.setAttribute('y2', '10');
    line.setAttribute('strokeWidth', '1px');
    line.setAttribute('stroke', COLORS.TANK_FILL_PATTERN);
    const pattern = document.createElementNS(SVG_NS, 'pattern');
    pattern.setAttribute('patternTransform', 'rotate(45)');
    pattern.setAttribute('patternUnits', 'userSpaceOnUse');
    pattern.setAttribute('height', '10');
    pattern.setAttribute('width', '5');
    pattern.setAttribute('id', 'tank_pattern');
    pattern.appendChild(line);
    return pattern;
  }

  _calculateViewerDimensions() {
    const {menuVisible} = this.props;
    this.setState({
      width: window.innerWidth - OPERATE_STYLES.MENU_WIDTH - (menuVisible ? OPERATE_STYLES.MENU_DETAILS_WIDTH:0),
      height: window.innerHeight
    });
  }

  _afterSvgLoad() {
    if (!this.viewer.current) return;
    // There is no callback if schema has been rendered
    // this is not the best solution but it's working
    if (this.firstLoad) {
      this.firstLoad = false;
      this.viewer.current.fitToViewer('center', 'center');
    }
    setTimeout(this._loadConfig.bind(this), 100);
    setTimeout(this._updateSvg.bind(this), 100);
    setTimeout(this._setSchemaListeners.bind(this), 100);
  }

  _loadConfig() {
    const elements = document.querySelectorAll('[id$="_arrow_group"]');
    for (const element of elements) element.setAttribute('opacity', '0');
    const {config} = this.props;
    if (!config) return;
    for (const [objId, values] of Object.entries(config)) {
      const element = document.getElementById(`${objId}_arrow_group`);
      if (element) element.setAttribute('opacity', '1');
      const arrowElement = document.getElementById(`${objId}_arrow`);
      if (arrowElement && values.rotate) {
        arrowElement.setAttribute('style',
          `transform-box: fill-box; transform-origin: center; transform: rotate(${values.rotate}deg)`);
      }
    }
  }

  _updateSvg() {
    const {valve, tank, pipe, pump, hex} = this.props.status;
    // TODO: optimisation (change only when needed)?
    for (let device of [].concat(valve, tank, pipe, pump, hex)) {
      if (!device) continue;
      if (device.temperature_sensors) {
        for (let temperature_sensor of device.temperature_sensors) {
          this._updateSvgTemperature(`${temperature_sensor.object_id}_temperature`, {
            deviceId: temperature_sensor.object_id,
            value: temperature_sensor.status !== deviceStatus.ERROR ? temperature_sensor.value:null
          }, );
        }
      }
      if (device.fill_volume_percent) {
        const fillVolumePercent = device.fill_volume_percent;
        if (device.status === deviceStatus.ERROR) {
          this._updateSvgTankFill(device.object_id, 0, 'N/A');
        } else {
          this._updateSvgTankFill(device.object_id, fillVolumePercent, `${fillVolumePercent.toFixed(2)} %`);
        }
      }
      if (device.average_temperature) {
        this._updateSvgTemperature(`${device.object_id}_tank_avg_temperature_value`, {
          value: device.status !== deviceStatus.ERROR ? device.average_temperature:null
        });
        this._updateSvgTemperatureIndicator(`${device.object_id}_tank_avg_temperature`, {
          isError: device.status === deviceStatus.ERROR,
          value: device.average_temperature
        });
      }
      if (device.is_in_use !== undefined) {
        this._updatePipe(device.object_id, device.is_in_use);
      }
    }
    this._updateHighlight();
    this._updateValves();
    this._updateHexes();
  }

  _updateSvgTemperature(elementId, {deviceId, value}) {
    let text = document.getElementById(elementId);
    let verbose, prefix = deviceId ? `${deviceId} `:'';
    if (!value) {
      verbose = `${prefix}N/A`;
    } else {
      verbose = `${prefix}${value.toFixed(0)} °C`;
    }
    if (text && text.children[0]) text.children[0].textContent = verbose;
  }

  _updateSvgTankFill(tankId, volume, verbose) {
    const tankBorder = document.getElementById(`${tankId}_tank_border`);
    const schemaSvg = document.getElementById('schema');

    if (tankBorder && schemaSvg) {
      const tankBorderBBox = tankBorder.getBBox();
      let tankFill = document.getElementById(`${tankId}_tank_fill`);
      if (!tankFill) {
        tankFill = this._createTankFillRect(tankId, tankBorderBBox);
        schemaSvg.appendChild(tankFill);
      }
      const tankHeight = tankBorderBBox.height;
      const tankY = tankBorderBBox.y;
      const newFillHeight = tankHeight * (volume / 100);
      const newFillY = tankY + (tankHeight - newFillHeight);
      tankFill.setAttribute('height', newFillHeight.toString());
      tankFill.setAttribute('y', newFillY.toString());
    }
    const tankFillValue = document.getElementById(`${tankId}_tank_fill_value`);
    if (tankFillValue && tankFillValue.children[0]) tankFillValue.children[0].textContent = verbose;
  }

  _createTankFillRect(tankId, tankBorderBBox) {
    const rect = document.createElementNS(SVG_NS, 'rect');
    rect.setAttribute('ry', '2');
    rect.setAttribute('rx', '2');
    rect.setAttribute('id', `${tankId}_tank_fill`);
    rect.setAttribute('x', tankBorderBBox.x);
    rect.setAttribute('y', tankBorderBBox.y);
    rect.setAttribute('width', tankBorderBBox.width);
    rect.setAttribute('style', 'fill:url(#tank_pattern)');
    return rect;
  }

  _updateSvgTemperatureIndicator(elementPrefix, {value, isError}) {
    const elementBorder = document.getElementById(`${elementPrefix}_border`);
    const elementIcon = document.getElementById(`${elementPrefix}_icon`);
    if (!elementBorder || !elementIcon) return;
    let color, opacity = 0;
    if (isError) { color = COLORS.TEMPERATURE_LOWEST; opacity = 1; }
    else {
      const temperature = parseFloat(value);
      if (temperature > 350) { color = COLORS.TEMPERATURE_HIGHEST; }
      else if (temperature > 250) { color = COLORS.TEMPERATURE_HIGH; }
      else if (temperature > 180) { color = COLORS.TEMPERATURE_LOW; }
      else { color = COLORS.TEMPERATURE_LOWEST; opacity = 1; }
    }
    elementBorder.setAttribute('fill', color);
    elementBorder.setAttribute('stroke', color);
    elementIcon.setAttribute('fill-opacity', opacity.toString());
  }

  _updateHighlight() {
    const {selectedDevices} = this.props;
    const elements = document.querySelectorAll('[id$="_highlight"]');
    for (let element of elements) element.setAttribute('opacity', '0');
    for (let deviceInfo of selectedDevices) {
      const element = document.getElementById(`${deviceInfo.device.object_id}_highlight`);
      if (element) element.setAttribute('opacity', '1');
    }
  }

  _updateValves() {
    const {valve: valves} = this.props.status;
    for (let valve of valves) {
      const objs = document.querySelectorAll(`svg [id^="${valve.object_id}_valve"]`);
      let color = COLORS.ERROR;
      if (valve.status === deviceStatus.INACTIVE) color = COLORS.INACTIVE;
      else if (valve.status === deviceStatus.ACTIVE) color = COLORS.ACTIVE;
      for (let obj of objs) obj.setAttribute('stroke', color);
    }
  }

  _updateHexes() {
    const {hex: hexes} = this.props.status;
    for (let hex of hexes) {
      const fanText = document.getElementById(`${hex.object_id}_fan_text`);
      const fanIconGroup = document.getElementById(`${hex.object_id}_fan_icon_group`);
      const text = document.getElementById(`${hex.object_id}_text`);
      const iconGroup = document.getElementById(`${hex.object_id}_icon_group`);
      if (!fanText || !fanIconGroup || !text || !iconGroup) continue;
      let color = COLORS.ERROR;
      if (hex.status === deviceStatus.INACTIVE) color = COLORS.INACTIVE;
      else if (hex.status === deviceStatus.ACTIVE) color = COLORS.ACTIVE;
      fanText.setAttribute('fill', color);
      text.setAttribute('fill', color);
      for (let element of [...fanIconGroup.children, ...iconGroup.children]) {
        element.hasAttribute('stroke') ? element.setAttribute('stroke', color):element.setAttribute('fill', color);
      }
    }
  }

  _updatePipe(deviceId, isInUse) {
    const obj = document.getElementById(`${deviceId}_pipe`);
    const color = isInUse ? COLORS.ACTIVE:COLORS.INACTIVE;
    if (obj) {
      if (obj.hasAttribute('fill')) obj.setAttribute('fill', color);
      else obj.setAttribute('stroke', color);
    }
  }

  _parseEvent(event) {
    const target = event.target;
    if (!target) return;
    const objId = target.id;
    if (!objId) return;
    const realObjId = objId.split('_')[0];
    return {objId, realObjId};
  }

  _clickListener(event) {
    this.setState({contextMenuPosition: null, contextMenuObject: null});
    event.preventDefault();
    const parsedEvent = this._parseEvent(event);
    if (!parsedEvent) return;
    const {objId, realObjId} = parsedEvent;
    const {status, toggleDeviceSelect} = this.props;

    if (objId.endsWith('_tank_border')) {
      if (event.type === 'click') {
        const {tank: tanks} = status;
        const tank = tanks.find(el => el.object_id === realObjId);
        if (!tank) return;
        toggleDeviceSelect(deviceTypes.TANK, tank);
      }
    } else if (objId.includes('_valve_icon')) {
      const {valve: valves} = status;
      const valve = valves.find(el => el.object_id === realObjId);
      if (!valve) return;
      if (event.type === 'click') toggleDeviceSelect(deviceTypes.VALVE, valve);
      if (event.type === 'contextmenu') {
        this.setState({
          contextMenuPosition: {x: event.clientX, y: event.clientY},
          contextMenuObject: {type: deviceTypes.VALVE, obj: valve}
        });
      }
    } else if (objId.endsWith('_pipe')) {
      const {pipe: pipes} = status;
      const pipe = pipes.find(el => el.object_id === realObjId);
      if (!pipe) return;
      if (event.type === 'contextmenu') {
        this.setState({
          contextMenuPosition: {x: event.clientX, y: event.clientY},
          contextMenuObject: {type: deviceTypes.PIPE, obj: pipe}
        });
      }
    }
  }

  _setSchemaListeners() {
    const schemaSvg = document.getElementById('schema');
    if (!schemaSvg) return;
    schemaSvg.addEventListener('click', this._clickListener.bind(this));
    schemaSvg.addEventListener('contextmenu', this._clickListener.bind(this));
  }

  _hideContextMenu() {
    this.setState({contextMenuPosition: null, contextMenuObject: null});
  }

  render() {
    const {status, isReadonly} = this.props;
    const {tool, value, width, height, svgContent, svgSize, contextMenuPosition, contextMenuObject} = this.state;
    if (!width || !height || !svgContent) return <div/>;
    const isMaintenanceMode = getIsMaintenanceMode({plant: {tes: {status}}});
    return (
      <>
        {contextMenuPosition &&
          <SchemaContextMenu
            obj={contextMenuObject.obj}
            type={contextMenuObject.type}
            position={contextMenuPosition}
            isMaintenanceMode={isMaintenanceMode}
            isReadonly={isReadonly}
          />
        }
        <ReactSVGPanZoom
          {...svgPanZoomBaseConfig}
          ref={this.viewer}
          width={width} height={height}
          tool={tool}
          onChangeTool={tool => this.setState({tool})}
          value={value}
          onMouseDown={this._hideContextMenu.bind(this)}
          onZoom={this._hideContextMenu.bind(this)}
          onChangeValue={value => this.setState({value})}
          customToolbar={
            (props) =>
              <SchemaMenu tool={props.tool} changeTool={this.changeTool} fitToViewer={this.fitToViewer}/>
          }
        >
          <svg width={svgSize.width} height={svgSize.height}>
            <InlineSVG src={svgContent} />
          </svg>
        </ReactSVGPanZoom>
      </>
    );
  }
}

Schema.propTypes = {
  url: PropTypes.string.isRequired,
  config: PropTypes.object,
  status: PropTypes.object.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  menuVisible: PropTypes.bool,
  selectedDevices: PropTypes.array,
  toggleDeviceSelect: PropTypes.func,
};

export default Schema;
