import {heliostatConstants} from '../constants/heliostat';

const initialState = {
  heliostatsList: [],
  heliostat: {},
  activeHeliostat: null
};

export const heliostat = (state = initialState, action) => {
  let newStatus, heliostat, foundIndex, heliostatList;
  switch (action.type) {

    case heliostatConstants.GET_HELIOSTAT_SUCCEEDED:
      return {...state, heliostat: action.payload};

    case heliostatConstants.SET_ACTIVE_HELIOSTAT:
      return {
        ...state,
        activeHeliostat: action.activeHeliostat,
        heliostat: state.heliostatsList.find(heliostat => heliostat.id === action.activeHeliostat)
      };

    case heliostatConstants.GET_HELIOSTAT_LIST_SUCCEEDED:
      return {
        ...state,
        heliostatsList: action.payload
      };

    case heliostatConstants.UPDATE_HELIOSTAT:
      newStatus = {...state};
      heliostat = action.payload;
      if (state.activeHeliostat === heliostat.id) newStatus.heliostat = heliostat;
      foundIndex = state.heliostatsList.findIndex(_heliostat => _heliostat.id === heliostat.id);
      heliostatList = [...state.heliostatsList];
      if (foundIndex === -1) {
        heliostatList.push(heliostat);
      } else {
        heliostatList[foundIndex] = heliostat;
      }
      newStatus.heliostatsList = heliostatList;
      return newStatus;

    default:
      return state;
  }
};
