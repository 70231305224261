const waccValues = {
  low: { key: 'low', display: 'Low', discountRateInPercent: 8 },
  medium: { key: 'medium', display: 'Medium', discountRateInPercent: 12 },
  high: { key: 'high', display: 'High', discountRateInPercent: 16 }
};

const weatherValues = {
  a: { key: 'a', display: 'Tropical' },
  b: { key: 'b', display: 'Dry' },
  c: { key: 'c', display: 'Temperate' },
  d: { key: 'd', display: 'Continental' },
  e: { key: 'e', display: 'Polar' }
};

function lookupWacc(w) {
  return waccValues[w];
}

function lookupWeather(w) {
  return weatherValues[w];
}

export default { waccValues, weatherValues, lookupWacc, lookupWeather };
