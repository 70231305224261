import {store} from '../store';
import {Auth} from 'aws-amplify';
import {usersConstants} from '../store/constants/users';

export const getPlantId = () => {
  const plant = store.getState().plant.plant;
  if (!plant) return null;
  return plant.id;
};

export const getPlantById = (plants, plantId) => {
  for (let dbPlant of plants) {
    if (dbPlant.id === plantId) {
      return dbPlant;
    }
  }
  return null;
};

export const getFlowModeById = (modes, isMaintenance, modeId) => {
  if (!modes || Object.keys(modes).length === 0) return;
  for (let mode of modes[isMaintenance ? 'maintenance':'operational']) {
    if (parseInt(mode.value) === parseInt(modeId)) {
      return mode;
    }
  }
};

export const getUserPayload = (cognitoUser) => {
  const session = cognitoUser.getSignInUserSession();
  let identities = cognitoUser.attributes?.identities;
  let isProvider = false;
  if (identities) {
    identities = JSON.parse(identities);
    for (let identity of identities) {
      if (identity.providerType && identity.providerType !== '') {
        isProvider = true;
        break;
      }
    }
  }
  return {
    username: cognitoUser.username,
    accessToken: session.getAccessToken().getJwtToken(),
    email: cognitoUser.attributes?.email,
    name: cognitoUser.attributes?.name,
    isProvider: isProvider
  };
};

export const getCurrentAuthenticatedUser = (dispatch) => {
  return Auth.currentAuthenticatedUser().then((user) => {
    const userPayload = getUserPayload(user);
    dispatch({
      type: usersConstants.SET_USER,
      payload: userPayload
    });
    return userPayload;
  });
};
