import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './styles.scss';
import InfoItem from '../../../components/operate/InfoItem';
import MenuHeaderTitle from '../../../components/operate/MenuHeaderTitle';
import {menuActions} from '../../../store/actions/menu';
import {plantModules, plantStatus as plantStatusName} from '../../../store/constants/plant';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import {checkIfExists} from '../../../helpers/utils';
import HasModuleActive from '../../HasModuleActive';

export class MenuInfoPlant extends Component {

    toggleInnerMenu = () => {
      this.props.dispatch(menuActions.toggleInnerMenu());
    };

    render() {
      const {heliostats, plantStatus, plant} = this.props;
      if (!plant || !plantStatus) return <div/>;
      let activeHeliostats = [];
      if (heliostats) activeHeliostats = heliostats.filter(heliostat => { return heliostat.status; });

      const statusTitle = plantStatusName[plantStatus.status];
      const lastSeen = plantStatus.last_seen ? new Date(plantStatus.last_seen) : new Date();
      const lastSeenDistance = formatDistanceStrict(new Date(), lastSeen);

      const focalPointTemperature = plantStatus.focal_point_temperature;
      let focalPointTemperatureVerbose = 'N/A';
      if (focalPointTemperature && focalPointTemperature.error_code === 0)
        focalPointTemperatureVerbose = `${focalPointTemperature.value.toFixed(2)} °C`;

      return (
        <React.Fragment>
          <MenuHeaderTitle subtitle={'Power Plant details'} title={plant.title} online={plantStatus.online} />
          {heliostats &&
            <HasModuleActive module={plantModules.HELIOSTATS}>
              <div className="items-wrapper">
                <InfoItem title={'Heliostats'} subtitle={'Operational'}
                  data={`${activeHeliostats.length}/${heliostats.length}`}
                  onClick={this.toggleInnerMenu}/>
              </div>
            </HasModuleActive>
          }
          <div className="items-wrapper">
            <div className="items-wrapper__title">Sentinel</div>
            <InfoItem title={'Status'} subtitle={'Current state'}
              data={statusTitle} subData={`${lastSeenDistance} ago`}/>
            <InfoItem title={'Cameras'} subtitle={'Online'}
              data={`${plantStatus.cameras_online}/${plantStatus.cameras_count}`}/>
            <HasModuleActive module={plantModules.HELIOSTATS}>
              <InfoItem title={'Focal point'} subtitle={'Temperature'}
                data={focalPointTemperatureVerbose}/>
            </HasModuleActive>
          </div>
          <HasModuleActive module={plantModules.WEATHER}>
            <div className="items-wrapper">
              <div className="items-wrapper__title">Weather</div>
              <InfoItem title={'Temperature'} subtitle={'Temperature'}
                data={checkIfExists(plantStatus.temperature) ? `${plantStatus.temperature.toFixed(2)}°C`:'N/A'}/>
              <InfoItem title={'Wind Direction'} subtitle={'Current'}
                data={checkIfExists(plantStatus.wind_direction) ? `${plantStatus.wind_direction.toFixed(2)}°N`:'N/A'}/>
              <InfoItem title={'Wind Speed'} subtitle={'Current'}
                data={checkIfExists(plantStatus.wind_speed) ? `${plantStatus.wind_speed.toFixed(2)}m/s`:'N/A'}/>
            </div>
          </HasModuleActive>
        </React.Fragment>
      );
    }
}

MenuInfoPlant.propTypes = {
  heliostats: PropTypes.array,
  plantStatus: PropTypes.object,
  plant: PropTypes.object,
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    plantStatus: state.plant.status,
    heliostats: state.heliostat.heliostatsList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuInfoPlant);
