import Amplify from 'aws-amplify';
import config from './config';


export const configureAmplify = () => {
  const amplifyConfig = {
    Auth: {
      mandatorySignIn: true,
      region: config.COGNITO.REGION,
      userPoolId: config.COGNITO.USER_POOL_ID,
      userPoolWebClientId: config.COGNITO.APP_CLIENT_ID
    },
    oauth: {
      domain: `${config.COGNITO.USER_POOL_DOMAIN}.auth.${config.COGNITO.REGION}.amazoncognito.com`,
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: config.COGNITO.OAUTH_CALLBACK_URL,
      redirectSignOut: config.COGNITO.OAUTH_LOGOUT_URL,
      responseType: 'code'
    }
  };
  Amplify.configure(amplifyConfig);
};
