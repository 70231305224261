import config from '../config';

export const locationHelper = {
  extractLocationName,
  getLocationNameFromCoords,
  isLatitudeValid,
  isLongitudeValid,
  isLocationValid
};

const addressComponentTypes = [
  { key: 'postal_town', order: 1, priority: 6 },
  { key: 'administrative_area_level_1', order: 6, priority: 3 },
  { key: 'administrative_area_level_2', order: 3, priority: 5 },
  { key: 'country', order: 10, priority: 1 }
];
const maxAddressComponentTypes = 3;

function extractLocationName(data) {
  if (!data || data.status === 'ZERO_RESULTS')
    return '';
  const locationNameData = data.results.shift();
  let parts = [];
  for (let i = 0; i < locationNameData.address_components.length; i++) {
    const part = locationNameData.address_components[i];
    addressComponentTypes.forEach(allowedType => {
      if (part.types.includes(allowedType.key)) {
        parts.push({ order: allowedType.order, priority: allowedType.priority, name: part.long_name });
      }
    });
  }
  const sortedParts = parts
    .sort((p1, p2) => p1.priority - p2.priority)
    .slice(0, maxAddressComponentTypes)
    .sort((p1, p2) => p1.order - p2.order);

  let name = '';
  for (let p = 0; p < sortedParts.length; p++) {
    if (p + 1 < sortedParts.length)
      name = name + sortedParts[p].name + ', ';
    else
      name = name + sortedParts[p].name;
  }

  return name;
}

const GOOGLE_GEOCODE_URL = 'https://maps.googleapis.com/maps/api/geocode/';
function getLocationNameFromCoords({lat, lng}) {
  const concatenatedCoordinates = lat + ',' + lng;
  return fetch(`${GOOGLE_GEOCODE_URL}json?latlng=${concatenatedCoordinates}&key=${config.GOOGLE_MAPS_API_KEY}`)
    .then(response => response.json())
    .then(data => extractLocationName(data));
}

function isLatitudeValid(lat) {
  return lat && !isNaN(lat) && lat <= 90 && lat >= -90;
}

function isLongitudeValid(long) {
  return long && !isNaN(long) && long <= 180 && long >= -180;
}

function isLocationValid(loc) {
  if (loc) {
    if (loc.lat !== undefined && loc.lng !== undefined) return isLatitudeValid(loc.lat) && isLongitudeValid(loc.lng);
    if (loc.lat !== undefined) return isLatitudeValid(loc.lat);
    if (loc.lng !== undefined) return isLongitudeValid(loc.lng);
    return false;
  }
  return false;
}
