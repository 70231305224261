import {MenuInfo, MenuHalt, MenuEvents, MenuDesign} from '../constants/menu';

export const menuActions = {
  toggleMenuInfo,
  toggleMenuTesInfo,
  toggleMenuEvents,
  toggleMenuHalt,
  toggleInnerMenu,
  closeInnerMenu,
  setMenuInfoPlant,
  setMenuTesInfo,
  setMenuHeliostat,
  setMenuInfoMaintenance,
  setMenuInfoProduction,
  setHaltProgress,
  setHaltEmergency,
  setHaltProdaction,
  setLiveVideo,
  unsetLiveVideo,
  setEventDetailsMenu,
  setEventsMenu,
  setDesignMenuCollapse,
  toggleMenuCloudViewer
};

function toggleMenuEvents() {
  return { type: MenuEvents.TOGGLE_MENU_EVENTS };
}

function setEventDetailsMenu(event) {
  return { type: MenuEvents.SET_MENU_DETAILS, payload: event };
}

function setEventsMenu() {
  return { type: MenuEvents.SET_MENU_EVENTS };
}

function toggleMenuInfo() {
  return { type: MenuInfo.TOGGLE_MENU_INFO };
}

function toggleMenuTesInfo() {
  return { type: MenuInfo.TOGGLE_MENU_TES_INFO };
}

function toggleMenuHalt() {
  return { type: MenuHalt.TOGGLE_MENU_HALT };
}

function setHaltProgress() {
  return { type: MenuHalt.SET_HALT_PROGRESS };
}

function setHaltEmergency() {
  return { type: MenuHalt.SET_HALT_EMERGENCY };
}

function setHaltProdaction() {
  return { type: MenuHalt.SET_HALT_PRODUCTION };
}

function toggleInnerMenu() {
  return { type: MenuInfo.TOGGLE_INNER_MENU };
}

function closeInnerMenu() {
  return { type: MenuInfo.TOGGLE_INNER_MENU };
}

function setMenuHeliostat() {
  return { type: MenuInfo.SET_MENU_INFO_HELIOSTAT };
}

function setMenuInfoPlant() {
  return { type: MenuInfo.SET_MENU_INFO_PLANT };
}

function setMenuTesInfo() {
  return { type: MenuInfo.SET_MENU_TES_INFO };
}

function setMenuInfoMaintenance() {
  return { type: MenuInfo.SET_MENU_INFO_MAINTENANCE };
}

function setMenuInfoProduction() {
  return { type: MenuInfo.SET_MENU_INFO_PRODUCTION};
}

function setLiveVideo() {
  return { type: MenuInfo.SET_LIVE_VIDEO };
}

function unsetLiveVideo() {
  return { type: MenuInfo.UNSET_LIVE_VIDEO };
}

function setDesignMenuCollapse(value) {
  return { type: MenuDesign.DESIGN_MENU_SET_COLLAPSE, payload: value };
}

function toggleMenuCloudViewer() {
  return { type: MenuInfo.TOGGLE_MENU_CLOUD_VIEWER };
}
