import {Button, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import FromBody from '../FormBody';

const PasswordChangeForm = ({onInputChange, onButtonPress, onCancelPress, disabled}) => (
  <Form className={'login-form'}>
    <p>Please provide current and new password to change it.</p>
    <FromBody
      onButtonPress={onButtonPress}
      fields={[
        {label: 'Current password', type: 'password', name: 'password'},
        {label: 'New password', type: 'password', name: 'newPassword1'},
        {label: 'Repeat new password', type: 'password', name: 'newPassword2'}
      ]}
      onInputChange={onInputChange}
    />
    <Form.Group className={'login-form__footer'}>
      <Button onClick={onButtonPress} disabled={disabled}>Change</Button><br/><br/>
      <Button onClick={onCancelPress} disabled={disabled} variant="secondary">Cancel</Button>
    </Form.Group>
  </Form>
);

PasswordChangeForm.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onButtonPress: PropTypes.func.isRequired,
  onCancelPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default PasswordChangeForm;
