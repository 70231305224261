import axios from 'axios';
import {calculationUrls} from '../constants/calculations';

export const calculationService = {
  calculateLcos,
  calculateCost,
  calculateAnnualProduction,
  calculateLcoe,
  calculatePeakCapacity
};

function calculateLcos({price, size, charge_cost, round_trip_efficiency, availability, discount_rate, dual_cycle}) {
  return axios.request({
    method: 'POST',
    url: calculationUrls.LCOS,
    data: {
      price, size, charge_cost, round_trip_efficiency, availability, discount_rate, dual_cycle
    }
  });
}

function calculateCost({year, numberOfTowers, numberOfHeliostats, numberOfStorageUnits, numberOfTurbines}) {
  return axios.request({
    method: 'POST',
    url: calculationUrls.COST,
    data: {
      year, numberOfTowers, numberOfHeliostats, numberOfStorageUnits, numberOfTurbines
    }
  });
}

function calculateAnnualProduction({dni, numberOfHeliostats}) {
  return axios.request({
    method: 'POST',
    url: calculationUrls.ANNUAL_PRODUCTION,
    data: {dni, numberOfHeliostats}
  });
}

function calculateLcoe({lifetimeInYears, initialInvestment, operatingCost, discountPercentage, annualOutput}) {
  return axios.request({
    method: 'POST',
    url: calculationUrls.LCOE,
    data: {lifetimeInYears, initialInvestment, operatingCost, discountPercentage, annualOutput}
  });
}

function calculatePeakCapacity({numberOfHeliostats}) {
  return parseFloat(numberOfHeliostats) * 5 / 1000; // MWp
}
