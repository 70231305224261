import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { arrowRight } from '../arrows';
import './ProjectStageMenuItem.scss';
import PropTypes from 'prop-types';

export const ProjectStageMenuItem = props => {
  const { href, title, description, numberOfProjects } = props;
  return (
    <Card className="project-stage-menu-item" onClick={() => props.history.push(href)}>
      <Card.Title>{title}{arrowRight()}</Card.Title>
      <Card.Subtitle>{description}</Card.Subtitle>
      <Card.Footer>{numberOfProjects || 0} projects</Card.Footer>
    </Card>
  );
};

ProjectStageMenuItem.propTypes = {
  href: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  numberOfProjects: PropTypes.number
};

export default withRouter(ProjectStageMenuItem);
