import React, {Component} from 'react';
import {matchPath, Route, Switch, withRouter} from 'react-router';
import PropTypes from 'prop-types';

import DesignMain from './main';
import Location from './location';
import Describe from './describe';
import Energy from './energy';
import SiteDesign from './siteDesign';
import GenerateReport from './generateReport';
import routes from '../../routes';
import config from '../../config';
import {connect} from 'react-redux';
import {projectsActions} from '../../store/actions/projects';
import FullPageLoader from '../../components/FullPageLoader';

export class DesignStage extends Component {

  componentDidMount() {
    const {projectId, history, dispatch} = this.props;
    if (projectId === null) {
      const match = matchPath(history.location.pathname, {
        path: '/design/:projectId'
      });
      if (config.IS_DEMO) {
        history.push(routes.designMain);
      } else {
        if (match && match.params?.projectId !== 'new')
          dispatch(projectsActions.loadProject({id: match.params.projectId}));
        else if (history.location.pathname !== routes.designMain) {
          history.push(routes.designMain);
        }
      }
    }
  }

  render() {
    const { project, loading } = this.props;
    const {settings} = project;

    if (!settings) return <div/>;
    const isReadonly = config.IS_DEMO || settings.readonly;

    if (loading) {
      return <FullPageLoader text="Loading project..." />;
    }

    return (
      <Switch>
        <Route exact path={routes.designMain}>
          <DesignMain />
        </Route>
        <Route path={routes.designLocation}>
          <Location />
        </Route>
        <Route path={routes.designDescribe}>
          <Describe />
        </Route>
        <Route path={routes.designEnergy}>
          <Energy isReadonly={isReadonly} />
        </Route>
        <Route path={routes.designSiteDesign}>
          <SiteDesign />
        </Route>
        <Route path={routes.designGenerateReport}>
          <GenerateReport />
        </Route>
      </Switch>);
  }
}

DesignStage.propTypes = {
  project: PropTypes.object,
  projectId: PropTypes.string,
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  history: PropTypes.object
};

const WrappedDesignStage = withRouter(DesignStage);

const mapStateToProps = (state) => {
  return {
    projectId: state.projects.projectId,
    project: state.projects.project,
    loading: state.projects.loading
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedDesignStage);
