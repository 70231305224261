import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import {handleEnterPress} from '../../helpers/utils';

const FromBody = ({onInputChange, onButtonPress, fields}) => (
  fields.map((field, index) => (
    <Form.Group key={index}>
      <Form.Label>{field.label}</Form.Label>
      <Form.Control
        plaintext
        type={field.type}
        onChange={onInputChange}
        onKeyPress={event => handleEnterPress(event, onButtonPress)}
        name={field.name}
        defaultValue={field.default || ''}
      />
    </Form.Group>
  ))
);

FromBody.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onButtonPress: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    default: PropTypes.string
  })).isRequired
};

export default FromBody;
