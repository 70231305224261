import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../styles.scss';
import './styles.scss';
import MenuHeaderTitle from '../../../../components/operate/MenuHeaderTitle';
import Switch from '../../../../components/operate/Switch';
import Select from '../../../../components/operate/Select';
import {plantTesActions} from '../../../../store/actions/plantTes';
import {plantTesService} from '../../../../store/services/plantTes';
import {getFlowModeById} from 'helpers/store';
import {IrCamera} from '../../../../components/operate/devices/IrCamera';
import {grainStateOptions, paletteOptions, resolutionOptions} from '../../../../store/constants/plant';
import DeviceList from '../../../../components/operate/devices/DeviceList';
import {formatLastSeen} from '../../../../helpers/utils';
import {getTesStatus} from '../../../../store/selectors/plant';
import {deviceStatus} from '../../../../store/constants/plantTes';


export class MenuTesInfo extends Component {

  state = {
    maintenanceMode: null,
    flowMode: null,
    temperature: null,
    rangeTemperature: null,
    isChanging: false,
    pallete: paletteOptions[0],
    resolution: resolutionOptions[0],
    grainState: grainStateOptions[0]
  };

  canRender() {
    const {plant, modes, status} = this.props;
    const {maintenanceMode, flowMode} = this.state;
    return plant && modes && status && maintenanceMode !== null && flowMode;
  }

  componentDidMount() {
    const {status, dispatch} = this.props;
    dispatch(plantTesActions.getModes());
    this.setState({
      maintenanceMode: status?.global?.is_maintenance,
      flowMode: this.getModeById(status?.global?.flow_mode),
    });
  }

  componentDidUpdate() {
    const {maintenanceMode, flowMode, isChanging} = this.state;
    const {status, modes} = this.props;

    // propagate initial values for controls
    const newState = {};
    if ((maintenanceMode === null || maintenanceMode === undefined) && status && status.global)
      newState.maintenanceMode = status.global.is_maintenance;
    if (!flowMode && status && status.global && modes) {
      let newFlowMode = this.getModeById(status.global.flow_mode);
      if (newFlowMode) newState.flowMode = newFlowMode;
    }
    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }

    // waiting for changes
    if (isChanging &&
      Boolean(status?.global?.is_maintenance) === Boolean(maintenanceMode) &&
      status?.global?.flow_mode === parseInt(flowMode.value)) {
      this.setState({isChanging: false});
    }
  }

  onMaintenanceModeChange(checked) {
    const {modes} = this.props;
    const newState = {maintenanceMode: checked};
    if (checked === true) this.setState({flowMode: modes['maintenance'][0]});
    this.setState(newState);
    plantTesService.setMaintenanceMode(checked).then(response => {
      console.log('SetMaintenanceMode', response);
    }).catch(error => {
      this.setState({
        isChanging: false,
        maintenanceMode: !checked
      });
      alert('Error while changing tes mode: ' + error);
    });
  }

  onFlowModeChange(mode) {
    const prevMode = this.state.flowMode;
    this.setState({flowMode: mode});
    const confirmed = window.confirm(`Are you sure you want to set flow mode to '${mode.label}'?`);
    if (confirmed) {
      plantTesService.setFlowMode(mode.value).then(response => {
        console.log('SetFlowMode', response);
      }).catch(error => {
        alert('Error while changing tes flow mode: ' + error);
        this.setState({isChanging: false, flowMode: prevMode});
      });
    } else {
      this.setState({isChanging: false, flowMode: prevMode});
    }
  }

  onTemperatureChange(temperature) {
    this.setState({rangeTemperature: temperature});
  }

  onTemperatureFinalChange(temperature) {
    const confirmed = window.confirm(`Are you sure you want to set temperature to ${temperature[0]}°C?`);
    const prevTemperature = this.state.temperature;
    if (confirmed) {
      plantTesService.setTankTemperature(temperature[0]).then(response => {
        this.setState({temperature, isChanging: false});
        console.log('SetTankTemperature', response);
      }).catch(error => {
        alert('Error while changing tank temperature: ' + error);
        this.setState({rangeTemperature: prevTemperature, isChanging: false});
      });
    } else {
      this.setState({rangeTemperature: prevTemperature, isChanging: false});
    }
  }

  getModeById(modeId) {
    const {modes, status} = this.props;
    if (!status || !status.global) return;
    return getFlowModeById(modes, status.global.is_maintenance, modeId);
  }

  getTankTemperature(temperatureArray) {
    if (!temperatureArray) return;
    return temperatureArray.reduce((a, b) => a + parseInt(b.value), 0) / temperatureArray.length;
  }

  render() {
    const {plant, modes, status, liveVideo, streams, selectedStream, isReadonly} = this.props;
    const {maintenanceMode, flowMode, isChanging, pallete, resolution, grainState} = this.state;

    if (!this.canRender()) return <div />;
    const flowModeObj = this.getModeById(status.global.flow_mode);
    const lastSeen = status.global?.timestamp ? formatLastSeen(status.global.timestamp):'N/A';
    const showIrCameraOptions = liveVideo && streams && streams[selectedStream] &&
        streams[selectedStream].title.indexOf('vulcanus') !== -1;

    return (
      <React.Fragment>
        <div className={'menu-info-wrapper'}>
          <div className="menu-info p-0">
            <div className={'block pb-0'}>
              <MenuHeaderTitle subtitle={'TES details'} title={plant.title} online={status.online} />
            </div>
            <hr/>
            <div className={'block item'}>
              <div className={'clearfix mb-3'}>
                <div className={'item__text float-left'}>
                  TES Status
                  <div className={'item__small-text'}>Current status</div>
                </div>
                <div className={'item__value float-right'}>
                  {status.global && !status.global.is_maintenance ? 'Operational':'Maintenance'}
                  <div className={'item__small-text'}>{lastSeen}</div>
                </div>
              </div>
              <div className={'clearfix mb-3'}>
                <div className={'item__text float-left'}>State of charge</div>
                <div className={'item__value float-right'}>
                  {status.global?.status !== deviceStatus.ERROR
                    ? 'N/A':(status.global?.soc_percent?.toFixed(2) + '%')}
                </div>
              </div>
              <div className={'clearfix'}>
                <div className={'item__text float-left'}>Current Mode</div>
                <div className={'item__value float-right'}>
                  {flowModeObj && flowModeObj.label}
                </div>
              </div>
              {!isReadonly &&
              <div className={clsx('mt-3 mb-3', isChanging && 'is-changing')}>
                <Select
                  value={flowMode}
                  options={modes ? modes[(maintenanceMode ? 'maintenance':'operational')]:[]}
                  onChange={this.onFlowModeChange.bind(this)}
                  isDisabled={isChanging}
                />
              </div>
              }
              {!isReadonly &&
              <div className={clsx('clearfix', isChanging && 'is-changing')}>
                <div className={clsx('maintenance-text', 'float-left', maintenanceMode && 'maintenance-text--on')}>
                  Maintenance mode
                </div>
                <div className={'item__switch float-right'}>
                  <Switch
                    handleDiameter={18}
                    height={28}
                    width={60}
                    onChange={this.onMaintenanceModeChange.bind(this)}
                    checked={Boolean(maintenanceMode)}
                    disabled={isChanging}
                  />
                </div>
              </div>
              }
            </div>
            <hr/>
            <div className={'block item'}>
              <div className={'clearfix'}>
                <DeviceList status={status} />
              </div>

            </div>
            <hr />
            <div className={'block item'}>
              <div className={'clearfix'}>
                <div className={'item__text float-left'}>
                  IR Camera<br/>
                  <span className={'item__subtext'}>Current status</span>
                </div>
                <div className={'item__value float-right'}>
                  {status.camera && status.camera.active ? 'Active':'Inactive'}
                </div>
              </div>
            </div>
            {!isReadonly && showIrCameraOptions &&
              <IrCamera
                pallete={pallete}
                resolution={resolution}
                grainState={grainState}
                isActive={status.camera.active}
              />
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MenuTesInfo.propTypes = {
  plant: PropTypes.object,
  modes: PropTypes.object,
  status: PropTypes.object,
  dispatch: PropTypes.func,
  liveVideo: PropTypes.bool,
  streams: PropTypes.array,
  selectedStream: PropTypes.number,
  isReadonly: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    modes: state.plant.tes.modes,
    status: getTesStatus(state),
    liveVideo: state.menu.liveVideo,
    streams: state.plant.streams,
    selectedStream: state.plant.selectedStream,
    isReadonly: state.general.isReadonly
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuTesInfo);
