import axios from 'axios';
import {eventsUrls} from '../constants/events';
import {getPlantId} from '../../helpers/store';

export const eventsService = {
  getEvents,
  setEventNotes,
  dismissAll,
  getEventStream,
  setEventSystem
};

function getEvents() {
  return axios.request({
    method: 'GET',
    url: eventsUrls.GET_EVENTS.replace('<plantId>', getPlantId())
  });
}

function setEventNotes(id, note) {
  return axios.request({
    method: 'POST',
    url: eventsUrls.RESOLVE.replace('<plantId>', getPlantId()).replace('<id>', id),
    data: {
      note,
    }
  });
}

function dismissAll() {
  return axios.request({
    method: 'POST',
    url: eventsUrls.DISMISS_ALL.replace('<plantId>', getPlantId())
  });
}

function getEventStream(eventId) {
  return axios.request({
    method: 'GET',
    url: eventsUrls.GET_EVENT_STREAM.replace('<plantId>', getPlantId()).replace('<id>', eventId)
  });
}

function setEventSystem(value) {
  return axios.request({
    method: 'POST',
    url: eventsUrls.SET_EVENT_SYSTEM.replace('<plantId>', getPlantId()),
    data: {
      newValue: value ? 1 : 0,
    }
  });
}
