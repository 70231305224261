import React, {Component} from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

import './styles.scss';
import BigButton from '../../../components/operate/buttons/BigButton';
import {haltIcon, plantOfflineIcon} from '../../svg';
import {plantStatusTypes} from '../../../store/constants/plant';

export class PlantNotWorking extends Component {
  // TECHDEBT: helios and tes plants should use same statuses

  render() {
    const {lastSeenTimestamp, status, isOnline, onResumeButtonClick, isLoading, errorMessage} = this.props;
    const isHalted = status === plantStatusTypes.HALTED;
    const isManuallyHalted = status === plantStatusTypes.MANUALLY_HALTED;
    const isErrorHalted = status === plantStatusTypes.ERROR_HALTED;
    const isOperational = !isHalted && !isManuallyHalted && !isErrorHalted;
    const isOffline = !isOnline;
    let lastSeen;
    if (lastSeenTimestamp) {
      lastSeen = format(new Date(lastSeenTimestamp), 'dd.MM HH:mm:ss');
    }
    let content;

    if (isOffline && isOperational) {
      content = (
        <React.Fragment>
          <div className={'halt-alert'}>
            {plantOfflineIcon()}
            <div className={'halt-alert__title'}>The Power Plant <br/>is OFFLINE</div>
            {lastSeen &&
              <React.Fragment>
                <span className={'halt-alert__text'}>Last connection:</span>
                <div className={'halt-alert__timer'}>{lastSeen}</div>
              </React.Fragment>
            }
          </div>
        </React.Fragment>
      );
    } else {
      content = (
        <React.Fragment>
          <div className={'halt-alert'}>
            {haltIcon()}
            {isHalted && <div className={'halt-alert__title'}>The Power Plant <br/>is STOPPED</div>}
            {isManuallyHalted &&
              <div className={'halt-alert__title'}>The EMERGENCY<br/> BUTTON was pressed<br/>
                and the power plant production has been STOPPED</div>
            }
            {isErrorHalted &&
              <div className={'halt-alert__title'}>
                The Power Plant<br/> is STOPPED<br/>
                Error occurred: {errorMessage || 'Unknown message'}
              </div>
            }
            <span className={'halt-alert__text'}>Last connection:</span>
            <div className={'halt-alert__timer'}>{lastSeen}</div>
          </div>
          {isHalted ? <BigButton text={isLoading ? null:'resume production'}
            onClick={onResumeButtonClick}/> : ''}
        </React.Fragment>
      );
    }


    return (
      <div className={'halt-wrapper'}>
        <div className={'halt-alert__wrapper'}>
          {content}
        </div>
      </div>
    );
  }
}

PlantNotWorking.propTypes = {
  lastSeenTimestamp: PropTypes.number,
  status: PropTypes.number,
  errorMessage: PropTypes.string,
  isOnline: PropTypes.bool,
  isLoading: PropTypes.bool,
  onResumeButtonClick: PropTypes.func
};

export default PlantNotWorking;
