export const plantConstants = {
  GETTING_LIST: 'PLANT_GETTING_LIST',
  GET_LIST_SUCCEEDED: 'PLANT_GET_LIST_SUCCEEDED',
  GET_LIST_ERROR: 'PLANT_GET_LIST_ERROR',
  GET_DETAILS: 'PLANT_GET_DETAILS',
  GET_DETAILS_SUCCEEDED: 'PLANT_GET_DETAILS_SUCCEEDED',
  GET_DETAILS_ERROR: 'PLANT_GET_DETAILS_ERROR',
  GET_STREAMS_SUCCEEDED: 'PLANT_GET_STREAMS_SUCCEEDED',
  GET_STATUS_SUCCEEDED: 'PLANT_GET_STATUS_SUCCEEDED',
  NEXT_STREAM: 'PLANT_NEXT_STREAM',
  UPDATE_ONLINE: 'PLANT_UPDATE_ONLINE',
  UPDATE_STATUS: 'PLANT_UPDATE_STATUS',
  GET_SKY_IMAGES: 'PLANT_GET_SKY_IMAGES',
  GET_SKY_IMAGES_SUCCEEDED: 'PLANT_GET_SKY_IMAGES_SUCCEEDED',
  GET_SKY_IMAGES_ERROR: 'PLANT_GET_SKY_IMAGES_ERROR',
  NEW_SKY_IMAGES: 'PLANT_NEW_SKY_IMAGES'

};

export const plantUrls = {
  GET_LIST: '/plant/list',
  GET_DETAILS: '/plant/<plantId>',
  GET_STATUS: '/plant',
  RESUME_PRODUCTION: '/plant/resume-production',
  HALT_PRODUCTION: '/plant/halt-production',
  GET_STREAMS: '/plant/<plantId>/get-streams',
  GET_STREAM_TOKEN: '/stream-jwt',
  GET_SKY_IMAGES: '/plant/<plantId>/sky-images'
};

export const plantStatus = {
  0: 'Operational',
  1: 'Stopped',
  2: 'Manually stopped'
};

export const plantStatusTypes = {
  OPERATIONAL: 0,
  HALTED: 1,
  MANUALLY_HALTED: 2,
  ERROR_HALTED: 3
};

export const plantModules = {
  HELIOSTATS: 0,
  TES: 1,
  WEATHER: 2,
  SKY_CAM: 3
};

export const paletteOptions = [
  {value: 1, label: 'Iron Black'}
];

export const resolutionOptions = [
  {value: 1, label: 'High (0,01K)'}
];

export const grainStateOptions = [
  {value: 1, label: 'High'}
];
