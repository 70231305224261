import React, {Component} from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import videojs from 'video.js';

import './styles.scss';
import {HeliostatActions} from '../../../store/actions/heliostat';
import {plantService} from '../../../store/services/plant';
import {store} from '../../../store';
import {checkVideoAutoPlay} from '../../../helpers/video';
import Video from '../../../components/operate/Video';
import {hasModuleActive} from '../../../helpers/plant';
import {plantModules} from '../../../store/constants/plant';


export class PlantLivePreview extends Component {

  player = null;
  state = {
    showManualPlay: false,
    videoState: null
  }

  componentDidMount() {
    const {plant, dispatch, setInitializeVideoFunc, streams} = this.props;
    if (hasModuleActive({plant, module: plantModules.HELIOSTATS})) {
      dispatch(HeliostatActions.getHeliostatList());
    }
    if (plant && streams && streams.length > 0) {
      this.initializeVideo();
    }
    setInitializeVideoFunc(this.initializeVideo.bind(this));
  }

  componentDidUpdate(prevProps) {
    const {plant, plantStatus, streams} = this.props;
    if ((prevProps && prevProps.plant && plant && plantStatus && prevProps.plant.id !== plant.id) ||
        (!prevProps || !prevProps.plant) && plant && streams && streams.length > 0) {
      this.delayInitializeVideo();
    }
    if (prevProps && prevProps.streams !== streams && streams && streams.length > 0) {
      console.log('ComponentDidUpdate: delayInitializeVideo');
      this.delayInitializeVideo();
    }
  }

  componentWillUnmount() {
    if (this.player) this.player.dispose();
  }

  delayInitializeVideo() {
    checkVideoAutoPlay(
      () => this.initializeVideo(),
      () => this.setState({showManualPlay: true})
    );
  }

  initializeVideo() {
    if (!this.player) {
      const videoElement = document.getElementById('live-video');
      console.log('Creating video', videoElement);
      if (!videoElement) {
        console.log('Cannot find video element', videoElement);
        return;
      }
      const _this = this;
      this.player = videojs(videoElement, { controls: false, autoplay: 'muted', width: '100%', height: '100vh' },
        function() {
          this.on('play', () => _this.setState({videoState: 'PLAYING'}));
          this.on('playing', () => _this.setState({videoState: 'PLAYING'}));
          this.on('waiting', () => _this.setState({videoState: 'BUFFERING'}));
          this.on('pause', () => _this.setState({videoState: 'PAUSED'}));
          this.on('stop', () => _this.setState({videoState: 'STOPPED'}));
        });
    }
    this.loadVideo();
  }

  loadVideo(attempt=1) {
    const {plantStatus} = this.props;
    console.log('[PlantLivePreview] Loading new video for', plantStatus);
    //TODO: pass chosenStream as param? stream has device_type to show
    let chosenStream = 0;
    const stream = store.getState().plant.streams[chosenStream];
    if (!stream) return;
    console.log('Getting url for stream:', stream.arn);
    if (attempt === 1) this.setState({videoState: 'GETTING_URL'});
    plantService.getStreamToken(stream.arn).then(response => {
      const data = response.data?.data;
      const streamState = data.stream_state;
      if (streamState !== 'LIVE') {
        if (attempt === 10) {
          this.setState({videoState: 'ONDEMAND_ERROR'});
        } else {
          this.setState({videoState: 'ONDEMAND'});
          setTimeout(() => this.loadVideo(attempt + 1), 5000);
        }
      } else {
        const token = response.data.data.jwt?.body;
        const streamUrl = `${stream.url}?token=${token}`;
        this.setState({videoState: 'LOADING'});

        console.log('Call loadVideo', streamUrl);
        this.player.src({
          src: streamUrl
        });
      }
    }).catch(error => {
      if (error.response.status === 404){
        alert('Video for this event is no available!');
      }
      else {
        alert('Error while getting stream url:' + error);
      }
    });
  }

  manualPlay() {
    this.setState({showManualPlay: false});
    this.initializeVideo();
  }

  render() {
    const {plant} = this.props;
    const {showManualPlay, videoState} = this.state;
    if (!plant) return <div />;

    return (
      <div style={{'backgroundColor': 'black', 'width': '100%'}}>
        <Video
          showManualPlay={showManualPlay}
          manualPlayOnClick={this.manualPlay.bind(this)}
          state={videoState}
        />
      </div>
    );
  }
}

PlantLivePreview.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  menuInfo: PropTypes.bool,
  plant: PropTypes.object,
  plantStatus: PropTypes.object,
  setInitializeVideoFunc: PropTypes.func,
  streams: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    plantStatus: state.plant.status,
    menuInfo: state.menu.menuInfo,
    streams: state.plant.streams
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlantLivePreview));
