import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { ProjectCard } from './projectCard';

export const ProjectsGrid = ({ projects, loadProject }) => {
  return (
    <Row>
      {projects.map((p, i) => (
        <Col key={i} sm={12} md={6} lg={4}>
          <ProjectCard project={p} loadProject={loadProject} />
        </Col>)
      )}
    </Row>
  );
};

ProjectsGrid.propTypes = {
  projects: PropTypes.array,
  loadProject: PropTypes.func.isRequired
};
