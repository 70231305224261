import {Button, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import QRCode from 'qrcode.react';

import config from '../../../config';
import FromBody from '../../FormBody';

const MfaSetupForm = ({onInputChange, onButtonPress, onBackPress, disabled, mfaData}) => {
  const str =
    `otpauth://totp/AWSCognito:${mfaData.username}?secret=${mfaData.mfaSetupCode}&issuer=${config.AUTH_ISSUER}`;
  return (
    <Form className={'login-form'}>
      <p>
        You need to configure MFA. Please scan this QR code with authenticator application.<br/>
        Then please provide code generated from this application.
      </p>
      <QRCode value={str}/>
      <FromBody
        onButtonPress={onButtonPress}
        fields={[
          {label: 'Code', type: 'text', name: 'mfaCode'},
        ]}
        onInputChange={onInputChange}
      />
      <Form.Group className={'login-form__footer'}>
        <Button onClick={onButtonPress} disabled={disabled}>Confirm</Button><br/><br/>
        <Button onClick={onBackPress} disabled={disabled} variant={'secondary'}>Back to login</Button>
      </Form.Group>
    </Form>
  );
};

MfaSetupForm.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onButtonPress: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  mfaData: PropTypes.object.isRequired
};

export default MfaSetupForm;
