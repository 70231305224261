import React, {useState} from 'react';
import {plantTesService} from '../../../store/services/plantTes';
import {deviceStatus, deviceStatusMap} from '../../../store/constants/plantTes';
import Switch from '../Switch';
import PropTypes from 'prop-types';

const ValveMenu = ({valve, isMaintenanceMode, isReadonly}) => {

  const [switchState, setSwitchState] = useState(Boolean(valve.status));

  const onSwitchChange = (valveId, checked) => {
    const verboseState = checked ? 'open':'close';
    const state = checked ? 1:0;
    const confirmed = window.confirm(`Are you sure you want to set valve state to ${verboseState}?`);
    if (confirmed) {
      plantTesService.setValveState(valveId, state).then(() => {
        setSwitchState(Boolean(state));
      }).catch(error => {
        alert('Error while changing valve state: ' + error);
      });
    }
  };

  const renderSwitch = (
    valve.status === deviceStatus.ERROR || valve.status === undefined?
      <div>&nbsp;Error</div>:(
        <Switch
          handleDiameter={12}
          height={20}
          width={50}
          onChange={(checked) => onSwitchChange(valve.object_id, checked)}
          onText="Open"
          offText="Close"
          checked={switchState}
        />
      )
  );

  return (
    <div className="schema-context-menu__item">
      <div>
        State: {deviceStatusMap[valve.status]}
      </div>
      {(!isReadonly && isMaintenanceMode) && renderSwitch}
    </div>
  );
};

ValveMenu.propTypes = {
  valve: PropTypes.object.isRequired,
  isMaintenanceMode: PropTypes.bool.isRequired,
  isReadonly: PropTypes.bool.isRequired
};

export default ValveMenu;
