// commands from Unity
const GET_PROJECT_DATA_TYPE = 'projectJson';
const GET_TES_CAPACITY_DATA_TYPE = 'tesCapacity';
const DESELECT_TOOL = 'deselect';
const MAP_LOADED = 'mapLoaded';
const OBJECT_SELECTED = 'objectSelect';
const OBJECT_DESELECTED = 'objectDeselect';
const OBJECT_ROTATION = 'objectRotation';
const AREA_CREATED = 'areaCreated';
const ALERT = 'alert';

// commands to unity
const SET_LAT_LON = 'SetLatLon';
const TOGGLE_TOP_VIEW = 'ToggleTopView';
const TOGGLE_CAMERA = 'ToggleCamera';
const DRAW_AREA = 'EnableAreaDrawingTool';
const DRAW_PV = 'ActivatePvPlacement';
const DRAW_STORAGE = 'ActivateTesPlacement';
const DRAW_CSP = 'ActivateTowerPlacement';
const SET_EDIT_MODE = 'EditModeEnabled';
const GIVE_KEYBOARD_CONTROL = 'GiveUnityKeyboardControl';
const GET_PROJECT_JSON = 'GetProjectAsJson';
const GET_TES_CAPACITY = 'GetTesCapacity';
const LOAD_PROJECT_FROM_JSON = 'LoadProjectAsJson';
const KEY_PRESSED = 'ButtonPressed';
const KEY_RELEASED = 'ButtonReleased';
const SET_ROTATION = 'SetRotation';
const DELETE_OBJECT = 'DeleteObject';
const FILL_AREA_WITH_PVS = 'FillLastAreaWithPvs';
const FORCE_STOP = 'StopUnityProject';
const PENCIL_MODE = 'SetAreaToolPencilMode';
const SNAPPING = 'SetSnappingEnabled';
const COMPASS = 'ShowCompass';

const SUPPORTED_KEYS_MAP = {
  'ShiftLeft': 'leftShift',
  'ControlLeft': 'leftControl'
};

export class UnityProjectDesignCommunication {
  _instance = null;
  data = null;
  _callbacks = null;
  _listeners = null;
  _keysPressed = [];

  constructor(instance) {
    this._instance = instance;
    this.data = {
      msgType: null,
      value: null
    };
    this._callbacks = {
      [GET_PROJECT_DATA_TYPE]: [],
      [GET_TES_CAPACITY_DATA_TYPE]: []
    };
    this._listeners = {
      [DESELECT_TOOL]: [],
      [MAP_LOADED]: [],
      [OBJECT_SELECTED]: [],
      [OBJECT_DESELECTED]: [],
      [OBJECT_ROTATION]: [],
      [AREA_CREATED]: [],
      [ALERT]: [],
    };
    window.IncomingUnityData = this._incomingUnityData.bind(this);
    window.IncomingDataStreamFromUnity = this._incomingDataStreamFromUnity.bind(this);
    window.ConcatenateDataStream = this._concatenateDataStream.bind(this);
    window.EndDataStream = this._endDataStream.bind(this);
  }

  setLocation(location) {
    const {lat, lng} = location;
    const unityLocation = `${lat},${lng}`;
    this._sendMessage(SET_LAT_LON, unityLocation);
  }

  toggleView() { this._sendMessage(TOGGLE_TOP_VIEW); }
  toggleCamera() { this._sendMessage(TOGGLE_CAMERA); }
  drawArea() { this._sendMessage(DRAW_AREA); }
  drawPv(params) { this._sendMessage(DRAW_PV, JSON.stringify(params)); }
  drawStorage(params) { this._sendMessage(DRAW_STORAGE, JSON.stringify(params)); }
  drawCsp() { this._sendMessage(DRAW_CSP); }
  setEditMode(state) { this._sendMessage(SET_EDIT_MODE, String(state)); }
  setGiveUnityKeyboardControl(state) { this._sendMessage(GIVE_KEYBOARD_CONTROL, String(state)); }
  getProjectData(callback) {
    if (callback) this._addCallback(GET_PROJECT_DATA_TYPE, callback);
    this._sendMessage(GET_PROJECT_JSON);
  }
  getTesCapacity(callback) {
    if (callback) this._addCallback(GET_TES_CAPACITY_DATA_TYPE, callback);
    this._sendMessage(GET_TES_CAPACITY);
  }
  loadProjectFromData(data) { this._sendMessage(LOAD_PROJECT_FROM_JSON, data); }
  addMapLoadedListener(listener) { this._addListener(MAP_LOADED, listener); }
  addDeselectListener(listener) { this._addListener(DESELECT_TOOL, listener); }
  addSelectObjectListener(listener) { this._addListener(OBJECT_SELECTED, listener); }
  addDeselectObjectListener(listener) { this._addListener(OBJECT_DESELECTED, listener); }
  addObjectRotationListener(listener) { this._addListener(OBJECT_ROTATION, listener); }
  addAreaCreatedListener(listener) { this._addListener(AREA_CREATED, listener); }
  addAlertListener(listener) { this._addListener(ALERT, listener); }
  sendKeyPressed(key) {
    // send only supported keys and not pressed
    const mappedKey = SUPPORTED_KEYS_MAP[key];
    if (!mappedKey || this._keysPressed.includes(mappedKey)) return;
    this._keysPressed.push(mappedKey);
    this._sendMessage(KEY_PRESSED, mappedKey);
  }
  sendKeyReleased(key) {
    // send only supported keys and pressed before
    const mappedKey = SUPPORTED_KEYS_MAP[key];
    const pressedIndex = this._keysPressed.indexOf(mappedKey);
    if (!mappedKey || pressedIndex === -1) return;
    this._keysPressed.splice(pressedIndex, 1);
    this._sendMessage(KEY_RELEASED, mappedKey);
  }
  setRotation(rotation) {
    if (isNaN(rotation)) return;
    this._sendMessage(SET_ROTATION, rotation);
  }
  deleteObject(guid) {
    this._sendMessage(DELETE_OBJECT, guid);
  }
  fillAreaWithPvs(restrictive) {
    this._sendMessage(FILL_AREA_WITH_PVS, restrictive ? 'true' : 'false');
  }
  forceStopUnity() {
    this._sendMessage(FORCE_STOP);
  }
  setPencilMode(enable) {
    this._sendMessage(PENCIL_MODE, enable ? 'true' : 'false');
  }
  setSnappingEnabled(enable) {
    this._sendMessage(SNAPPING, enable ? 'true' : 'false');
  }
  setCompassEnabled(enable) {
    this._sendMessage(COMPASS, enable ? 'true' : 'false');
  }

  _addCallback(msgType, callback) {
    this._callbacks[msgType].push(callback);
  }

  _addListener(msgType, listener) {
    this._listeners[msgType].push(listener);
  }

  _invokeCallbacksAndListeners() {
    this._invokeCallbacks();
    this._invokeListeners();
  }

  _invokeCallbacks() {
    const msgType = JSON.parse(JSON.stringify(this.data.msgType));
    const callbacks = this._callbacks[msgType];
    if (!callbacks) return;
    callbacks.forEach((callback) => {
      callback(this.data.value);
    });
    this._callbacks[msgType] = [];
  }

  _invokeListeners() {
    const listeners = this._listeners[this.data.msgType];
    if (!listeners) return;
    listeners.forEach((listener) => {
      listener(this.data.value);
    });
  }

  _sendMessage(functionName, ...args) {
    console.log('Sending to unity', functionName, ...args);
    this._instance.send('BrowserBridge', functionName, ...args);
  }

  _incomingUnityData(msgType, value) {
    console.log('IncomingUnityData', msgType, value);
    this.data.msgType = msgType;
    this.data.value = value;
    this._invokeCallbacksAndListeners();
  }

  _incomingDataStreamFromUnity(msgType, value) {
    console.log('IncomingUnityDataStream', msgType, value);
    this.data.msgType = msgType;
    this.data.value = value;
  }

  _concatenateDataStream(value) {
    console.log('UnityDataStream', value);
    this.data.value += value;
  }

  _endDataStream(value) {
    console.log('EndDataStream', value);
    this.data.value += value;
    this._invokeCallbacksAndListeners();
  }
}
