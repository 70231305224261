import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import './reportCard.scss';

export const ReportCard = ({ variant, header, body, isUpdating }) => (
  <Card className={`report-card ${variant} ${isUpdating === true ? 'report-card__loading' : ''}`}>
    <Card.Header className="form-text">{header}</Card.Header>
    <Card.Body>{isUpdating === true ? 'Calculating...' : body}</Card.Body>
  </Card>
);

ReportCard.propTypes = {
  variant: PropTypes.string,
  header: PropTypes.string.isRequired,
  body: PropTypes.string,
  isUpdating: PropTypes.bool
};
