import {projectsConstants, initialProject} from '../constants/projects';

const initialState = {
  projectId: null,
  project: initialProject,
  loading: false
};

export const projects = (state = initialState, action) => {
  let newProjectState;
  switch (action.type) {
    case projectsConstants.SET_PROJECT_ID:
      return {...state, projectId: action.payload};
    case projectsConstants.NEW_PROJECT:
      return {
        ...state,
        projectId: 'new',
        project: initialProject,
        loading: false
      };
    case projectsConstants.LOADING_PROJECT:
      return {
        ...state,
        loading: true
      };
    case projectsConstants.LOAD_PROJECT_SUCCESS:
      return {
        ...state,
        projectId: action.payload.id,
        project: action.payload,
        loading: false
      };
    case projectsConstants.LOAD_PROJECT_ERROR:
      return {
        ...state,
        loading: false
      };
    case projectsConstants.UPDATE_PROJECT:
      newProjectState = JSON.parse(JSON.stringify(state.project));
      for (const [model, modelUpdate] of Object.entries(action.payload)) {
        if (!newProjectState[model]) newProjectState[model] = {};
        Object.assign(newProjectState[model], modelUpdate);
      }
      console.log('UPDATE PROJECT', action.payload, newProjectState);
      return {
        ...state,
        project: newProjectState
      };
    default:
      return state;
  }
};
