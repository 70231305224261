import {MenuHalt, MenuInfo, MenuEvents, MenuDesign} from '../constants/menu';
import {plantConstants} from '../constants/plant';

const initialState = {
  menuInfo: false,
  menuTesInfo: false,
  menuHalt: false,
  menuEvents: false,
  menuCloudViewer: false,
  infoIconActive: false,
  tesInfoIconActive: false,
  haltIconActive: false,
  eventsIconActive: false,
  innerMenu: false,
  menuInfoPlant: true,
  menuInfoHeliostat: false,
  menuInfoMaintenance: false,
  menuInfoProduction: false,
  menuHaltProduction: true,
  menuHaltProgress: false,
  menuHaltEmergency: false,
  menuEventsInfo: false,
  menuEventsDetails: false,
  liveVideo: true,
  eventDetails: {},

  designMenuCollapse: false
};

export const menu = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case MenuInfo.TOGGLE_MENU_INFO:
      newState = {
        menuInfo: !state.menuInfo,
        menuHalt: false,
        menuEvents: false,
        menuTesInfo: false,
        menuCloudViewer: false,
        menuEventsInfo: false,
        menuEventsDetails: false,
        haltIconActive: false,
        eventsIconActive: false,
        menuInfoPlant: false,
        menuInfoHeliostat: false,
        infoIconActive: !state.infoIconActive
      };
      if (newState.menuInfo) {
        newState.menuInfoPlant = true;
        newState.menuInfoHeliostat = false;
      }
      return {
        ...state,
        ...newState
      };

    case MenuInfo.TOGGLE_MENU_TES_INFO:
      newState = {
        menuTesInfo: !state.menuTesInfo,
        menuInfo: false,
        menuCloudViewer: false,
        menuHalt: false,
        menuEvents: false,
        menuEventsInfo: false,
        menuEventsDetails: false,
        haltIconActive: false,
        eventsIconActive: false,
        infoIconActive: false,
        tesInfoIconActive: !state.tesInfoIconActive
      };
      return {
        ...state,
        ...newState
      };

    case MenuInfo.TOGGLE_MENU_CLOUD_VIEWER:
      newState = {
        menuCloudViewer: !state.menuCloudViewer,
        menuTesInfo: false,
        menuInfo: false,
        menuHalt: false,
        menuEvents: false,
        menuEventsInfo: false,
        menuEventsDetails: false,
        haltIconActive: false,
        eventsIconActive: false,
        infoIconActive: false,
        tesInfoIconActive: false
      };
      return {
        ...state,
        ...newState
      };

    case MenuHalt.TOGGLE_MENU_HALT:
      return {
        ...state,
        menuHalt: !state.menuHalt,
        menuInfo: false,
        menuTesInfo: false,
        menuCloudViewer: false,
        infoIconActive: false,
        tesInfoIconActive: false,
        haltIconActive: !state.haltIconActive,
        menuEventsInfo: false,
        menuEventsDetails: false,
        menuEvents: false,
        eventsIconActive: false,
        menuHaltProduction: true
      };

    case MenuHalt.SET_HALT_PROGRESS:
      return {...state, menuHaltProduction: false, menuHaltProgress: true};

    case MenuHalt.SET_HALT_EMERGENCY:
      return {
        ...state,
        menuHaltEmergency: true,
        menuHaltProgress: false,
        menuCloudViewer: false,
        innerMenu: false,
        menuInfoPlant: true,
        menuHaltProduction: false,
        menuHalt: true,
        menuInfo: false,
        menuTesInfo: false
      };

    case MenuHalt.SET_HALT_PRODUCTION:
      return {...state, menuHaltEmergency: false, menuHaltProduction: true};

    case MenuInfo.TOGGLE_INNER_MENU:
      return {...state, innerMenu: !state.innerMenu};

    case MenuInfo.CLOSE_INNER_MENU:
      return {...state, innerMenu: false};

    case MenuInfo.SET_MENU_INFO_HELIOSTAT:
      return {
        ...state,
        menuInfoPlant: false,
        menuInfoHeliostat: true,
        menuCloudViewer: false,
        menuInfoMaintenance: false,
        menuInfoProduction: false,
        innerMenu: false
      };

    case MenuInfo.SET_MENU_INFO_PLANT:
      return {
        ...state,
        menuInfo: true,
        menuTesInfo: false,
        menuCloudViewer: false,
        menuHalt: false,
        infoIconActive: true,
        tesInfoIconActive: false,
        haltIconActive: false,
        innerMenu: true,
        menuInfoPlant: true,
        menuInfoHeliostat: false,
        menuInfoMaintenance: false,
        menuInfoProduction: false,
        menuHaltProduction: true,
        menuHaltProgress: false,
        menuHaltEmergency: false
      };

    case MenuInfo.SET_MENU_TES_INFO:
      return {
        ...state,
        menuInfo: false,
        menuTesInfo: true,
        menuCloudViewer: false,
        menuHalt: false,
        infoIconActive: false,
        tesInfoIconActive: true,
        haltIconActive: false,
        innerMenu: false,
        menuInfoPlant: false,
        menuInfoHeliostat: false,
        menuInfoMaintenance: false,
        menuInfoProduction: false,
        menuHaltProduction: false,
        menuHaltProgress: false,
        menuHaltEmergency: false
      };

    case MenuInfo.SET_MENU_INFO_MAINTENANCE:
      return {...state, menuInfoHeliostat: false, menuInfoMaintenance: true};

    case MenuInfo.SET_MENU_INFO_PRODUCTION:
      return {...state, menuInfoMaintenance: false, menuInfoProduction: true};

    case plantConstants.SELECT:
      return {
        ...state,
        liveVideo: true,
        menuInfo: true,
        menuTesInfo: true,
        menuHalt: false,
        menuCloudViewer: false,
        infoIconActive: true,
        tesInfoIconActive: true,
        haltIconActive: false,
        innerMenu: false,
        menuInfoPlant: true,
        menuInfoHeliostat: false,
        menuInfoMaintenance: false,
        menuInfoProduction: false,
        menuHaltProduction: false,
        menuHaltProgress: false,
        menuHaltEmergency: false,
        menuEvents: false,
        menuEventsInfo: false,
        menuEventsDetails: false,
        eventsIconActive: false,
      };

    case MenuInfo.SET_LIVE_VIDEO:
      return {
        ...state,
        liveVideo: true
      };

    case MenuInfo.UNSET_LIVE_VIDEO:
      return {
        ...state,
        liveVideo: false
      };

    case MenuEvents.TOGGLE_MENU_EVENTS:
      newState = {
        menuEvents: !state.menuEvents,
        menuEventsInfo: !state.menuEventsInfo,
        menuHalt: false,
        menuInfo: false,
        menuCloudViewer: false,
        menuTesInfo: false,
        haltIconActive: false,
        infoIconActive: false,
        tesInfoIconActive: false,
        eventsIconActive: !state.eventsIconActive,
      };
      return {
        ...state,
        ...newState
      };

    case MenuEvents.SET_MENU_EVENTS:
      return {
        ...state,
        menuEventsDetails: false,
        menuEventsInfo: true,
        menuEvents: true,
        eventsIconActive: true,
        menuCloudViewer: false,
        menuHalt: false,
        menuTesInfo: false,
        haltIconActive: false,
        menuInfoPlant: false,
        menuInfoHeliostat: false,
        infoIconActive: false,
        tesInfoIconActive: false
      };

    case MenuEvents.SET_MENU_DETAILS:
      return {...state, menuEventsInfo: false, menuEventsDetails: true, eventDetails: action.payload};

    case MenuDesign.DESIGN_MENU_SET_COLLAPSE:
      return {
        ...state,
        designMenuCollapse: action.payload
      };
    default:
      return state;
  }
};
