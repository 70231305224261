import React from 'react';

import DeviceTypeInfo from '../DeviceTypeInfo';
import PropTypes from 'prop-types';
import {deviceTypes} from '../../../../store/constants/plantTes';


const DeviceList = ({status}) => (
  <>
    {status?.tank?.length &&
    <DeviceTypeInfo
      title={'Tanks'}
      subtitle={`Devices: ${status?.tank?.length}`}
      devices={status?.tank}
      type={deviceTypes.TANK}
    />
    }
    {status?.heater?.length &&
    <DeviceTypeInfo
      title={'Heaters'}
      subtitle={`Devices: ${status?.heater?.length}`}
      devices={status?.heater}
      type={deviceTypes.HEATER}
    />
    }
    {status?.hex?.length &&
    <DeviceTypeInfo
      title={'Hexes'}
      subtitle={`Devices: ${status?.hex?.length}`}
      devices={status?.hex}
      type={deviceTypes.HEX}
    />
    }
    {status?.pump?.length &&
    <DeviceTypeInfo
      title={'Pumps'}
      subtitle={`Devices: ${status?.pump?.length}`}
      devices={status?.pump}
      type={deviceTypes.PUMP}
    />
    }
    {status?.valve?.length &&
    <DeviceTypeInfo
      title={'Valves'}
      subtitle={`Devices: ${status?.valve?.length}`}
      devices={status?.valve}
      type={deviceTypes.VALVE}
    />
    }
    {status?.pipe?.length &&
    <DeviceTypeInfo
      title={'Pipes'}
      subtitle={`Devices: ${status?.pipe?.length}`}
      devices={status?.pipe}
      type={deviceTypes.PIPE}
    />
    }
  </>
);

DeviceList.propTypes = {
  status: PropTypes.object.isRequired
};

export default DeviceList;
