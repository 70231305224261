import {Button, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import FromBody from '../../FormBody';

const PasswordResetConfirmForm = ({onInputChange, onButtonPress, onBackPress, disabled, defaultUsername}) => (
  <Form className={'login-form'}>
    <p>Please provide username, one-time code and new password to set new password.</p>
    <FromBody
      onButtonPress={onButtonPress}
      fields={[
        {label: 'Username', type: 'text', name: 'username', default: defaultUsername},
        {label: 'One-time code', type: 'text', name: 'code'},
        {label: 'Password', type: 'password', name: 'newPassword1'},
        {label: 'Repeat password', type: 'password', name: 'newPassword2'},
      ]}
      onInputChange={onInputChange}
    />
    <Form.Group className={'login-form__footer'}>
      <Button onClick={onButtonPress} disabled={disabled}>Confirm</Button><br/><br/>
      <Button onClick={onBackPress} disabled={disabled} variant={'secondary'}>Back to login</Button>
    </Form.Group>
  </Form>
);

PasswordResetConfirmForm.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onButtonPress: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultUsername: PropTypes.string
};

export default PasswordResetConfirmForm;
