export const projectEnergyPropertyConstants = {
  storageSize: 0,
  year: 2020,
  price: 60000,
  chargeCost: 0,
  efficiency: 95,
  availability: 99.7,
  dualCycle: 0,
  totalClientLoad: 0,
  totalRenewable: 0,
  totalExcessEnergy: 0,
  totalStorageFeed: 0,
  totalGridDemand: 0,
  hours: [
    {
      hour: 0,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 1,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    },
    {
      hour: 2,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 3,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    },
    {
      hour: 4,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 5,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    },
    {
      hour: 6,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 7,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    },
    {
      hour: 8,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 9,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    },
    {
      hour: 10,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 11,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    },
    {
      hour: 12,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 13,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    },
    {
      hour: 14,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 15,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 16,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    },
    {
      hour: 17,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 18,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    },
    {
      hour: 19,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 20,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    },
    {
      hour: 21,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 22,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }, {
      hour: 23,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }
  ]
};
