import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../styles.scss';
import {formatLastSeen} from '../../../../helpers/utils';
import {connect} from 'react-redux';
import {isDeviceSelected} from '../../../../helpers/plant';
import {deviceStatus, deviceStatusMap, deviceTypes} from '../../../../store/constants/plantTes';
import {plantTesActions} from '../../../../store/actions/plantTes';
import {getTemperatureRow} from '../utils';

// TECHDEBT: move styles to component
// TECHDEBT: create more generic component for tables
class HexesTable extends React.Component {
  getHexRow = (hex, index, isSelected) => {
    // cols: id, status, is door open, last seen
    return (
      <tbody key={`hex-${index}`} >
        <tr
          onClick={() => this.onHexClick(hex, isSelected)}
          className={clsx('table__row', {'table__row--selected': isSelected, 'table__row--error':
              hex.status === deviceStatus.ERROR})}
        >
          <td scope="row">{hex.object_id}</td>
          <td scope="row">{deviceStatusMap[hex.status]}</td>
          <td scope="row">{hex.is_door_open ? 'Yes':'No'}</td>
          <td className={'table__cell'}>{formatLastSeen(hex.timestamp)}</td>
        </tr>
        {isSelected && hex.temperature_sensors ?
          hex.temperature_sensors.map((obj, idx) => getTemperatureRow(obj, idx, 3)):null}
      </tbody>
    );
  };

  isSelected = (hex) => {
    const {selectedDevices} = this.props;
    return isDeviceSelected({selectedDevices, type: deviceTypes.HEX, device: hex});
  };

  onHexClick(hex, isSelected) {
    const {dispatch} = this.props;
    if (isSelected) dispatch(plantTesActions.unselectDevice(deviceTypes.HEX, hex));
    else dispatch(plantTesActions.selectDevice(deviceTypes.HEX, hex));
  }

  render() {
    const {hexes} = this.props;
    return (
      <div className={'table'}>
        <table>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Status</th>
              <th scope="col">Is door open?</th>
              <th scope="col" className={'table__cell'}>Last seen</th>
            </tr>
          </thead>
          {
            hexes && hexes.length > 0 ? (
              <>
                {
                  hexes.map((hex, index) =>
                    (this.getHexRow(hex, index, this.isSelected(hex))))
                }
              </>) : <tbody>
              <tr>
                <td>No hexes</td>
              </tr>
            </tbody>
          }
        </table>
      </div>
    );
  }
}

HexesTable.propTypes = {
  hexes: PropTypes.array,
  selectedDevices: PropTypes.array,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    selectedDevices: state.plant.selectedDevices
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HexesTable);
