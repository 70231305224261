import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import './styles.scss';
import {OPERATE_STYLES} from '../../../styles/constants';
import {plantActions} from '../../../store/actions/plant';
import CloudViewerBox from '../../../components/operate/CloudViewerBox';
import {parseSkyImageInfo} from '../../../helpers/plant';


const EMPTY_IMAGE = {
  index: null,
  obj: null,
  date: null,
  time: null
};

class CloudViewer extends React.Component {

  state = {
    loading: false,
    currImage: EMPTY_IMAGE
  }

  constructor(props) {
    super(props);
    this.setImage = this.setImage.bind(this);
    this.setDate = this.setDate.bind(this);
  }

  componentDidMount() {
    this.setState({loading: true});
    this.props.dispatch(plantActions.getSkyImages(new Date()));
  }

  componentDidUpdate(prevProps) {
    const {images} = this.props;
    if (prevProps && prevProps.images !== images && images) {
      if (images.length > 0) this.prefetchImages(prevProps);
      else this.setState({loading: false, currImage: EMPTY_IMAGE});
    }
  }

  setDate(date) {
    this.setState({loading: true});
    this.props.dispatch(plantActions.getSkyImages(date));
  }

  prefetchImages(prevProps) {
    const {images, cloudImages: {currentDate: date}} = this.props;
    const prevImages = prevProps.images;
    const prevCurrentDate = prevProps.cloudImages.currentDate;
    const {currImage: {index}} = this.state;
    images.forEach(image => {
      const img = new Image();
      img.src = image.url;
    });
    if (!index || prevImages.length - 1 === index || prevCurrentDate !== date) this.setImage(images.length - 1);
    this.setState({loading: false});
  }

  setImage(index) {
    const {images} = this.props;
    const obj = images[index];
    const {date, time} = parseSkyImageInfo(obj);
    this.setState({currImage: {index, obj, date, time}});
  }

  render() {
    const {images, cloudImages} = this.props;
    const {currImage, loading} = this.state;
    return (
      <div className="cloud-viewer" style={{paddingLeft: OPERATE_STYLES.MENU_WIDTH}}>
        {currImage?.obj && <img src={currImage.obj.url} alt={currImage.obj.name}/>}
        <CloudViewerBox
          loading={loading}
          date={cloudImages.currentDate}
          images={images}
          current={currImage}
          setDate={this.setDate}
          setIndex={this.setImage} />
      </div>
    );
  }
}

CloudViewer.propTypes = {
  dispatch: PropTypes.func,
  cloudImages: PropTypes.object,
  images: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    cloudImages: state.plant.cloudImages,
    images: state.plant.cloudImages?.images
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CloudViewer));
