import axios from 'axios';
import {fileUrls} from '../constants/files';

export const filesService = {
  getCsvImports,
  getCsvImport
};

function getCsvImports(dir) {
  return axios.request({
    method: 'GET',
    url: fileUrls.GET_CSV_IMPORTS,
    params: {dir}
  });
}

function getCsvImport(fileName) {
  return axios.request({
    method: 'GET',
    url: fileUrls.GET_CSV_IMPORT.replace('<fileName>', fileName.replaceAll('/', '%2F'))
  });
}

