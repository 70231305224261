import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../styles.scss';
import {formatLastSeen} from '../../../../helpers/utils';
import {connect} from 'react-redux';
import {plantTesActions} from '../../../../store/actions/plantTes';
import {getTemperatureRow} from '../utils';
import {deviceStatus, deviceTypes} from '../../../../store/constants/plantTes';
import {isDeviceSelected} from '../../../../helpers/plant';
import Switch from '../../Switch';
import {plantTesService} from '../../../../store/services/plantTes';
import {getIsMaintenanceMode} from '../../../../store/selectors/plant';

// TECHDEBT: move styles to component
// TECHDEBT: create more generic component for tables
class ValvesTable extends React.Component {
  state = {
    valveStates: {}
  }

  onSwitchChange(valveId, checked) {
    const verboseState = checked ? 'open':'close';
    const state = checked ? 1:0;
    const confirmed = window.confirm(`Are you sure you want to set valve state to ${verboseState}?`);
    if (confirmed) {
      plantTesService.setValveState(valveId, state).then(() => {
        this.setState({valveStates: this.getNewValvesState(valveId, {state})});
      }).catch(error => {
        alert('Error while changing valve state: ' + (error.response?.data?.message || error));
      });
    }
  }

  getValveRow = (valve, index, isSelected) => {
    // cols: id, state, last seen, (action)
    const {isReadonly, isMaintenanceMode} = this.props;
    const {valveStates} = this.state;
    let switchState = valveStates[valve.object_id]?.state;
    if (switchState === undefined) switchState = valve.status;
    let rowClass, statusVerbose;
    if (valve.status === deviceStatus.INACTIVE) {
      rowClass = 'table__row--empty';
      statusVerbose = 'CLOSED';
    } else if (valve.status === deviceStatus.ACTIVE) {
      rowClass = 'table__row--success';
      statusVerbose = 'OPEN';
    } else {
      rowClass = 'table__row--error';
      statusVerbose = 'ERROR';
    }
  
    return (
      <tbody key={`valve-${index}`} >
        <tr className={clsx('table__row', {'table__row--selected': isSelected}, rowClass)}
          onClick={() => this.onValveClick(valve, isSelected)}>
          <td scope="row">{valve.object_id}</td>
          <td>{statusVerbose}</td>
          <td className={'table__cell'}>{formatLastSeen(valve.timestamp)}</td>
          {(!isReadonly && isMaintenanceMode) &&
            <td colSpan="6" className="table__cell">
              {Boolean(valve.status !== deviceStatus.ERROR && valve.status !== undefined) && (
                <>
                  <div className="table__cell__switch">
                    <Switch
                      handleDiameter={12}
                      height={20}
                      width={50}
                      onChange={(checked) => this.onSwitchChange(valve.object_id, checked)}
                      onText="Open"
                      offText="Close"
                      checked={Boolean(switchState)}
                    />
                  </div>
                </>
              )}
            </td>
          }
        </tr>
        {isSelected && valve.temperature_sensors ?
          valve.temperature_sensors.map((obj, idx) => getTemperatureRow(obj, idx, 5)):null}
      </tbody>
    );
  };

  getNewValvesState = (valveId, {state}) => {
    const {valveStates} = this.state;
    let newValveStates = {
      ...valveStates,
      [valveId]: {
        ...valveStates[valveId],
      }
    };
    if (state !== undefined) newValveStates[valveId].state = state;
    return newValveStates;
  }

  isSelected = (valve) => {
    const {selectedDevices} = this.props;
    return isDeviceSelected({selectedDevices, type: deviceTypes.VALVE, device: valve});
  };
  
  onValveClick(valve, isSelected) {
    const {dispatch} = this.props;
    if (isSelected) dispatch(plantTesActions.unselectDevice(deviceTypes.VALVE, valve));
    else dispatch(plantTesActions.selectDevice(deviceTypes.VALVE, valve));
  }
  
  render() {
    const {valves, isReadonly, isMaintenanceMode} = this.props;
    return (
      <div className={'table'}>
        <table>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">State</th>
              <th scope="col" className={'table__cell'}>Last seen</th>
              {(!isReadonly && isMaintenanceMode) && <th scope="col" className={'table__cell'}>Action</th>}
            </tr>
          </thead>
          {
            valves && valves.length > 0 ? (
              <>
                {valves.map((valve, index) =>
                  (this.getValveRow(valve, index, this.isSelected(valve))))}
              </>) :
              <tbody>
                <tr>
                  <td>No valves</td>
                </tr>
              </tbody>
          }
        </table>
      </div>
    );
  }
}


ValvesTable.propTypes = {
  valves: PropTypes.array,
  selectedDevices: PropTypes.array,
  isMaintenanceMode: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    selectedDevices: state.plant.selectedDevices,
    isMaintenanceMode: getIsMaintenanceMode(state),
    isReadonly: state.general.isReadonly
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValvesTable);
