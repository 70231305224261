import {heliostatConstants} from '../constants/heliostat';
import {heliostatService} from '../services/heliostat';

export const HeliostatActions = {
  getHeliostatList,
  setActiveHeliostat,
  getHeliostat,
  updateHeliostat
};

function getHeliostatList() {
  return (dispatch) => {
    heliostatService.getHeliostatList().then(response => {
      const heliostats = response.data;
      dispatch({
        type: heliostatConstants.GET_HELIOSTAT_LIST_SUCCEEDED, payload: heliostats
      });
    });
  };
}

function getHeliostat(id) {
  return (dispatch) => {
    heliostatService.getHeliostat(id).then(response => {
      const heliostat = response.data;
      dispatch({
        type: heliostatConstants.GET_HELIOSTAT_SUCCEEDED, payload: heliostat
      });
    });
  };
}

function setActiveHeliostat(id) {
  return { type: heliostatConstants.SET_ACTIVE_HELIOSTAT, activeHeliostat: id };
}

function updateHeliostat(heliostat) {
  return { type: heliostatConstants.UPDATE_HELIOSTAT, payload: heliostat };
}
