import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../Select';
import {paletteOptions, resolutionOptions, grainStateOptions} from '../../../../store/constants/plant';


export const IrCamera = ({pallete, resolution, grainState, isActive}) => (
  <React.Fragment>
    <div className={'block item clearfix pt-0'}>
      <div className={'item__label'}>
        Pseudo color palette
      </div>
      <div className={'mt-2'}>
        <Select
          value={pallete}
          options={paletteOptions}
          onChange={() => console.log('Changing pallete')}
          isDisabled={!isActive}
        />
      </div>
    </div>
    <div className={'block item clearfix pt-0'}>
      <div className={'item__label'}>
        Radiometry tlinear resolution
      </div>
      <div className={'mt-2'}>
        <Select
          value={resolution}
          options={resolutionOptions}
          onChange={() => console.log('Changing resolution')}
          isDisabled={!isActive}
        />
      </div>
    </div>
    <div className={'block item clearfix pt-0'}>
      <div className={'item__label'}>
        Grain state
      </div>
      <div className={'mt-2'}>
        <Select
          value={grainState}
          options={grainStateOptions}
          onChange={() => console.log('Changing grain state')}
          isDisabled={!isActive}
        />
      </div>
    </div>
  </React.Fragment>
);

IrCamera.propTypes = {
  pallete: PropTypes.number,
  resolution: PropTypes.number,
  grainState: PropTypes.number,
  isActive: PropTypes.bool
};
