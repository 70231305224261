import { combineReducers } from 'redux';
import {general} from './store/reducers/general';
import {menu} from './store/reducers/menu';
import {plant} from './store/reducers/plant';
import {heliostat} from './store/reducers/heliostat';
import {events} from './store/reducers/events';
import {socket} from './store/reducers/socket';
import {projects} from './store/reducers/projects';
import {users} from './store/reducers/users';

export const rootReducer = combineReducers({
  general,
  menu,
  plant,
  heliostat,
  events,
  socket,
  projects,
  users
});
