import {socketConstants} from '../constants/socket';

const initialState = {
  connected: false,
  channels: null,
  joined: null
};

export const socket = (state = initialState, action) => {
  switch (action.type) {
    case socketConstants.CONNECTED:
      console.log('Socket connected');
      return {
        ...state,
        connected: true,
        channels: null,
        joined: null
      };
    case socketConstants.JOIN:
      return {
        ...state,
        channels: action.payload,
        joined: false
      };
    case socketConstants.JOINED:
      return {
        ...state,
        joined: action.payload.success
      };
    case socketConstants.DISCONNECTED:
      return {
        ...state,
        connected: false,
        joined: false
      };
    default:
      return state;
  }
};
