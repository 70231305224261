import React, {Component} from 'react';
import './styles.scss';
import {closeIcon} from '../../../components/svg';
import InnerMenuItem from '../../../components/operate/InnerMenuItem';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {menuActions} from '../../../store/actions/menu';

export class InnerMenu extends Component {

    closeInnerMenu = () => {
      this.props.dispatch(menuActions.closeInnerMenu());
    };

    render() {
      const {heliostats} = this.props;

      return (
        <div className={'inner-menu'}>
          <div className="inner-menu__top">
            <div className="inner-menu__top-text">List of heliostats</div>
            <div className="inner-menu__top-icon" onClick={this.closeInnerMenu}>{closeIcon()}</div>
          </div>
          <div className="inner-menu__content">
            {heliostats && heliostats.map((heliostat, index) => {
              return <InnerMenuItem key={index} label={`Heliostat ${heliostat.name}`}
                isActive={heliostat.status}
                isRepair={heliostat.mode} id={heliostat.id} />;
            })}
          </div>
        </div>
      );
    }
}

InnerMenu.propTypes = {
  heliostats: PropTypes.array,
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    innerMenu: state.menu.innerMenu,
    heliostats: state.heliostat.heliostatsList
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnerMenu);
