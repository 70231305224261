import {Button, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import FromBody from '../../FormBody';

const SignInConfirmForm = ({onInputChange, onButtonPress, onBackPress, disabled,}) => {
  return (
    <Form className={'login-form'}>
      <p>
        Your account is protected by MFA. Please provide authorization code.<br/>
      </p>
      <FromBody
        onButtonPress={onButtonPress}
        fields={[
          {label: 'Code', type: 'text', name: 'mfaCode'},
        ]}
        onInputChange={onInputChange}
      />
      <Form.Group className={'login-form__footer'}>
        <Button onClick={onButtonPress} disabled={disabled}>Confirm</Button><br/><br/>
        <Button onClick={onBackPress} disabled={disabled} variant={'secondary'}>Back to login</Button>
      </Form.Group>
    </Form>
  );
};

SignInConfirmForm.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onButtonPress: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default SignInConfirmForm;
