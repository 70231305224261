import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Auth, Hub} from 'aws-amplify';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {logo} from '../../components/logo';
import routes from '../../routes';
import './styles.scss';
import SignInForm from '../../components/login_forms/SignIn';
import NewPasswordForm from '../../components/login_forms/NewPassword';
import {usersConstants} from '../../store/constants/users';
import {getUserPayload} from '../../helpers/store';
import PasswordResetForm from '../../components/login_forms/PasswordReset';
import PasswordResetConfirmForm from '../../components/login_forms/PasswordResetConfirm';
import PasswordResetSuccessForm from '../../components/login_forms/PasswordResetSuccess';
import mainImage from '../../images/kyotopia-site.png';
import MfaSetupForm from '../../components/login_forms/MfaSetup';
import SignInConfirmForm from '../../components/login_forms/SingInConfirm';
import config from '../../config';
import GoogleSignInForm from '../../components/login_forms/GoogleSignIn';

const AUTH_STATES = {
  signIn: 0,
  newPasswordRequired: 1,
  mfaRequired: 2,
  resetPassword: 3,
  resetPasswordConfirmation: 4,
  resetPasswordSuccess: 5,
  mfaSetup: 6
};

export class LoginPage extends Component {

  state = {
    loading: false,
    username: '',
    password: '',
    name: '',
    newPassword1: '',
    newPassword2: '',
    code: '',
    authState: AUTH_STATES.signIn,
    mfaSetupCode: '',
    mfaCode: '',
    mfaType: ''
  }

  user = null;

  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSignInSubmit = this.onSignInSubmit.bind(this);
    this.onResetButtonPress = this.onResetButtonPress.bind(this);
    this.onNewPasswordSubmit = this.onNewPasswordSubmit.bind(this);
    this.onPasswordResetSubmit = this.onPasswordResetSubmit.bind(this);
    this.onPasswordResetConfirmationSubmit = this.onPasswordResetConfirmationSubmit.bind(this);
    this.onMfaSetupSubmit = this.onMfaSetupSubmit.bind(this);
    this.onSignInConfirmSubmit = this.onSignInConfirmSubmit.bind(this);
    this.onGoogleSignInClick = this.onGoogleSignInClick.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
  }

  resetForms = () => {
    this.setState({
      username: '',
      password: '',
      name: '',
      newPassword1: '',
      newPassword2: '',
      signInError: false,
      signInErrorMessage: ''
    });
  }

  componentDidMount() {
    Hub.listen('auth', ({ payload: {event, data}}) => {
      if (event === 'signIn') {
        this.user = data;
        this.redirectAfterSignIn();
      } else if (event === 'signIn_failure') {
        this.setState({
          signInError: true,
          signInMessage: decodeURIComponent(data.message).replaceAll('+', ' ')
        });
      }
    });
  }

  onInputChange = (event) => {
    const { target } = event;
    this.setState({[target.name]: target.value});
  }

  async onSignInSubmit() {
    const {username, password} = this.state;
    if (!username || username === '' || !password || password === '') {
      alert('Please provide username and password');
      return;
    }
    this.setState({ loading: true });
    try {
      this.user = await Auth.signIn(username, password);
      this.setState({ loading: false });

      if (this.user.challengeName === 'SMS_MFA' ||
          this.user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        this.resetForms();
        this.setState({authState: AUTH_STATES.mfaRequired, mfaType: this.user.challengeName});
      } else if (this.user.challengeName === 'MFA_SETUP') {
        Auth.setupTOTP(this.user).then((code) =>
          this.setState({mfaSetupCode: code, authState: AUTH_STATES.mfaSetup}))
          .catch(() => alert('Cannot setup MFA'));
      } else if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.resetForms();
        this.setState({authState: AUTH_STATES.newPasswordRequired});
      } else {
        this.redirectAfterSignIn();
      }
    } catch (e) {
      // TODO: think about preventing user enumartion
      alert('Cannot login: ' + e.message);
      this.setState({ loading: false });
    }
  }

  onResetButtonPress() { this.setState({authState: AUTH_STATES.resetPassword}); }

  async onNewPasswordSubmit() {
    const {newPassword1, newPassword2, name} = this.state;
    if (!newPassword1 || newPassword1 === '' || !newPassword2 || newPassword2 === '' ||
      newPassword1 !== newPassword2) {
      alert('Please provide valid passwords. Password cannot be empty. Passwords must be identical.');
      return;
    }
    this.setState({ loading: true });
    try {
      this.user = await Auth.completeNewPassword(this.user, newPassword1, {name});
      this.setState({ loading: false });
      if (this.user.challengeName === 'SMS_MFA' ||
        this.user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        this.setState({authState: AUTH_STATES.mfaRequired});
      } else if (this.user.challengeName === 'MFA_SETUP') {
        Auth.setupTOTP(this.user).then((code) =>
          this.setState({mfaSetupCode: code, authState: AUTH_STATES.mfaSetup}))
          .catch(() => alert('Cannot setup MFA'));
      } else {
        this.redirectAfterSignIn();
      }
    } catch (e) {
      alert('Cannot set new password: ' + e.message);
      this.setState({ loading: false });
    }
  }

  async onPasswordResetSubmit() {
    const {username} = this.state;
    if (!username || username === '') {
      alert('Please provide valid username.');
      return;
    }
    this.setState({ loading: true });
    try {
      await Auth.forgotPassword(username);
      this.setState({
        authState: AUTH_STATES.resetPasswordConfirmation,
        loading: false
      });
    } catch (error) {
      alert(error.message);
      this.setState({ loading: false });
    }
  }

  async onPasswordResetConfirmationSubmit() {
    const { username, code, newPassword1, newPassword2 } = this.state;
    if (!newPassword1 || newPassword1 === '' || !newPassword2 || newPassword2 === '' ||
      newPassword1 !== newPassword2) {
      alert('Please provide valid passwords. Password cannot be empty. Passwords must be identical.');
      return;
    }
    this.setState({ loading: true });

    try {
      await Auth.forgotPasswordSubmit(
        username,
        code,
        newPassword1
      );
      this.setState({
        loading: false,
        authState: AUTH_STATES.resetPasswordSuccess,
      });
      this.resetForms();
    } catch (error) {
      this.setState({ loading: false });
      alert('Error while setting new password: ' + error.message);
      this.setState({ loading: false });
    }
  }

  async onMfaSetupSubmit() {
    const {mfaCode} = this.state;
    this.setState({ loading: true });
    Auth.verifyTotpToken(this.user, mfaCode).then(() => {
      Auth.setPreferredMFA(this.user, 'TOTP');
      this.setState({ loading: false, mfaCode: '', authState: AUTH_STATES.signIn });
    }).catch( e => {
      this.setState({ loading: false });
      alert('Cannot verify mfa token: ' + e.message);
    });
  }

  async onSignInConfirmSubmit() {
    const {mfaCode, mfaType} = this.state;
    this.setState({ loading: true });
    Auth.confirmSignIn(this.user, mfaCode, mfaType).then(() => {
      this.setState({ loading: false, mfaCode: ''});
      this.redirectAfterSignIn();
    }).catch( e => {
      this.setState({ loading: false });
      alert('Cannot verify mfa token: ' + e.message);
    });
  }

  onGoogleSignInClick() {
    Auth.federatedSignIn({provider: 'Google'});
  }

  backToLogin() { this.setState({authState: AUTH_STATES.signIn}); }

  redirectAfterSignIn = () => {
    const {dispatch, history} = this.props;
    dispatch({type: usersConstants.SET_USER, payload: getUserPayload(this.user)});
    history.replace(routes.main);
  }

  render() {
    const {authState, loading, username, mfaSetupCode, signInError, signInMessage} = this.state;
    let form;
    if (config.LOGIN_WITH_PASSWORD === true) {
      if (authState === AUTH_STATES.signIn) {
        form =
          <SignInForm
            onButtonPress={this.onSignInSubmit}
            onResetPress={this.onResetButtonPress}
            onInputChange={this.onInputChange}
            disabled={loading}
            defaultUsername={username}
          />;
      } else if (authState === AUTH_STATES.newPasswordRequired) {
        form =
          <NewPasswordForm
            onButtonPress={this.onNewPasswordSubmit}
            onInputChange={this.onInputChange}
            onBackPress={this.backToLogin}
            disabled={loading}
          />;
      } else if (authState === AUTH_STATES.resetPassword) {
        form =
          <PasswordResetForm
            onButtonPress={this.onPasswordResetSubmit}
            onInputChange={this.onInputChange}
            onBackPress={this.backToLogin}
            disabled={loading}
            defaultUsername={username}
          />;
      } else if (authState === AUTH_STATES.resetPasswordConfirmation) {
        form =
          <PasswordResetConfirmForm
            onButtonPress={this.onPasswordResetConfirmationSubmit}
            onInputChange={this.onInputChange}
            onBackPress={this.backToLogin}
            disabled={loading}
            defaultUsername={username}
          />;
      } else if (authState === AUTH_STATES.resetPasswordSuccess) {
        form =
          <PasswordResetSuccessForm
            onButtonPress={this.backToLogin}
            disabled={loading}
          />;
      } else if (authState === AUTH_STATES.mfaSetup) {
        form =
          <MfaSetupForm
            onBackPress={this.backToLogin}
            onButtonPress={this.onMfaSetupSubmit}
            mfaData={{username: this.user.username, mfaSetupCode}}
            onInputChange={this.onInputChange}
          />;
      } else if (authState === AUTH_STATES.mfaRequired) {
        form =
          <SignInConfirmForm
            onBackPress={this.backToLogin}
            onButtonPress={this.onSignInConfirmSubmit}
            onInputChange={this.onInputChange}
          />;
      }
    }

    return (
      <div className={'login-page'}>
        <div className={'enter-menu login-page__left'}>
          <a href={routes.main} title="Go to Main page" className={'enter-menu__logo'}>
            {logo()}
            <div className="enter-menu__logo-subtitle">Login</div>
          </a>
          {form}
          <React.Fragment>
            <GoogleSignInForm onButtonPress={this.onGoogleSignInClick} />
            {signInError && <p className="error-text">
              Error occured during sign in: <br/> {signInMessage}<br/>
              If you are trying to sing in for the first time please contact administrator.
            </p>}
          </React.Fragment>
        </div>
        <div className={'login-page__right'}>
          <div className='page-content' id='content'>
            <div className='page-content__splash'>
              <img src={mainImage} alt='Kyotopia Site illustration' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.object,
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPage));
