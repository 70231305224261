import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {TOOL_NONE, TOOL_PAN, TOOL_ZOOM_IN, TOOL_ZOOM_OUT, TOOL_AUTO} from 'react-svg-pan-zoom';

import { PointerIcon, PanIcon, ZoomInIcon, ZoomOutIcon, FitIcon, AutoIcon } from './icons';
import './styles.scss';

const SchemaMenu = ({tool: activeTool, changeTool, fitToViewer}) => {

  const onSelect = (tool) => {
    changeTool(tool);
  };

  const tools = [
    [TOOL_AUTO, <AutoIcon key="tool-auto" />],
    [TOOL_NONE, <PointerIcon key="tool-pointer" />],
    [TOOL_PAN, <PanIcon key="tool-pan" />],
    [TOOL_ZOOM_IN, <ZoomInIcon key="tool-zoom-in" />],
    [TOOL_ZOOM_OUT, <ZoomOutIcon key="tool-zoom-out" />],
  ];

  return (
    <div className="schema-menu" role="toolbar">
      {tools.map((tool, index) => (
        <div key={'tool-' + index}
          className={clsx('schema-menu__icon', {'schema-menu__icon--active': tool[0] === activeTool})}
          onClick={() => onSelect(tool[0])}
        >
          {tool[1]}
        </div>
      ))}
      <div className="schema-menu__icon">
        <FitIcon onClick={fitToViewer}/>
      </div>
    </div>
  );
};

SchemaMenu.propTypes = {
  tool: PropTypes.string.isRequired,
  changeTool: PropTypes.func.isRequired,
  fitToViewer: PropTypes.func.isRequired
};

export default SchemaMenu;



