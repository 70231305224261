import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StageSubtitle from '../StageSubtitle';
import {logo, logoCollapsed} from '../logo';
import routes from '../../routes';
import {ProjectStageNavigation} from './ProjectStageNavigation';
import {ProjectStageSelected} from './ProjectStageSelected';
import {PaddedMenuContainer} from '../paddedMenuContainer';
import DesignNavigation from '../../pages/design/DesignNavigation';
import avatar from '../../images/avatar.png';
import {usersActions} from '../../store/actions/users';

export const Menu = props => {
  const {menuCollapsed, history, project, user} = props;
  const splashScreen = history.location.pathname === routes.main;
  if (!project || !project.settings) return <div />;

  return (
    <div
      className={`sidebar-wrapper ${splashScreen ? 'splash-screen' : ''} ${menuCollapsed ? 'vertical-nav__collapsed' : 'vertical-nav'}`}
      id="sidebar">
      {!menuCollapsed && (
        <PaddedMenuContainer>
          <a href={routes.main}>
            {logo()}
          </a>
          <Route path="/" component={StageSubtitle}/>
        </PaddedMenuContainer>
      )}
      {menuCollapsed && (
        <PaddedMenuContainer>
          <a href={routes.main} className={'mb-3 d-block'}>
            {logoCollapsed()}
          </a>
        </PaddedMenuContainer>
      )}

      <Switch>
        <Route exact path="/">
          <PaddedMenuContainer>
            <p className="menu-paragraph">Select stage:</p>
          </PaddedMenuContainer>
          <ProjectStageNavigation stages={[
            {
              href: routes.designMain,
              title: 'Design stage',
              description: 'Design or continue a project.',
              numberOfProjects: 7
            },
            {
              href: routes.buildMain,
              title: 'Build stage',
              description: 'Supervise the construction process.',
              numberOfProjects: 3
            },
            {
              href: routes.operatePlants,
              title: 'Operation stage',
              description: 'Operate an existing plan.',
              numberOfProjects: 2
            }
          ]}/>
        </Route>

        <Route path={routes.designMain} exact>
          <ProjectStageSelected title="Design stage"
            description="Distinctively expedite emerging manufactured products before
                                           endend core competencies. Phosfluo rescently reintermediate intuitive
                                            infomediaries through proactive information. "
            backLink={routes.main}/>
        </Route>
        <Route path={routes.buildMain} exact>
          <ProjectStageSelected title="Build stage" description="Long description of the build stage"
            backLink={routes.main}/>
        </Route>
        <Route path={routes.operatePlants} exact>
          <ProjectStageSelected title="Operate stage" description="Long description of the operate stage"
            backLink={routes.main}/>
        </Route>
        <Route path={routes.designMain}
          render={(props) =>
            <DesignNavigation {...props} />
          }
        />
      </Switch>

      <div className="mt-auto logout">
        <div className="media d-flex align-items-center">
          <img
            src={avatar}
            alt={user.name + ' avatar'}
            onClick={() => history.push({pathname: routes.profile})}
            width="40" className="rounded-circle shadow-sm cursor-pointer"/>
          {!menuCollapsed && (
            <div className="media-body ml-3">
              <p className="m-0 logout-name cursor-pointer" onClick={() => history.push({pathname: routes.profile})}>
                {user.name}
              </p>
              <a onClick={() => usersActions.logout(history)} href='' >
                <p className="logout-logout">Logout</p>
              </a>
            </div>
          )}
        </div>
      </div>

    </div>
  );
};

Menu.propTypes = {
  dispatch: PropTypes.func,
  project: PropTypes.object,
  menuCollapsed: PropTypes.bool,
  user: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    project: state.projects.project,
    menuCollapsed: state.menu.designMenuCollapse,
    user: state.users.user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Menu));
