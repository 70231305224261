import {eventsConstants} from '../constants/events';
import {eventsService} from '../services/events';

export const eventsActions = {
  getEvents,
  updateEvents,
  removeEvent,
  clearEvents,
  setShowOnlyCritical,
  setEventSystem
};

function getEvents() {
  return (dispatch) => {
    dispatch({type: eventsConstants.GETTING_EVENTS});
    eventsService.getEvents().then(response => {
      const events = response.data.events || [];
      const criticalEvents = response.data.critical || [];
      const eventsInfo = {
        events,
        critical_events: criticalEvents
      };
      dispatch({
        type: eventsConstants.GET_EVENTS, payload: eventsInfo
      });
    }).catch((error) => {
      dispatch({type: eventsConstants.GET_EVENTS_ERROR, payload: error});
    });
  };
}

function updateEvents(event) {
  return {type: eventsConstants.UPDATE_EVENTS, payload: event};
}

function removeEvent(eventId) {
  return {type: eventsConstants.REMOVE_EVENT, payload: eventId};
}

function clearEvents() {
  return {type: eventsConstants.CLEAR_EVENTS};
}

function setShowOnlyCritical(showOnlyCritical) {
  return {type: eventsConstants.SET_SHOW_ONLY_CRITICAL, payload: showOnlyCritical};
}

function setEventSystem(value) {
  return (dispatch) => {
    eventsService.setEventSystem(value).then(() => {
      dispatch({
        type: eventsConstants.SET_EVENT_SYSTEM, payload: value
      });
    }).catch((error) => {
      dispatch({type: eventsConstants.GET_EVENTS_ERROR, payload: error});
    });
  };
}
