import axios from 'axios';
import {projectUrls} from 'store/constants/projects';


export const projectService = {
  getList,
  save,
  get,
  convertToPlant,
  deleteProject,
  getModelDownloadUrl,
  getModels,
  getBomFile
};

function getList() {
  return axios.request({
    method: 'GET',
    url: projectUrls.GET_LIST
  });
}

function save(data) {
  return axios.request({
    method: 'POST',
    url: projectUrls.SAVE,
    data
  });
}

function get(projectId) {
  return axios.request({
    method: 'GET',
    url: projectUrls.GET.replace('<projectId>', projectId)
  });
}

function convertToPlant(projectId) {
  return axios.request({
    method: 'POST',
    url: projectUrls.CONVERT_TO_PLANT.replace('<projectId>', projectId)
  });
}

function deleteProject(projectId) {
  return axios.request({
    method: 'DELETE',
    url: projectUrls.DELETE.replace('<projectId>', projectId)
  });
}

function getModelDownloadUrl(modelName) {
  return axios.request({
    method: 'GET',
    url: projectUrls.GET_MODEL.replace('<modelName>', modelName)
  });
}

function getModels(projectId) {
  return axios.request({
    method: 'GET',
    url: projectUrls.GET_MODELS.replace('<projectId>', projectId)
  });
}

function getBomFile(projectId) {
  return axios.request({
    method: 'GET',
    responseType: 'blob',
    url: projectUrls.GET_BOM.replace('<projectId>', projectId)
  });
}
