import React from 'react';

export const stop = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
    strokeLinecap="round" strokeLinejoin="round">
    <rect x="1" y="2" width="19" height="19" rx="2" ry="2" />
  </svg>
);

export const play = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
    strokeLinecap="round" strokeLinejoin="round">
    <polygon points="5 3 19 12 5 21 5 3" />
  </svg>
);

export const prev = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path d="M1,34.11,38.35,63.44a2.67,2.67,0,0,0,4.31-2.11V50.08l17,13.36A2.68,2.68,0,0,0,64,61.33V2.67A2.69,2.69,0,0,0,62.5.27,2.54,2.54,0,0,0,61.33,0a2.7,2.7,0,0,0-1.65.56l-17,13.36V2.67A2.68,2.68,0,0,0,41.17.27,2.54,2.54,0,0,0,40,0a2.7,2.7,0,0,0-1.65.56L1,29.89a2.71,2.71,0,0,0,0,4.22ZM58.66,8.16V55.84l-16-12.56-5.33-4.19-9-7.09,9-7.09Zm-21.33,0v10l-15,11.78a2.69,2.69,0,0,0,0,4.22l15,11.78v10L7,32Z"/>
  </svg>
);

export const next = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path d="M63,29.9,25.65.56a2.68,2.68,0,0,0-4.32,2.11V13.92L4.32.56A2.72,2.72,0,0,0,1.49.27,2.71,2.71,0,0,0,0,2.67V61.34a2.72,2.72,0,0,0,1.49,2.4A2.74,2.74,0,0,0,2.67,64a2.66,2.66,0,0,0,1.65-.56l17-13.36V61.34a2.71,2.71,0,0,0,1.5,2.4A2.66,2.66,0,0,0,24,64a2.63,2.63,0,0,0,1.65-.56L63,34.11a2.7,2.7,0,0,0,0-4.21ZM5.33,55.84V8.16l16,12.56,5.34,4.19,9,7.09-9,7.1Zm21.34,0V45.9l15-11.79a2.68,2.68,0,0,0,0-4.21l-15-11.79V8.16L57,32Z"/>
  </svg>
);

export const showHide = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 4 24 24">
    <path d="M23.662,15.286l-6.9-6.999c-0.39-0.394-1.024-0.394-1.414,0c-0.391,0.394-0.391,1.034,0,1.428L21.544,16   l-6.196,6.285c-0.391,0.394-0.391,1.034,0,1.428c0.391,0.394,1.024,0.394,1.414,0l6.899-6.999   C24.038,16.335,24.039,15.666,23.662,15.286z" fill="#121313"/><path d="M16.662,15.286L9.763,8.287c-0.391-0.394-1.024-0.394-1.414,0c-0.391,0.394-0.391,1.034,0,1.428L14.544,16   l-6.196,6.285c-0.391,0.394-0.391,1.034,0,1.428c0.391,0.394,1.024,0.394,1.414,0l6.899-6.999   C17.038,16.335,17.039,15.666,16.662,15.286z" fill="#121313"/>
  </svg>
);
