import config from '../config';

export const overrideUrls = (apiUrl, socketUrl, apiKey) => {
  console.log('Changing urls to', apiUrl, socketUrl);
  config.overrideApiUrl = apiUrl;
  config.overrideSocketUrl = socketUrl;
  config.apiKey = apiKey;
};

export const removeUrlsOverride = () => {
  console.log('Changing urls to defaults');
  config.overrideApiUrl = null;
  config.overrideSocketUrl = null;
  config.apiKey = null;
};
