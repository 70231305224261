import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';

import './styles.scss';
import MenuHeaderTitle from '../../../components/operate/MenuHeaderTitle';
import BigButton from '../../../components/operate/buttons/BigButton';
import InfoItem from '../../../components/operate/InfoItem';
import {menuActions} from '../../../store/actions/menu';


export class MenuStateFour extends Component {

    onClick = () => {
      this.props.dispatch(menuActions.setMenuHeliostat());
    };

    render() {
      const {intervalCount} = this.props;
      return (
        <React.Fragment>
          <MenuHeaderTitle subtitle={'Selected Object:'} title={'Heliostat 1E5'} online/>
          <div className="items-wrapper">
            <InfoItem title={'Status'} subtitle={'Current mode'} data={'Maintenance'} yellow/>
            <InfoItem title={'Last update'} subtitle={'Last connection'} data={`${intervalCount} sec ago`}/>
          </div>

          <BigButton text={'resume production'} onClick={this.onClick}/>
        </React.Fragment>
      );
    }
}

MenuStateFour.propTypes = {
  dispatch: PropTypes.func,
  intervalCount: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    state
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuStateFour);
