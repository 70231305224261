import React from 'react';
import clsx from 'clsx';
import {deviceStatus} from '../../../store/constants/plantTes';

export const getTemperatureRow = (temperature, index, colSpan) => (
  <tr className={clsx('table___row', 'table__row--expanded', {'table__row--error':
      temperature.status === deviceStatus.ERROR})} key={`temperature-${index}`}>
    <td scope="row">{temperature.object_id}</td>
    <td className="table__cell" colSpan={colSpan}>
      {temperature.value !== undefined && temperature.status !== deviceStatus.ERROR ?
        `${temperature.value}°C`:'N/A'}
    </td>
  </tr>
);
