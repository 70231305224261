import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {menuActions} from '../../../store/actions/menu';
import logo from '../../../images/small-logo.png';
import MenuIcon from '../../../components/operate/MenuIcon';
import MenuBorderIcon from '../../../components/operate/MenuBorderItem';
import MenuInfoBase from './MenuInfoBase';
import MenuTesInfo from './MenuTesInfo';
import MenuHaltBase from './MenuHaltBase';
import MenuEventsBase from './MenuEventsBase';
import './styles.scss';
import {eventsIcon, infoIcon, cameraIcon, haltIcon,
  irCameraIcon, schemeIcon, cloudViewerIcon} from '../../../components/svg';
import {plantActions} from '../../../store/actions/plant';
import {eventsActions} from '../../../store/actions/events';
import {plantModules} from '../../../store/constants/plant';
import routes from '../../../routes';
import {plantTesActions} from '../../../store/actions/plantTes';
import {socketActions} from '../../../store/actions/socket';
import {socketChannels} from '../../../store/constants/socket';
import avatar from '../../../images/avatar.png';
import UserMenu from '../../../components/UserMenu';
import {hasModuleActive} from '../../../helpers/plant';
import HasModuleActive from '../../HasModuleActive';
import {OPERATE_STYLES} from '../../../styles/constants';

export class CollapseMenu extends Component {

  state = {
    showUserMenu: false,
  };

  componentDidMount() {
    const {plant} = this.props;
    if (plant) {
      this.load();
    }
  }

  componentDidUpdate(prevProps) {
    // TECHDEBT: after status unification this should be moved to proper containers
    const {plant, socket, dispatch} = this.props;
    const sharedChannels = [socketChannels.EVENTS, socketChannels.HEARTBEAT];
    if (prevProps && prevProps.plant.id !== plant.id) {
      this.load();
    }
    if (prevProps && prevProps.socket && prevProps.socket.connected === false && socket.connected === true) {
      let channels = [...sharedChannels];
      if (hasModuleActive({plant, module: plantModules.TES})) channels.push(socketChannels.TES_STATUS);
      if (hasModuleActive({plant, modules: [plantModules.HELIOSTATS, plantModules.WEATHER]})) {
        channels.push(socketChannels.STATUS);
      }
      if (hasModuleActive({plant, module: plantModules.HELIOSTATS})) channels.push(socketChannels.HELIOSTAT);
      dispatch(socketActions.join(channels));
    }
  }

  load() {
    const {plant, dispatch} = this.props;
    dispatch(eventsActions.getEvents());
    if (hasModuleActive({plant, module: plantModules.TES})) dispatch(plantTesActions.getStatus());
    if (hasModuleActive({plant, modules: [plantModules.HELIOSTATS, plantModules.WEATHER]})) {
      dispatch(plantActions.getStatus());
    }
    dispatch(socketActions.connect());
  }

  componentWillUnmount() {
    this.props.dispatch(socketActions.disconnect());
  }

  toggleInfoMenu = () => {
    this.props.dispatch(menuActions.toggleMenuInfo());
  };

  toggleTesInfoMenu = () => {
    this.props.dispatch(menuActions.toggleMenuTesInfo());
  };

  toggleEventsMenu = () => {
    this.props.dispatch(menuActions.toggleMenuEvents());
  };

  toggleHaltMenu = () => {
    this.props.dispatch(menuActions.toggleMenuHalt());
  };

  toggleMenuCloudViewer = () => {
    this.props.dispatch(menuActions.toggleMenuCloudViewer());
  };

  render() {
    const {
      menuInfo, menuTesInfo, menuHalt, menuEvents, infoIconActive, eventsIconActive, haltIconActive, menuHaltEmergency,
      plant, dispatch, events, initializeLiveVideo, tesInfoIconActive, streams, liveVideo, isReadonly, user, history,
      menuCloudViewer
    } = this.props;
    const {showUserMenu} = this.state;
    const emptyObject = events === undefined || events.length === 0;

    if (!plant) return <div/>;
    return (
      <div className="menu-collapse__wrapper">
        <div className={'menu-small'} style={{width: OPERATE_STYLES.MENU_WIDTH}}>
          <Link to={routes.operatePlants} className={'menu-small__logo'}>
            <img src={logo} alt=""/>
          </Link>

          {!menuHaltEmergency && (
            <React.Fragment>
              <div className="menu-small__top">
                <MenuIcon icon={eventsIcon()} isActive={eventsIconActive} label={'events'}
                  onClick={this.toggleEventsMenu} point={!emptyObject}/>
                <HasModuleActive modules={[plantModules.HELIOSTATS, plantModules.WEATHER]}>
                  <MenuIcon icon={infoIcon()} isActive={infoIconActive} label={'info'}
                    onClick={this.toggleInfoMenu} />
                </HasModuleActive>
                <HasModuleActive module={plantModules.TES}>
                  <MenuIcon icon={infoIcon()} isActive={tesInfoIconActive} label={'info'}
                    onClick={this.toggleTesInfoMenu} />
                </HasModuleActive>
                <HasModuleActive module={plantModules.SKY_CAM}>
                  <MenuIcon icon={cloudViewerIcon()} isActive={menuCloudViewer} label={'sky images'}
                    onClick={this.toggleMenuCloudViewer}/>
                </HasModuleActive>
              </div>
              <div className="menu-small__bottom">
                {liveVideo && streams && streams.length > 1 &&
                  <MenuBorderIcon icon={cameraIcon()} label={'next cam'}
                    onClick={() => dispatch(plantActions.nextStream())}
                  />
                }
                <HasModuleActive module={plantModules.TES}>
                  <MenuBorderIcon icon={!liveVideo ? irCameraIcon():schemeIcon()}
                    label={!liveVideo ? 'Camera':'Scheme'}
                    onClick={() => liveVideo ?
                      dispatch(menuActions.unsetLiveVideo()):dispatch(menuActions.setLiveVideo())}
                  />
                </HasModuleActive>
                {!isReadonly &&
                  <HasModuleActive modules={[plantModules.HELIOSTATS, plantModules.TES]}>
                    <MenuBorderIcon icon={haltIcon()} label={'stop'} isDanger isActive={haltIconActive}
                      onClick={this.toggleHaltMenu}/>
                  </HasModuleActive>
                }
                <MenuIcon
                  icon={<img src={avatar} width="40" className="rounded-circle" alt={user.name + ' profile image'}/>}
                  label={user.name}
                  onClick={() => this.setState({showUserMenu: !showUserMenu})}
                >
                  <UserMenu show={showUserMenu} history={history} name={user.name}/>
                </MenuIcon>
              </div>
            </React.Fragment>
          )}
        </div>
        <div
          className={menuInfo || menuTesInfo || menuHalt || menuEvents ? 'menu-big active' : 'menu-big'}
          style={{width: OPERATE_STYLES.MENU_DETAILS_WIDTH}}
        >
          {menuEvents && <MenuEventsBase initializeLiveVideo={initializeLiveVideo} />}
          {menuInfo &&
            <HasModuleActive modules={[plantModules.HELIOSTATS, plantModules.WEATHER]}>
              <MenuInfoBase />
            </HasModuleActive>
          }
          {menuHalt && <MenuHaltBase/>}
          {menuTesInfo &&
            <HasModuleActive module={plantModules.TES}>
              <MenuTesInfo />
            </HasModuleActive>
          }
        </div>
      </div>
    );
  }
}

CollapseMenu.propTypes = {
  menuInfo: PropTypes.bool,
  menuTesInfo: PropTypes.bool,
  menuHalt: PropTypes.bool,
  menuEvents: PropTypes.bool,
  menuCloudViewer: PropTypes.bool,
  infoIconActive: PropTypes.bool,
  tesInfoIconActive: PropTypes.bool,
  eventsIconActive: PropTypes.bool,
  haltIconActive: PropTypes.bool,
  menuHaltEmergency: PropTypes.bool,
  liveVideo: PropTypes.bool,
  plant: PropTypes.object,
  dispatch: PropTypes.func,
  events: PropTypes.array,
  initializeLiveVideo: PropTypes.func,
  match: PropTypes.object,
  socket: PropTypes.object,
  streams: PropTypes.array,
  isReadonly: PropTypes.bool,
  user: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    menuInfo: state.menu.menuInfo,
    menuTesInfo: state.menu.menuTesInfo,
    menuHalt: state.menu.menuHalt,
    menuEvents: state.menu.menuEvents,
    menuCloudViewer: state.menu.menuCloudViewer,
    infoIconActive: state.menu.infoIconActive,
    tesInfoIconActive: state.menu.tesInfoIconActive,
    haltIconActive: state.menu.haltIconActive,
    eventsIconActive: state.menu.eventsIconActive,
    menuHaltEmergency: state.menu.menuHaltEmergency,
    liveVideo: state.menu.liveVideo,
    events: state.events.events,
    socket: state.socket,
    streams: state.plant.streams,
    isReadonly: state.general.isReadonly,
    user: state.users.user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CollapseMenu));
