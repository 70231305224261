import React, {Component} from 'react';
import {generatePath} from 'react-router';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import './styles.scss';
import routes from '../../../routes';


export class TileItem extends Component {

  linkHandler() {
    const {history, beforeLinkTo, item} = this.props;
    let shouldRedirect = true;
    if (beforeLinkTo) shouldRedirect = beforeLinkTo(item);
    if (shouldRedirect) history.push(generatePath(routes.operatePlant, {plantId: item.id}));
  }

  render() {
    const {item} = this.props;
    console.log(item.imageUrl);
    return (
      <div onClick={this.linkHandler.bind(this)} className={'tile-item'}>
        {item.imageUrl &&
          <div className={'image'} style={{backgroundImage: `url(${item.imageUrl})`}} />
        }
        <div className="title">{item.title}</div>
        <div className="subtitle">{item.subtitle}</div>
        <div className="footer">{item.footer}</div>
      </div>
    );
  }
}

TileItem.propTypes = {
  history: PropTypes.object,
  beforeLinkTo: PropTypes.func,
  item: PropTypes.object
};

export default withRouter(TileItem);
