import {createSelector} from 'reselect';
import {sortDeviceById} from '../../helpers/utils';

const getRawGlobal = (state) => state.plant.tes.status.global;
export const getGlobal = createSelector(
  getRawGlobal,
  (global) => (global ? {
    ...global,
    soc_percent: global ? (global.available_energy / global.max_energy * 100):null
  }:null)
);

const getRawHeaters = (state) => state.plant.tes.status.heater;
export const getHeaters = createSelector(getRawHeaters, (heaters) => heaters && heaters.sort(sortDeviceById));

const getRawHexes = (state) => state.plant.tes.status.hex;
export const getHexes = createSelector(getRawHexes, (hexes) => hexes && hexes.sort(sortDeviceById));

const getRawPipes = (state) => state.plant.tes.status.pipe;
export const getPipes = createSelector(getRawPipes, (pipes) => pipes && pipes.sort(sortDeviceById));

const getRawPumps = (state) => state.plant.tes.status.pump;
export const getPumps = createSelector(
  getRawPumps,
  (pumps) => pumps && pumps.map(pump => ({
    ...pump,
    frequency_percent: pump.frequency / pump.max_frequency * 100
  }))
);

const getRawTanks = (state) => state.plant.tes.status.tank;
export const getTanks = createSelector(
  getRawTanks,
  (tanks) => tanks && tanks.sort(sortDeviceById).map(tank => ({
    ...tank,
    fill_volume_percent: tank.fill_volume / tank.max_fill_volume * 100
  }))
);

const getRawValves = (state) => state.plant.tes.status.valve;
export const getValves = createSelector(getRawValves, (valves) => valves && valves.sort(sortDeviceById));

const getRawTesStatus = (state) => state.plant.tes.status;
export const getTesStatus = createSelector(
  getRawTesStatus, getGlobal, getHeaters, getHexes, getPipes, getTanks, getValves,
  (status, global, heaters, hexes, pipes, tanks, valves) => ({
    ...status,
    global,
    heater: heaters,
    hex: hexes,
    pipe: pipes,
    tank: tanks,
    valve: valves
  })
);

export const getIsMaintenanceMode = createSelector(
  getGlobal,
  (global) => global.is_maintenance
);
