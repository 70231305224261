import {Button, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

const GoogleSignInForm = ({onButtonPress, disabled}) => (
  <Form className={'login-form'}>
    <p>You can sign in to Kyotopia system using google account.</p>
    <Form.Group className={'login-form__footer'}>
      <Button onClick={onButtonPress} disabled={disabled}>Login with Google</Button><br/><br/>
    </Form.Group>
  </Form>
);

GoogleSignInForm.propTypes = {
  onButtonPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default GoogleSignInForm;
