import { generateId } from 'modules/id';

export const projectsConstants = {
  SET_PROJECT_ID: 'SET_PROJECT_ID',
  NEW_PROJECT: 'NEW_PROJECT',
  LOADING_PROJECT: 'LOADING_PROJECT',
  LOAD_PROJECT_SUCCESS: 'LOAD_PROJECT_SUCCESS',
  LOAD_PROJECT_ERROR: 'LOAD_PROJECT_ERROR',
  UPDATE_PROJECT: 'UPDATE_PROJECT'
};

export const projectUrls = {
  GET_LIST: '/projects',
  SAVE: '/projects',
  GET: '/projects/<projectId>',
  CONVERT_TO_PLANT: '/projects/<projectId>/convert-to-plant',
  DELETE: '/projects/<projectId>',
  GET_MODEL: '/3dmodels/download?model_name=<modelName>',
  GET_MODELS: '/3dmodels?projectId=<projectId>',
  GET_BOM: '/projects/<projectId>/bom'
};

export const initialProject = {
  id: generateId(),
  settings: {},
  site: {},
  checklist: {},
  financial: {
    irrInPercent: 25,
    interestInPercent: 8
  },
  projectEnergyProperty: {
    storageSize: 0,
    year: 2020,
    price: 60000,
    chargeCost: 0,
    efficiency: 95,
    availability: 99.7,
    dualCycle: 0,
    totalClientLoad: 0,
    totalRenewable: 0,
    totalExcessEnergy: 0,
    totalStorageFeed: 0,
    totalGridDemand: 0,
    hours: [...Array(24).keys()].map(i => ({
      hour: i,
      clientLoad: 0,
      renewable: 0,
      excessEnergy: 0,
      storageFeed: 0,
      gridDemand: 0
    }))
  }
};
