import React from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './styles.scss';

export default class CustomSwitch extends React.Component {

  render() {
    // TODO::should pass only react-switch parameters
    const {checked, onChange, onText, offText, ...rest} = this.props;

    return (
      <Switch
        className={clsx('switch', {'switch--on': checked, 'switch--off': !checked})}
        checked={checked}
        onChange={onChange}
        checkedIcon={<div className="react-switch-on">{onText || 'On'}</div>}
        uncheckedIcon={<div className="react-switch-off">{offText || 'Off'}</div>}
        onHandleColor="#979797"
        offHandleColor="#979797"
        onColor="#242427"
        offColor="#242427"
        {...rest}
      />
    );
  }
}

CustomSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onText: PropTypes.string,
  offText: PropTypes.string
};
