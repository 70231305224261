import {Auth} from 'aws-amplify';
import routes from '../../routes';
import {removeUrlsOverride} from '../../helpers/dynamicConfig';

export const usersActions = {
  logout
};

function logout(history) {
  Auth.signOut();
  removeUrlsOverride();
  history.push({pathname: routes.login});
}
