import {socketConstants} from '../constants/socket';

export const socketActions = {
  connect,
  join,
  disconnect
};

function connect() {
  return {type: socketConstants.CONNECT};
}

function join(channels) {
  return {type: socketConstants.JOIN, payload: channels};
}

function disconnect() {
  return {type: socketConstants.DISCONNECT};
}
