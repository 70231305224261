import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';

import './styles.scss';
import CustomSpinner from '../CustomSpinner';
import {play, stop, next, prev, showHide} from './svg';
import {Button} from 'react-bootstrap';
import {parseSkyImageInfo} from '../../../helpers/plant';

export default class CloudViewerBox extends React.Component {
  state = {
    playing: false,
    hide: true
  }

  timer = null;

  constructor(props) {
    super(props);
    this.prevImage = this.prevImage.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.playNextImage = this.playNextImage.bind(this);
    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
    this.toggleHide = this.toggleHide.bind(this);
  }

  componentWillUnmount() {
    this.setState({playing: false});
  }

  prevImage() {
    const {images, current: {index}, setIndex} = this.props;
    let newIndex;
    if (index === 0) newIndex = images.length - 1;
    else newIndex = (index - 1) % images.length;
    setIndex(newIndex);
  }

  nextImage() {
    const {images, current: {index}, setIndex} = this.props;
    setIndex((index + 1) % images.length);
  }

  play() {
    this.setState({playing: true});
    this.playNextImage();
    this.timer = setInterval(this.playNextImage, 100);
  }

  playNextImage() {
    const {current: {index}, images} = this.props;
    this.nextImage();
    if (index + 1 === images.length - 1) this.stop();
  }

  stop() {
    this.setState({playing: false});
    clearInterval(this.timer);
    this.timer = null;
  }

  toggleHide() {
    const {hide} = this.state;
    this.setState({hide: !hide});
  }

  render() {
    const {date, images, setDate, current: {date: imgDate, time: imgTime, index}, loading} = this.props;
    const {playing, hide} = this.state;
    const imagesLength = images ? images.length:null;
    let lastImg = null;
    if (imagesLength > 0) lastImg = parseSkyImageInfo(images[imagesLength - 1]);

    if (loading) {
      return (
        <div className="cloud-viewer-box cloud-viewer-box--loader">
          <CustomSpinner color="white" />
          <span>Loading images...</span>
        </div>
      );
    }

    return (
      <div className={clsx('cloud-viewer-box', {'cloud-viewer-box--hidden': hide})}>
        <div className="cloud-viewer-box__item">
          Selected date:
          <DatePicker
            selected={date}
            maxDate={new Date()}
            dateFormat="yyyy-MM-dd"
            popperPlacement="bottom-end"
            onChange={setDate} />
        </div>
        <div className="cloud-viewer-box__item">
          Images: <b>{imagesLength !== null ? imagesLength:'N/A'}</b><br/>
          {images &&
            <>
              {lastImg && <>Last image for date: <br/><b>{lastImg.date} {lastImg.time}</b><br/></>}
              {imgDate && <>Image date: <br/><b>{imgDate} {imgTime}</b><br/></>}
            </>
          }
        </div>
        <div className="cloud-viewer-box__buttons">
          <Button variant="light" className="cloud-viewer-box__buttons__button" onClick={this.toggleHide}>
            <div className={clsx(hide && 'r180')}>{showHide()}</div>
          </Button>
          {imagesLength ?
            <React.Fragment>
              <Button variant="light" className="cloud-viewer-box__buttons__button"
                disabled={index === 0} onClick={this.prevImage}>{prev()}</Button>
              <Button variant="light" className="cloud-viewer-box__buttons__button"
                disabled={playing} onClick={this.play}>{play()}</Button>
              <Button variant="light" className="cloud-viewer-box__buttons__button"
                disabled={!playing} onClick={this.stop}>{stop()}</Button>
              <Button variant="light" className="cloud-viewer-box__buttons__button"
                disabled={index === imagesLength - 1} onClick={this.nextImage}>{next()}</Button>
            </React.Fragment> : null
          }
        </div>
      </div>
    );
  }
}

CloudViewerBox.propTypes = {
  loading: PropTypes.bool.isRequired,
  date: PropTypes.object.isRequired,
  setIndex: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  current: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired
};

