import {Button, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import FromBody from '../../FormBody';

const NewPasswordForm = ({onInputChange, onButtonPress, onBackPress, disabled}) => (
  <Form className={'login-form'}>
    <p>
      New password is required. Please provide and repeat secure password.<br/>
      You also need to provide required attributes.
    </p>
    <FromBody
      onButtonPress={onButtonPress}
      fields={[
        {label: 'Password', type: 'password', name: 'newPassword1'},
        {label: 'Repeat password', type: 'password', name: 'newPassword2'},
        {label: 'Name', type: 'text', name: 'name'}
      ]}
      onInputChange={onInputChange}
    />
    <Form.Group className={'login-form__footer'}>
      <Button onClick={onButtonPress} disabled={disabled}>Confirm</Button><br/><br/>
      <Button onClick={onBackPress} disabled={disabled} variant={'secondary'}>Back to login</Button>
    </Form.Group>
  </Form>
);

NewPasswordForm.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onButtonPress: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default NewPasswordForm;
