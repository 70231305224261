import React from 'react';
import PropTypes from 'prop-types';

const PipeMenu = ({pipe}) => {

  console.log(pipe);

  return (
    <div className="schema-context-menu__item">
      <div>
        Is in use: {pipe.is_in_use ? 'Yes':'No'}
      </div>
    </div>
  );
};

PipeMenu.propTypes = {
  pipe: PropTypes.object.isRequired
};

export default PipeMenu;
