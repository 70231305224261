import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../styles.scss';
import {formatLastSeen} from '../../../../helpers/utils';
import {connect} from 'react-redux';
import {plantTesActions} from '../../../../store/actions/plantTes';
import {getTemperatureRow} from '../utils';
import {deviceStatus, deviceStatusMap, deviceTypes} from '../../../../store/constants/plantTes';
import {isDeviceSelected} from '../../../../helpers/plant';
import {plantTesService} from '../../../../store/services/plantTes';
import Range from '../../Range';
import {getIsMaintenanceMode} from '../../../../store/selectors/plant';


const MAX_TEMPERATURE = 400;

// TECHDEBT: move styles to component
// TECHDEBT: create more generic component for tables
class TanksTable extends React.Component {
  state = {
    tanksRange: {}
  }

  componentDidMount() {
    const {tanks} = this.props;
    if (tanks && tanks.length > 0) this.generateRangeInitials();
  }

  componentDidUpdate(prevProps) {
    const {tanks} = this.props;
    if (prevProps.tanks && tanks && prevProps.tanks !== tanks) this.generateRangeInitials();
  }

  generateRangeInitials() {
    const {tanks} = this.props;
    let tanksRange = {};
    tanks.forEach(tank => {
      tanksRange[tank.object_id] =
        {desiredTemperature: [tank.desired_temperature], rangeDesiredTemperature: [tank.desired_temperature]};
    });
    this.setState({tanksRange});
  }

  onRangeChange(tankId, values) {
    this.setState({ tanksRange: this.getNewTanksState(tankId, {rangeDesiredTemperature: values}) });
  }

  onRangeFinalChange(tankId, values) {
    const confirmed = window.confirm(`Are you sure you want to set tank ${tankId} temperature to ${values[0]}°C?`);
    const {tanksRange} = this.state;
    const prevDesiredTemperature = tanksRange[tankId].desiredTemperature;
    if (confirmed) {
      plantTesService.setTankDesiredTemperature(tankId, values[0]).then(() => {
        this.setState({ tanksRange: this.getNewTanksState(tankId, {desiredTemperature: values}) });
      }).catch(error => {
        alert('Error while changing tank temperature: ' + (error.response?.data?.message || error));
        this.setState({ tanksRange: this.getNewTanksState(tankId, {rangeDesiredTemperature: prevDesiredTemperature}) });
      });
    } else {
      this.setState({ tanksRange: this.getNewTanksState(tankId, {rangeDesiredTemperature: prevDesiredTemperature}) });
    }
  }

  getNewTanksState = (tankId, {desiredTemperature, rangeDesiredTemperature}) => {
    const {tanksRange} = this.state;
    let newTanksRange = {
      ...tanksRange,
      [tankId]: {
        ...tanksRange[tankId],
      }
    };
    if (desiredTemperature !== undefined) newTanksRange[tankId].desiredTemperature = desiredTemperature;
    if (rangeDesiredTemperature !== undefined) newTanksRange[tankId].rangeDesiredTemperature = rangeDesiredTemperature;
    return newTanksRange;
  };

  getTankRow = (tank, index, isSelected) => {
    const {tanksRange} = this.state;
    const {isReadonly, isMaintenanceMode} = this.props;
    const rangeDesiredTemperature = tanksRange[tank.object_id]?.rangeDesiredTemperature;
    if (!rangeDesiredTemperature) return;
    const valueError = MAX_TEMPERATURE < rangeDesiredTemperature[0];
    return (
      <tbody key={`tank-${index}`}>
        <tr onClick={() => this.onTankClick(tank, isSelected)}
          className={clsx('table__row', {'table__row--selected': isSelected,
            'table__row--error': tank.status === deviceStatus.ERROR})}
        >
          <td scope="row">{tank.object_id}</td>
          <td className="table__cell">{deviceStatusMap[tank.status]}</td>
          <td className="table__cell">
            {tank.average_temperature !== undefined ? `${tank.average_temperature}°C` : 'N/A'}
          </td>
          <td className="table__cell">
            {tank.fill_volume_percent !== undefined ? `${tank.fill_volume_percent.toFixed(2)}%` : 'N/A'}
          </td>
          <td className="table__cell">
            {formatLastSeen(tank.timestamp)}
          </td>
        </tr>
        {(!isReadonly && isMaintenanceMode) &&
        <tr>
          <td colSpan="5" className="table__cell">
            {valueError ?
              <div className="table__cell__range-error">
                Temperature ({rangeDesiredTemperature[0]}°C) cannot be bigger than expected
                maximum ({MAX_TEMPERATURE}°C).
              </div> :
              <div className="table__cell__range">
                <Range
                  values={rangeDesiredTemperature}
                  max={MAX_TEMPERATURE}
                  min={Math.min(rangeDesiredTemperature[0], 0)}
                  onChange={values => this.onRangeChange(tank.object_id, values)}
                  onFinalChange={values => this.onRangeFinalChange(tank.object_id, values)}
                  disabled={!tank || tank.status !== deviceStatus.ACTIVE}
                />
              </div>
            }
            <div className="table__cell__range-text">
              {rangeDesiredTemperature && rangeDesiredTemperature[0]}°C
            </div>
          </td>
        </tr>
        }
        {isSelected && tank.temperature_sensors ?
          tank.temperature_sensors.map((obj, idx) => getTemperatureRow(obj, idx, 4)) : null}
      </tbody>
    );
  };

  isSelected = (tank) => {
    const {selectedDevices} = this.props;
    return isDeviceSelected({selectedDevices, type: deviceTypes.TANK, device: tank});
  };

  onTankClick = (tank, isSelected) => {
    const {dispatch} = this.props;
    if (isSelected) dispatch(plantTesActions.unselectDevice(deviceTypes.TANK, tank));
    else dispatch(plantTesActions.selectDevice(deviceTypes.TANK, tank));
  }

  render() {
    const {tanks} = this.props;
    return (
      <div className={'table'}>
        <table>
          <thead>
            <tr>
              <th scope="col" className="table__cell">ID</th>
              <th scope="col" className="table__cell">Status</th>
              <th scope="col" className="table__cell">Temperature</th>
              <th scope="col" className="table__cell">Filling volume</th>
              <th scope="col" className="table__cell">Last seen</th>
            </tr>
          </thead>
          {
            tanks && tanks.length > 0 ? (
              <>
                {tanks.map((tank, index) =>
                  (this.getTankRow(tank, index, this.isSelected(tank))))}
              </>) :
              <tbody>
                <tr>
                  <td>No tanks</td>
                </tr>
              </tbody>
          }
        </table>
      </div>
    );
  }
}

TanksTable.propTypes = {
  tanks: PropTypes.array,
  selectedDevices: PropTypes.array,
  isMaintenanceMode: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    selectedDevices: state.plant.selectedDevices,
    isMaintenanceMode: getIsMaintenanceMode(state),
    isReadonly: state.general.isReadonly
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TanksTable);
