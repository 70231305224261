import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {WizardNavigation} from '../../components/Navigation/WizardNavigation';
import routes from '../../routes';
import {describeIcon, locationIcon, designIcon, reportIcon, energyIcon} from '../../components/icons';
import {PaddedMenuContainer} from '../../components/paddedMenuContainer';
import '../../styles/designStyles.scss';
import './DesignNavigation.scss';
import { projectsActions } from 'store/actions/projects';
import { connect } from 'react-redux';
import {projectService} from '../../store/services/project';

const isStepSelected = (href, location) => {
  if (href && (typeof href === 'string' || href instanceof String))
    return location.pathname === href;
  if (href && Array.isArray(href)) {
    return href.some(h => location.pathname === h);
  }
  return false;
};

export const DesignNavigation = ({location, menuCollapsed, padding, history, dispatch, project}) => {

  const [saving, setSaving] = useState(false);
  const [converting, setConverting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const isReadonly = project.settings.readonly;

  const onConvertToPlantClick = () => {
    if (window.confirm('This cannot be undone. ' +
        'After converting project to plant you won\'t be able to modify project. Are you sure?')) {
      setConverting(true);
      projectService.convertToPlant(project.id).then(() => {
        setConverting(false);
        history.push(routes.designMain);
      }).catch((error) => {
        setConverting(false);
        console.log('Error while converting project to plant', error);
        alert('Error while converting project to plant');
      });
    }
  };

  const deleteProject = () => {
    if (window.confirm('This cannot be undone. Are you sure?')) {
      setDeleting(true);
      projectService.deleteProject(project.id).then(() => {
        setDeleting(false);
        history.push(routes.designMain);
      }).catch((error) => {
        setDeleting(false);
        console.log('Error while deleting project', error);
        alert('Error while deleting project');
      });
    }
  };

  const saveProject = () => {
    setSaving(true);
    dispatch(projectsActions.saveProject()).then(() => setSaving(false));
  };

  return (
    <React.Fragment>
      <PaddedMenuContainer padding={padding}>
        {!menuCollapsed && (<p className="menu-paragraph">
          {isReadonly ? 'View project informations:':'Start a new project:'}</p>)
        }
        <WizardNavigation steps={[
          {
            href: routes.designLocation,
            mdi: locationIcon(),
            text: 'Pick a location',
            selected: isStepSelected([routes.designLocation, routes.designMain], location)
          },
          {
            href: routes.designDescribe,
            mdi: describeIcon(),
            text: 'Describe the project',
            selected: isStepSelected(routes.designDescribe, location)
          },
          {
            href: routes.designEnergy,
            mdi: energyIcon(),
            text: 'Energy usage',
            selected: isStepSelected(routes.designEnergy, location)
          },
          {
            href: routes.designSiteDesign,
            mdi: designIcon(),
            text: 'Design the site',
            selected: isStepSelected(routes.designSiteDesign, location)
          },
          {
            href: routes.designGenerateReport,
            mdi: reportIcon(),
            text: 'Generate a report',
            selected: isStepSelected(routes.designGenerateReport, location)
          }
        ]} menuCollapsed={menuCollapsed}/>
      </PaddedMenuContainer>
      {!menuCollapsed && <PaddedMenuContainer padding={padding} paddingTop={20}>
        {isReadonly !== true &&
          <Button block className="design-menu-button" variant={'light'} active={!saving} onClick={saveProject}>
            {saving ? 'Saving...':'Save project'}
          </Button>
        }
        <Button block className="design-menu-button design-menu-button__subtle" onClick={() => {
          dispatch(projectsActions.newProject());
          history.push(routes.designMain);
        }} variant="link">Cancel</Button>
        <br/>
        {isReadonly !== true &&
          <React.Fragment>
            <Button block className="design-menu-button design-menu-button__subtle" onClick={onConvertToPlantClick}
              variant="link">{converting ? 'Converting...':'Convert to plant'}</Button>
            <Button block className="design-menu-button design-menu-button__subtle btn-danger" onClick={deleteProject}
              variant="link">{deleting ? 'Deleting...':'Delete'}</Button>
          </React.Fragment>
        }
      </PaddedMenuContainer>}
    </React.Fragment>
  );
};

DesignNavigation.propTypes = {
  dispatch: PropTypes.func,
  menuCollapsed: PropTypes.bool,
  project: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  padding: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    menuCollapsed: state.menu.designMenuCollapse,
    project: state.projects.project
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DesignNavigation));
