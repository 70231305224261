const envMode = process.env.REACT_APP_ENVIRONMENT || 'dev';

const apiUrl = process.env.REACT_APP_API ||
  (envMode === 'dev' ? 'https://0xc0262qvg.execute-api.eu-west-1.amazonaws.com/mvp'
    : envMode === 'test' ? 'http://localhost:4010'
      : null);

const socketUrl = process.env.REACT_APP_SOCKET_URL ||
  (envMode === 'dev' ? 'wss://xs4l3n0lch.execute-api.eu-west-1.amazonaws.com/mvp'
    : null);

const unityUrl = process.env.REACT_APP_UNITY_URL;

const oauthCallbackUrls = process.env.REACT_APP_COGNITO_OAUTH_CALLBACK_URLS ?
  JSON.parse(process.env.REACT_APP_COGNITO_OAUTH_CALLBACK_URLS):['http://localhost:3000/login/'];
const oauthLogoutUrls = process.env.REACT_APP_COGNITO_OAUTH_LOGOUT_URLS ?
  JSON.parse(process.env.REACT_APP_COGNITO_OAUTH_LOGOUT_URLS):['http://localhost:3000/login/'];

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

let oauthCallbackUrl, oauthLogoutUrl;
// callbacks configuration for dev environment should include localhost url as first in array
oauthCallbackUrl = (oauthCallbackUrls.length === 1 || isLocalhost) ? oauthCallbackUrls[0]:oauthCallbackUrls[1];
oauthLogoutUrl = (oauthLogoutUrls.length === 1 || isLocalhost) ? oauthLogoutUrls[0]:oauthLogoutUrls[1];

const cognito = {
  REGION: 'eu-west-1',
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID ||
    (envMode === 'dev' ? 'eu-west-1_wAOFYHwBa'
      : 'null'),
  APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID ||
    (envMode === 'dev' ? '2ushf23shd32krd98bajkfv28m'
      : null),
  USER_POOL_DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
  OAUTH_CALLBACK_URL: oauthCallbackUrl,
  OAUTH_LOGOUT_URL: oauthLogoutUrl
};

export default {
  API_URL: apiUrl,
  SOCKET_URL: socketUrl,
  UNITY_URL: unityUrl,
  HEARTBEAT_TIMEOUT: envMode === 'dev' ? 500000:5, // heartbeat timeout in seconds
  IS_DEMO: process.env.REACT_APP_IS_DEMO || false,
  MOCK_DATA: false,
  GOOGLE_MAPS_API_KEY: 'AIzaSyC_JYjxpWvf2pPIcQufK5t-NX5KcOLrHQc',
  COGNITO: cognito,
  AUTH_ISSUER: `kyoto[${envMode}]`,
  LOGIN_WITH_PASSWORD: process.env.REACT_APP_LOGIN_WITH_PASSWORD || false,
  // dynamic config - can be changed in runtime
  overrideApiUrl: null,
  overrideSocketUrl: null,
  apiKey: null
};
