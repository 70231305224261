import React from 'react';
import PropTypes from 'prop-types';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';


const autocompleteStyles = {
  container: (provided) => ({
    ...provided,
    flex: 1,
    height: 56,
    fontSize: '16px',
    fontWeight: 400,
    color: '#221A16'
  }),
  control: (provided) => ({
    ...provided,
    height: 56,
    border: '1px solid #ced4da',
    borderLeftWidth: 0,
    borderRadius: '.25rem'
  })
};

class MapSearchBox extends React.Component {

  onChange = (data) => {
    const {onValueChange, updateCoordinates} = this.props;
    onValueChange(data.label);
    if (updateCoordinates) {
      geocodeByAddress(data.label)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          updateCoordinates({lat, lng});
        });
    }
  }

  render () {
    const {isReadonly, value} = this.props;

    return (
      <GooglePlacesAutocomplete
        selectProps={{
          value: {label: value, value: {}},
          onChange: this.onChange,
          styles: autocompleteStyles,
          isDisabled: isReadonly
        }}
      />
    );
  }
}

MapSearchBox.propTypes = {
  isReadonly: PropTypes.bool,
  updateCoordinates: PropTypes.func,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default MapSearchBox;
