import values from '../values';

export const calculationsHelper = {
  getLcosRequestPayload,
  getCostRequestPayload,
  getAnnualProductionRequestPayload,
  getLcoeRequestPayload,
  getPeakCapacityRequestPayload
};

function getLcosRequestPayload(projectSettings, projectEnergyProperty) {
  const waccCategory = values.lookupWacc((projectSettings || {}).waccCategory);
  const discountRate = waccCategory ? waccCategory.discountRateInPercent : 8;

  if (!projectEnergyProperty) return null;

  return {
    'price': projectEnergyProperty.price, // 60000 or 28000
    'size': projectEnergyProperty.storageSize, // default 0 MWh
    'charge_cost': projectEnergyProperty.chargeCost, // default 0 USD/MWh
    'round_trip_efficiency': projectEnergyProperty.efficiency, // default 95 %
    'availability': projectEnergyProperty.availability, // deafult 99.7 %
    'discount_rate': discountRate, // 8 or 12 or 16
    'dual_cycle': projectEnergyProperty.dualCycle // default 0 %
  };
}

function getCostRequestPayload(projectSite) {
  return  {
    'year': 2020,
    'numberOfTowers': projectSite.towers,
    'numberOfHeliostats': projectSite.heliostats,
    'numberOfStorageUnits': 0,
    'numberOfTurbines': 0
  };
}

function getAnnualProductionRequestPayload(projectSettings, projectSite) {
  return {
    'dni': projectSettings.dni,
    'numberOfHeliostats': projectSite.heliostats
  };
}

function getLcoeRequestPayload(projectSettings, projectSite, financial) {
  const {waccCategory = null} = projectSettings;
  const {annualProduction = null} = projectSite;
  const {totalCost = null} = financial;
  const wacc = values.lookupWacc(waccCategory);
  if (wacc && annualProduction) {
    return {
      'lifetimeInYears': 25,
      'initialInvestment': totalCost,
      'operatingCost': totalCost * 0.03,
      'discountPercentage': wacc.discountRateInPercent,
      'annualOutput': annualProduction
    };
  } else {
    return null;
  }
}

function getPeakCapacityRequestPayload(projectSite) {
  return {
    'numberOfHeliostats': projectSite.heliostats
  };
}
