import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';


export class MenuInfoItem extends Component {

  render() {
    const {title, subtitle, data, subData, onClick, danger, yellow} = this.props;
    return (
      <div
        className={danger ? 'info-item info-item--danger' : yellow ? 'info-item info-item--yellow' : 'info-item'}
        onClick={onClick}>
        <div>
          <div className="info-item__title">{title}</div>
          <div className="info-item__subtitle">{subtitle}</div>
        </div>
        <div>
          <div className="info-item__data">{data}</div>
          {subData && <div className="info-item__subdata">{subData}</div>}
        </div>
      </div>
    );
  }
}

MenuInfoItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.string,
  subData: PropTypes.string,
  onClick: PropTypes.func,
  danger: PropTypes.bool,
  yellow: PropTypes.bool
};

export default MenuInfoItem;
