export const eventsConstants = {
  GETTING_EVENTS: 'GETTING_EVENTS',
  GET_EVENTS: 'GET_EVENTS',
  GET_EVENTS_ERROR: 'GET_EVENTS_ERROR',
  UPDATE_EVENTS: 'UPDATE_EVENTS',
  REMOVE_EVENT: 'REMOVE_EVENT',
  CLEAR_EVENTS: 'CLEAR_EVENTS',
  SET_SHOW_ONLY_CRITICAL: 'SET_SHOW_ONLY_CRITICAL_EVENT',
  SET_EVENT_SYSTEM: 'SET_EVENT_SYSTEM'
};


export const eventsUrls = {
  GET_EVENTS: '/plant/<plantId>/events',
  RESOLVE: '/plant/<plantId>/events/<id>/resolve',
  DISMISS_ALL: '/plant/<plantId>/events/dismiss-all',
  GET_EVENT_STREAM: '/plant/<plantId>/events/<id>/get-stream-url',
  SET_EVENT_SYSTEM: '/plant/<plantId>/events/toggle'
};

export const eventLevel = {
  INFO: 0,
  WARNING: 1,
  ERROR: 2,
  CRITICAL: 3
};

export const eventLevelConfig = {
  [eventLevel.INFO]: {color: '#00d0ff', verbose: 'info'},
  [eventLevel.WARNING]: {color: '#ffff00', verbose: 'warning'},
  [eventLevel.ERROR]: {color: '#ff0000', verbose: 'error'},
  [eventLevel.CRITICAL]: {color: '#b00000', verbose: 'critical'},
};
