import React from 'react';
import './styles.scss';


function EmptyTileItem() {

  return <div className={'empty-item'} />;

}

export default EmptyTileItem;
