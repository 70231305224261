import React from 'react';

const EmptyMiniature = () => <div/>;

export const svgPanZoomBaseConfig = {
  SVGBackground: 'transparent',
  background: '#242427',
  detectAutoPan: false,
  toolbarProps: {
    SVGAlignX: 'center',
    SVGAlignY: 'center'
  },
  miniatureProps: {
    background: '#242427'
  },
  scaleFactorMin: 0.7,
  // disable miniature view
  customMiniature: EmptyMiniature
};
