export const getWeatherCategoryByLat = (lat) => {
  /**
   * Return weather category (climate) based on lat.
   * Selecting category from latitude is not strict so we are basing on some assumptions from:
   * https://en.wikipedia.org/wiki/K%C3%B6ppen_climate_classification
   * @param {number} lat - latitude
   * @return {string} found category
   */
  const absLat = Math.abs(parseFloat(lat));
  if (0 <= absLat && absLat <= 25) return 'a';
  if (25 <= absLat && absLat <= 50) return 'c';
  if (50 < absLat && absLat <= 75) return 'd';
  if (75 < absLat) return 'e';
};
