import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import CustomSpinner from '../CustomSpinner';

export class MenuEventsTitle extends Component {

  onClickHandler = (...args) => {
    const {loading, onClick} = this.props;
    if (loading) return;
    return onClick(...args);
  }

  render() {
    const {title, buttonTitle, loading} = this.props;
    return (
      <div className={'menu-events__header'}>
        <div className="menu-events__title">{title}</div>
        {buttonTitle &&
          <div className="menu-events__button-title" onClick={this.onClickHandler}>
            {loading ?
              <CustomSpinner size={14}/> :
              buttonTitle
            }
          </div>
        }
      </div>
    );
  }
}

MenuEventsTitle.propTypes = {
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

export default MenuEventsTitle;
