import React from 'react';
import PropTypes from 'prop-types';
import replayIcon from '../../../images/replay-icon.svg';
import './styles.scss';
import CustomSpinner from '../CustomSpinner';

const Video = ({showManualPlay, manualPlayOnClick, state}) => {
  let infoComponent;

  if (showManualPlay) {
    infoComponent = (
      <div className={'info-wrapper'} onClick={manualPlayOnClick}>
        <span>Your browser blocked video autoplay in background. Press here to load video.</span>
        <img src={replayIcon} alt={'Replay'} />
      </div>
    );
  } else if (state === 'GETTING_URL') {
    infoComponent = (
      <div className={'info-wrapper'}>
        <div className={'info-wrapper__content'}>
          <CustomSpinner className={'spinner'} />
          <span>Waiting for stream url...</span>
        </div>
      </div>
    );
  } else if (state === 'LOADING') {
    infoComponent = (
      <div className={'info-wrapper'}>
        <div className={'info-wrapper__content'}>
          <CustomSpinner className={'spinner'}/>
          <span>Video is loading...</span>
        </div>
      </div>
    );
  } else if (state === 'ONDEMAND') {
    infoComponent = (
      <div className={'info-wrapper'}>
        <div className={'info-wrapper__content'}>
          <CustomSpinner className={'spinner'} />
          <span>Waiting for video on-demand service...</span>
        </div>
      </div>
    );
  } else if (state === 'ONDEMAND_ERROR') {
    infoComponent = (
      <div className={'info-wrapper'}>
        <div className={'info-wrapper__content'}>
          <CustomSpinner className={'spinner'} />
          <span>Error occured when try to reach stream</span>
        </div>
      </div>
    );
  } else if (state === 'BUFFERING') {
    infoComponent = (
      <div className={'info-wrapper'}>
        <div className={'info-wrapper__content'}>
          <CustomSpinner className={'spinner'} />
          <span>Video is buffering...</span>
        </div>
      </div>
    );
  } else if (state === 'STOPPED') {
    infoComponent = (
      <div className={'info-wrapper'} onClick={manualPlayOnClick}>
        <span>Video problem occurred. Press here to reload video.</span>
        <img src={replayIcon} alt={'Replay'} />
      </div>
    );
  } else if (state === 'PAUSED') {
    infoComponent = (
      <div className={'info-wrapper'} onClick={manualPlayOnClick}>
        <span>Video is paused. Press here to reload video.</span>
        <img src={replayIcon} alt={'Replay'} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <video-js id="live-video" />
      <div className={'live'}>
        LIVE
      </div>
      {infoComponent}
    </React.Fragment>
  );
};

Video.propTypes = {
  showManualPlay: PropTypes.bool,
  manualPlayOnClick: PropTypes.func,
  state: PropTypes.string
};

export default Video;
