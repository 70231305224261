import {usersConstants} from '../constants/users';

const initialStage = {
  user: {
    username: null,
    accessToken: null
  }
};

export const users = (state = initialStage, action) => {
  switch (action.type) {
    case usersConstants.SET_USER:
      return {
        ...state,
        user: action.payload
      };
    default:
      return state;
  }
};

