import {deviceStatus} from '../store/constants/plantTes';

export const hasModuleActive = ({plant, module, modules, checkAll = false}) => {
  /**
   * Check if module is active in plant
   * :param plant: plant status object
   * :param module: module from plantModules constant
   * :param modules: array of modules
   * :param checkAll: if true all modules have to be active
   * :return: true if module/modules are active, false if not
   *
   * If module is provided then function do simple check.
   * If modules are provided then one of them needs to be active.
   */
  if (!plant || !Array.isArray(plant.modules)) return false;
  if (module !== null && module !== undefined) {
    return plant.modules.includes(module);
  }
  if (Array.isArray(modules)) {
    if (checkAll) {
      return modules.every((_module) => plant.modules.includes(_module));
    } else {
      return plant.modules.some((_module) => modules.includes(_module));
    }
  }
  return false;
};

export const deviceErrors = ({device}) => {
  /**
   * Check if device or related devices have errors
   * :param device: device object
   * :return: dictionary with errorCount
   */
  let errorCount = 0;
  if (device.status && device.status === deviceStatus.ERROR) { errorCount += 1; }
  if (device.temperature_sensors) {
    device.temperature_sensors.forEach(obj => { if (obj.status === deviceStatus.ERROR) errorCount += 1; });
  }
  return {errorCount};
};

export const isDeviceSelected = ({selectedDevices, type, device}) => {
  /**
   * Check if device is selected
   * :param type: device type
   * :param device: device object
   * :return: true if device is selected
   */
  for (let deviceData of selectedDevices) {
    if (deviceData.type === type && deviceData.device.object_id === device.object_id) return true;
  }
};

export const parseSkyImageInfo = (obj) => {
  const name_parts = obj.name.split('/');
  const date = name_parts[name_parts.length - 2];
  const time = name_parts[name_parts.length - 1].split('.')[0];
  return {date, time};
};
