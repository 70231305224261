import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import {Form, Row, Col, Button, InputGroup} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {generatePath, withRouter} from 'react-router-dom';
import routes from '../../routes';
import {connect} from 'react-redux';
import {menuActions} from '../../store/actions/menu';
import {projectsActions} from '../../store/actions/projects';
import './location.scss';
import Marker from '../../components/design/Marker';
import {locationHelper} from '../../helpers/location';
import config from '../../config';
import MapSearchBox from '../../components/design/MapSearchBox';

const INITIAL_CENTER = { lat: 0, lng: 23 };
const DEFAULT_ZOOM = 4;

export class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapsApiLoaded: false,
      name: '',
      lat: '',
      lng: '',
      locationValid: false,
      zoom: DEFAULT_ZOOM
    };
  }

  componentDidMount() {
    const {projectLocation} = this.props;
    this.props.dispatch(menuActions.setDesignMenuCollapse(false));
    if (projectLocation) this.handlePropsChange();
  }

  componentDidUpdate(prevProps) {
    const {projectLocation} = this.props;
    if (prevProps.projectLocation !== projectLocation && projectLocation) this.handlePropsChange();
  }

  handlePropsChange = () => {
    const {projectLocation} = this.props;
    let newState = {};
    if (locationHelper.isLatitudeValid(projectLocation.lat))
      newState.lat = projectLocation.lat;
    if (locationHelper.isLongitudeValid(projectLocation.lng))
      newState.lng = projectLocation.lng;
    newState.locationValid = locationHelper.isLocationValid(projectLocation);
    newState.name = projectLocation.name;
    this.setState(newState);
  }

  onMapClicked = ({ lat, lng }) => {
    if (this.props.isReadonly) return;
    this.setState({ lat, lng, locationValid: true });
    locationHelper.getLocationNameFromCoords({lat, lng}).then(name => this.setState({name}));
  };

  onNextStepClicked = () => {
    const {dispatch, projectId} = this.props;
    const {lat, lng, name} = this.state;
    dispatch(projectsActions.updateProjectSettings({ name }));
    dispatch(projectsActions.updateProjectLocation({ lat, lng, name }));
    this.props.history.push(generatePath(routes.designDescribe, {projectId}));
  };

  getMapOptions = (maps) => {
    return {
      fullscreenControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: maps.ControlPosition.RIGHT_BOTTOM,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ]
      },
    };
  }

  onCoordinatesChange = e => {
    let value = parseFloat(e.target.value);
    let name = e.target.name;
    this.setState({[name]: value}, () => {
      const {lat, lng} = this.state;
      if (locationHelper.isLocationValid({lat, lng})) {
        this.setState({ locationValid: true });
        locationHelper.getLocationNameFromCoords({lat, lng}).then(name => this.setState({name}));
      } else {
        this.setState({ locationValid: false, name: '' });
      }
    });
  };

  onNameChange = name => this.setState({name});
  updateCoordinates = ({lat, lng}) => {
    this.setState({lat, lng, locationValid: true, zoom: 11});
  }

  render() {
    const { lat, lng, locationValid, name, mapsApiLoaded, zoom } = this.state;
    const {  isReadonly } = this.props;
    const mapProps = {};
    if (locationValid) {
      const parsedLat = parseFloat(lat);
      const parsedLng = parseFloat(lng);
      if (!isNaN(parsedLat) && !isNaN(parsedLng)) mapProps.center = {lat: parsedLat, lng: parsedLng};
    }

    return (
      <div className={'map-wrapper'}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.GOOGLE_MAPS_API_KEY, libraries: 'places' }}
          defaultCenter={INITIAL_CENTER}
          defaultZoom={DEFAULT_ZOOM}
          zoom={zoom}
          onClick={this.onMapClicked}
          options={this.getMapOptions.bind(this)}
          onGoogleApiLoaded={() => this.setState({mapsApiLoaded: true})}
          {...mapProps}
        >
          {locationValid && (
            <Marker lat={lat} lng={lng} />
          )}
        </GoogleMapReact>
        {mapsApiLoaded &&
          <Form className="map-form">
            <Row className={'map-controls'}>
              <Col xs={4}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="location-name">Location:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <MapSearchBox
                    value={name}
                    onValueChange={this.onNameChange}
                    updateCoordinates={this.updateCoordinates}
                    isReadonly={isReadonly}
                  />
                </InputGroup>
              </Col>
              <Col xs={3}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="latitude">Latitude:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control type="number" aria-label="Latitude" aria-describedby="latitude"
                    name="lat" placeholder="Enter latitude" value={lat}
                    onChange={this.onCoordinatesChange} disabled={isReadonly}/>
                </InputGroup>
              </Col>
              <Col xs={3}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="longitude">Longitude:</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control type="number" aria-label="Longitude" aria-describedby="longitude"
                    name="lng" placeholder="Enter longitude" value={lng}
                    onChange={this.onCoordinatesChange} disabled={isReadonly}/>
                </InputGroup>
              </Col>
              <Col xs={2}>
                <Button
                  disabled={!locationValid || name === ''}
                  onClick={this.onNextStepClicked}>
                    Next step
                </Button>
              </Col>
            </Row>
          </Form>
        }
      </div>
    );
  }
}

Location.propTypes = {
  center: PropTypes.object,
  history: PropTypes.object.isRequired,
  projectId: PropTypes.string,
  projectLocation: PropTypes.object,
  isReadonly: PropTypes.bool,
  dispatch: PropTypes.func
};

const WrapperLocation = withRouter(Location);

const mapStateToProps = (state) => {
  return {
    projectId: state.projects.projectId,
    projectLocation: state.projects.project?.projectLocation
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrapperLocation);
