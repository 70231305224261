import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {hasModuleActive} from '../../helpers/plant';

export class HasModuleActive extends React.Component {
  render() {
    const {plant, module, modules, checkAll, children} = this.props;
    const moduleActive = hasModuleActive({plant, module, modules, checkAll});
    if (!moduleActive) return null;
    return children;
  }
}

HasModuleActive.propTypes = {
  plant: PropTypes.object,
  module: PropTypes.number,
  modules: PropTypes.arrayOf(PropTypes.number),
  checkAll: PropTypes.bool,
  children: PropTypes.node
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HasModuleActive);
