import React from 'react';
import {Button, Form, FormCheck} from 'react-bootstrap';
import PropTypes from 'prop-types';

import './styles.scss';
import {UnityProjectDesignCommunication} from '../../../helpers/UnityProjectDesignCommunication';
import {Icon} from '@mdi/react';
import {mdiInformationOutline} from '@mdi/js';

class PvSubmenu extends React.Component {
  state = {
    restrictive: true,
    fillingDisabled: !this.props.areaCreated
  };

  onChange(event) {
    const {onChange} = this.props;
    const eventTarget = event.target;
    if (eventTarget.value && eventTarget.max && parseInt(eventTarget.value) > parseInt(eventTarget.max)) return;
    if (eventTarget.value && eventTarget.min && parseInt(eventTarget.value) < parseInt(eventTarget.min)) return;
    onChange(eventTarget.name, eventTarget.value);
  }

  fillAreaWithPvs() {
    const {unityCommunication} = this.props;
    unityCommunication.fillAreaWithPvs(this.state.restrictive);

    this.setState({fillingDisabled: true});
  }

  restrictiveChange() {
    this.setState(prevState => ({restrictive: !prevState.restrictive}));
  }

  render() {
    const {params} = this.props;

    return (
      <React.Fragment>
        <div className="submenu__item">
          <div className="submenu__item__text">
            <Form.Text>
              Rows <br/>
              <span className="submenu__item__small-text">Between 1 and 25</span>
            </Form.Text>
          </div>
          <div className="submenu__item__input">
            <Form.Control
              plaintext
              type="number"
              min={1}
              max={25}
              value={params.rows}
              name="rows"
              onChange={this.onChange.bind(this)}
            />
          </div>
        </div>
        <div className="submenu__item">
          <div className="submenu__item__text">
            <Form.Text>
              Cols<br/>
              <span className="submenu__item__small-text">Between 1 and 25</span>
            </Form.Text>
          </div>
          <div className="submenu__item__input">
            <Form.Control
              plaintext
              type="number"
              min={1}
              max={25}
              value={params.cols}
              name="cols"
              onChange={this.onChange.bind(this)}
            />
          </div>
        </div>
        <div className="submenu__item">
          <div className="submenu__item__title">Fill area with PVs</div>
          <div className="submenu__item__text">
            <FormCheck>
              <FormCheck.Input onChange={this.restrictiveChange.bind(this)} checked={this.state.restrictive} />
              <FormCheck.Label>No overflow</FormCheck.Label>
            </FormCheck>
          </div>
          <div className="submenu__item__text">
            <Button variant="link" className={'unity-menu-help'} onClick={() =>
              alert('PVs will fill the area in a manner that no panel will stick out of the area outline.')}>
              <Icon path={mdiInformationOutline} size="1rem" />
            </Button>
          </div>
          <div className="submenu__item__footer-button clearfix">
            <Button className={'design-menu-button design-menu-button__subtle'} disabled={this.state.fillingDisabled}
              onClick={this.fillAreaWithPvs.bind(this)} variant="link">
              Fill area
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PvSubmenu.propTypes = {
  params: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  unityCommunication: PropTypes.instanceOf(UnityProjectDesignCommunication),
  areaCreated: PropTypes.object
};

export default PvSubmenu;
