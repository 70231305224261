import {plantConstants} from '../constants/plant';
import {plantService} from '../services/plant';
import {plantStatusTypes} from '../constants/plant';
import {menuActions} from './menu';
import {store} from '../../store';
import format from 'date-fns/format';
import {parseSkyImageInfo} from '../../helpers/plant';

export const plantActions = {
  getList,
  getPlant,
  getStatus,
  getStreams,
  nextStream,
  updateOnline,
  updateStatus,
  getSkyImages,
  newSkyImages
};

// TECHDEBT: helios and tes plants should use same statuses

function getList() {
  return (dispatch) => {
    dispatch({type: plantConstants.GETTING_LIST});
    return plantService.getList().then(response => {
      const list = response.data.plants;
      dispatch({type: plantConstants.GET_LIST_SUCCEEDED, payload: list});
    }).catch(error => {
      dispatch({type: plantConstants.GET_LIST_ERROR, payload: error});
      alert(`Error while getting plants list: ${error}`);
    });
  };
}

function getPlant(plantId) {
  return (dispatch) => {
    dispatch({type: plantConstants.GET_DETAILS});
    return plantService.getDetails(plantId).then(response => {
      const plant = response.data.plant;
      dispatch({type: plantConstants.GET_DETAILS_SUCCEEDED, payload: plant});
    }).catch(error => {
      dispatch({type: plantConstants.GET_DETAILS_ERROR, payload: error});
      alert(`Error while getting plant details: ${error}`);
    });
  };
}

function getStreams() {
  return (dispatch) => {
    plantService.getStreams().then(response => {
      const streams = response.data.streams;
      dispatch({type: plantConstants.GET_STREAMS_SUCCEEDED, payload: streams});
    }).catch(error => {
      alert(`Error while getting streams list: ${error}`);
    });
  };
}

function getStatus() {
  return (dispatch) => {
    plantService.getStatus().then(response => {
      const plantStatus = response.data;
      if (!plantStatus) return;
      dispatch({
        type: plantConstants.GET_STATUS_SUCCEEDED, payload: plantStatus
      });
      _handleStatus(dispatch, plantStatus);
    });
  };
}

function nextStream() {
  return {type: plantConstants.NEXT_STREAM};
}

function updateOnline(isOnline) {
  return (dispatch) => {
    const prevPlantStatus = store.getState().plant.status;
    const prevStatus = prevPlantStatus.status;
    if (prevPlantStatus.online !== isOnline) {
      dispatch({type: plantConstants.UPDATE_ONLINE, payload: isOnline});
    }
    if ((!prevPlantStatus.online || prevPlantStatus.online === true) && isOnline === false) {
      dispatch(menuActions.setHaltEmergency());
    }
    if ((prevPlantStatus.online === undefined || prevPlantStatus.online === false) && isOnline === true &&
        prevStatus === plantStatusTypes.OPERATIONAL) {
      dispatch(menuActions.setMenuInfoPlant());
    }
  };
}

function updateStatus(partialStatus) {
  return (dispatch) => {
    dispatch({type: plantConstants.UPDATE_STATUS, payload: partialStatus});
    _handleStatus(dispatch, partialStatus);
  };
}

function _handleStatus(dispatch, plantStatus) {
  if (plantStatus.status === undefined) return;
  const prevPlant = store.getState().plant;
  const prevPlantStatus = prevPlant.status;
  const prevStatus = prevPlantStatus.status;
  if (plantStatus.status === plantStatusTypes.HALTED || plantStatus.status === plantStatusTypes.MANUALLY_HALTED) {
    dispatch(menuActions.setHaltEmergency());
    return;
  }
  if (((prevStatus === plantStatusTypes.HALTED || prevStatus === plantStatusTypes.MANUALLY_HALTED) &&
    plantStatus.status === plantStatusTypes.OPERATIONAL)) {
    dispatch(menuActions.setMenuInfoPlant());
  }
}

function getSkyImages(date) {
  return dispatch => {
    dispatch({type: plantConstants.GET_SKY_IMAGES});
    const strDate = format(date, 'yyyy-MM-dd');
    plantService.getSkyImages(strDate).then(
      (response) => {
        dispatch({type: plantConstants.GET_SKY_IMAGES_SUCCEEDED, payload: {files: response.data.files, date}});
      }
    ).catch(() => {
      dispatch({type: plantConstants.GET_SKY_IMAGES_ERROR});
    });
  };
}

function newSkyImages(newFiles) {
  const currentDate = store.getState().plant.cloudImages?.currentDate;
  // checking only first file date as back-end should block older files
  const imageInfo = parseSkyImageInfo(newFiles[0]);
  if (imageInfo.date !== format(currentDate, 'yyyy-MM-dd')) return {type: plantConstants.NEW_SKY_IMAGES, payload: []};
  return {type: plantConstants.NEW_SKY_IMAGES, payload: newFiles};
}
