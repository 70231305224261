import React from 'react';

import icon from './icon.svg';

const Marker = () => (
  <div style={{width: '30px', height: '38px', marginLeft: '-15px', marginTop: '-38px'}}>
    <img
      src={icon} alt={'Maps marker'}
      style={{width: '100%'}} />
  </div>
);

export default Marker;
