import React, {Component} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import './App.scss';
import DesignStage from './pages/design';
import routes from './routes';
import history from './history';
import Menu from './components/Navigation/menu';
import PlantsPage from './pages/operate/Plants';
import PlantPage from './pages/operate/Plant';
import LoginPage from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import ProfilePage from './pages/Profile';
import mainImage from 'images/kyotopia-site.png';


export class App extends Component {

  render() {
    const {menuCollapsed} = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route exact path={[routes.login]}>
            <LoginPage />
          </Route>
          <PrivateRoute exact path={[routes.profile]}>
            <ProfilePage />
          </PrivateRoute>
          <PrivateRoute exact path={routes.operatePlants} component={PlantsPage} />
          <PrivateRoute path={routes.operatePlant} component={PlantPage} />
          <Route path={[routes.main, routes.designMain]}>
            <div className="App">
              <Menu />
              <div className={`page-content ${menuCollapsed ? 'page-content__menu-collapsed' : ''}`} id="content">
                <PrivateRoute exact path={routes.main} component={() => (
                  <div className="page-content__splash">
                    <img src={mainImage} alt="Kyotopia Site illustration"/>
                  </div>
                )} />
                <PrivateRoute path={routes.designMain} component={DesignStage} />
                <PrivateRoute path={routes.buildMain} component={() => (
                  <p>Build stage is not available in Kyotopia yet.</p>
                )} />
              </div>
            </div>
          </Route>
        </Switch>
      </Router>
    );
  }
}

App.propTypes = {
  menuCollapsed: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    menuCollapsed: state.menu.designMenuCollapse
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
