export const heliostatConstants = {
  SET_ACTIVE_HELIOSTAT: 'SET_ACTIVE_HELIOSTAT',
  GET_HELIOSTAT_SUCCEEDED: 'GET_HELIOSTAT_SUCCEEDED',
  GET_HELIOSTAT_LIST_SUCCEEDED: 'GET_HELIOSTAT_LIST_SUCCEEDED',
  UPDATE_HELIOSTAT: 'UPDATE_HELIOSTAT'
};

export const heliostatUrls = {
  GET_LIST: '/heliostats/',
  GET_DETAIL: '/heliostats/', // + ID
};

export const heliostatStatus = {
  0: 'Operational',
  1: 'Maintenance',
};

export const heliostatStatusTypes = {
  OPERATIONAL: 0,
  MAINTENANCE: 1
};
