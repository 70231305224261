export const routes = {
  main: '/',
  designMain: '/design',
  designLocation: '/design/:projectId/location',
  designDescribe: '/design/:projectId/describe',
  designEnergy: '/design/:projectId/energy-usage',
  designSiteDesign: '/design/:projectId/site-design',
  designGenerateReport: '/design/:projectId/generate-report',
  buildMain: '/build',
  operatePlants: '/operate',
  operatePlant: '/operate/plant/:plantId',
  login: '/login',
  profile: '/profile'
};

export default routes;
