import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../styles.scss';
import {formatLastSeen} from '../../../../helpers/utils';
import {connect} from 'react-redux';
import {isDeviceSelected} from '../../../../helpers/plant';
import {deviceStatus, deviceStatusMap, deviceTypes} from '../../../../store/constants/plantTes';
import {plantTesActions} from '../../../../store/actions/plantTes';
import {getTemperatureRow} from '../utils';
import Range from '../../Range';
import {plantTesService} from '../../../../store/services/plantTes';
import {getIsMaintenanceMode} from '../../../../store/selectors/plant';

// TECHDEBT: move styles to component
// TECHDEBT: create more generic component for tables
class PumpsTable extends React.Component {

  state = {
    pumpsRange: {}
  }

  componentDidMount() {
    const {pumps} = this.props;
    if (pumps && pumps.length > 0) this.generateRangeInitials();
  }

  componentDidUpdate(prevProps) {
    const {pumps} = this.props;
    if (prevProps.pumps && pumps && prevProps.pumps !== pumps) this.generateRangeInitials();
  }

  generateRangeInitials() {
    const {pumps} = this.props;
    let pumpsRange = {};
    pumps.forEach(pump => {
      pumpsRange[pump.object_id] = {frequency: [pump.frequency], rangeFrequency: [pump.frequency]};
    });
    this.setState({pumpsRange});
  }

  onRangeChange(pumpId, values) {
    this.setState({ pumpsRange: this.getNewPumpsState(pumpId, {rangeFrequency: values}) });
  }

  onRangeFinalChange(pumpId, values) {
    const confirmed = window.confirm(`Are you sure you want to set pump ${pumpId} frequency to ${values[0]}Hz?`);
    const {pumpsRange} = this.state;
    const prevFrequency = pumpsRange[pumpId].frequency;
    if (confirmed) {
      plantTesService.setPumpFrequency(pumpId, values[0]).then(() => {
        this.setState({ pumpsRange: this.getNewPumpsState(pumpId, {frequency: values}) });
      }).catch(error => {
        alert('Error while changing pump frequency: ' + (error.response?.data?.message || error));
        this.setState({ pumpsRange: this.getNewPumpsState(pumpId, {rangeFrequency: prevFrequency}) });
      });
    } else {
      this.setState({ pumpsRange: this.getNewPumpsState(pumpId, {rangeFrequency: prevFrequency}) });
    }
  }

  getNewPumpsState = (pumpId, {frequency, rangeFrequency}) => {
    const {pumpsRange} = this.state;
    let newPumpsRange = {
      ...pumpsRange,
      [pumpId]: {
        ...pumpsRange[pumpId],
      }
    };
    if (frequency !== undefined) newPumpsRange[pumpId].frequency = frequency;
    if (rangeFrequency !== undefined) newPumpsRange[pumpId].rangeFrequency = rangeFrequency;
    return newPumpsRange;
  };

  getPumpRow = (pump, index, isSelected) => {
    const {pumpsRange} = this.state;
    const {isReadonly, isMaintenanceMode} = this.props;
    const rangeFrequency = pumpsRange[pump.object_id]?.rangeFrequency;
    if (!rangeFrequency) return;
    // cols: id, status, frequency, last seen
    const valueError = pump.max_frequency < rangeFrequency[0];
    return (
      <tbody key={`pump-${index}`} >
        <tr
          onClick={() => this.onPumpClick(pump, isSelected)}
          className={clsx('table__row', {'table__row--selected': isSelected, 'table__row--error':
              pump.status === deviceStatus.ERROR})}
        >
          <td scope="row">{pump.object_id}</td>
          <td className={'table__cell'}>{deviceStatusMap[pump.status]}</td>
          <td className={'table__cell'}>{`${parseFloat(pump.frequency).toFixed(2)} Hz`}</td>
          <td className={'table__cell'}>{formatLastSeen(pump.timestamp)}</td>
        </tr>
        {(!isReadonly && isMaintenanceMode) &&
        <tr>
          <td colSpan="4" className="table__cell">
            {valueError ?
              <div className="table__cell__range-error">
                Frequency ({rangeFrequency[0]}Hz) cannot be bigger than expected maximum ({pump.max_frequency}Hz).
              </div>:
              <div className="table__cell__range">
                <Range
                  values={rangeFrequency}
                  max={pump.max_frequency}
                  onChange={values => this.onRangeChange(pump.object_id, values)}
                  onFinalChange={values => this.onRangeFinalChange(pump.object_id, values)}
                  disabled={!pump || pump.status !== deviceStatus.ACTIVE}
                />
              </div>
            }
            <div className="table__cell__range-text">
              {rangeFrequency && rangeFrequency[0]}Hz
            </div>
          </td>
        </tr>
        }
        {isSelected && pump.temperature_sensors ?
          pump.temperature_sensors.map((obj, idx) => getTemperatureRow(obj, idx, 3)):null}
      </tbody>
    );
  };

  isSelected = (pump) => {
    const {selectedDevices} = this.props;
    return isDeviceSelected({selectedDevices, type: deviceTypes.PUMP, device: pump});
  };

  onPumpClick(pump, isSelected) {
    const {dispatch} = this.props;
    if (isSelected) dispatch(plantTesActions.unselectDevice(deviceTypes.PUMP, pump));
    else dispatch(plantTesActions.selectDevice(deviceTypes.PUMP, pump));
  }

  render() {
    const {pumps} = this.props;
    return (
      <div className={'table'}>
        <table>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col" className={'table__cell'}>Status</th>
              <th scope="col" className={'table__cell'}>Frequency</th>
              <th scope="col" className={'table__cell'}>Last seen</th>
            </tr>
          </thead>
          {
            pumps && pumps.length > 0 ? (
              <>{pumps.map((hex, index) => (this.getPumpRow(hex, index, this.isSelected(hex))))}</>) :
              <tbody>
                <tr>
                  <td>No pumps</td>
                </tr>
              </tbody>
          }
        </table>
      </div>
    );
  }
}

PumpsTable.propTypes = {
  pumps: PropTypes.array,
  selectedDevices: PropTypes.array,
  isMaintenanceMode: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    selectedDevices: state.plant.selectedDevices,
    isMaintenanceMode: getIsMaintenanceMode(state),
    isReadonly: state.general.isReadonly
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PumpsTable);
