import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

export class ActionConfirm extends Component {

  render() {
    const {onConfirm, onCancel, text} = this.props;
    return (
      <div className={'buttons-block'}>
        <div className="buttons-block__title">Are you sure?</div>
        <div className="buttons-block__text">{text}</div>

        <div className={'buttons'}>
          <button className={'button button--small'} onClick={onConfirm}>yes</button>
          <button className={'button button--small'} onClick={onCancel}>no</button>
        </div>
      </div>
    );
  }
}

ActionConfirm.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  text: PropTypes.string
};

export default ActionConfirm;
