import React from 'react';
import {Default} from 'react-spinners-css';
import PropTypes from 'prop-types';

const CustomSpinner = ({color, size, ...props}) => {
  return (
    <Default color={color || 'white'} size={size || 40} {...props} />
  );
};

CustomSpinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

export default CustomSpinner;
