import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const CriticalEventBar = ({events, onClick}) => {
  let text;
  if (events && events.length > 1) text = `Critical errors (${events.length})`;
  else text = 'Critical error';
  return (
    <div className={'critical-event'} onClick={onClick}>
      <div className={'critical-event__text'}>{text}</div>
      <div className={'critical-event__title'}>{events && events[0].title}</div>
    </div>
  );
};

CriticalEventBar.propTypes = {
  events: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CriticalEventBar;
