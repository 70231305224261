import {projectsConstants} from '../constants/projects';
import config from '../../config';
import {overrideUrls} from '../../helpers/dynamicConfig';
import {projectService} from '../services/project';
import history from '../../history';
import {generateId} from '../../modules/id';
import {store} from '../../store';

export const projectsActions = {
  setProjectId,
  newProject,
  loadProject,
  saveProject,
  updateProject,
  updateProjectSettings,
  updateProjectChecklist,
  updateProjectFinancials,
  updateProjectSite,
  updateProjectLocation,
  updateProjectEnergyProperty,
  updateProjectUntyData
};

function setProjectId(projectId) {
  return {type: projectsConstants.SET_PROJECT_ID, payload: projectId};
}

function newProject() {
  return {type: projectsConstants.NEW_PROJECT};
}

function loadProject(project) {
  return dispatch => {
    console.log(config.IS_DEMO, project.api_url);
    if (config.IS_DEMO) overrideUrls(project.api_url, null, project.api_key);
    dispatch({type: projectsConstants.LOADING_PROJECT});
    return projectService.get(project.id)
      .then(response => {
        if (response.data) {
          const loadedProject = response.data;
          console.log('project', loadedProject,);
          if (config.IS_DEMO) loadedProject.settings.readonly = project.settings.readonly;
          dispatch({type: projectsConstants.LOAD_PROJECT_SUCCESS, payload: loadedProject});
        } else {
          dispatch({type: projectsConstants.LOAD_PROJECT_ERROR});
        }
      }).catch(e => {
        console.log('Error while loading project', e);
        dispatch({type: projectsConstants.LOAD_PROJECT_ERROR});
      });
  };
}

function saveProject() {
  return dispatch => {
    const project = store.getState().projects.project;
    const isNew = history.location.pathname.includes('/new/');
    const projectId = project.id || generateId();
    console.log('Project save', project);
    return projectService.save({
      'createdTimestamp': project.createdTimestamp || Math.round(Date.now() / 1000),
      'id': projectId,
      'projectLocation': project.projectLocation,
      'projectEnergyProperty': project.projectEnergyProperty,
      'name': project.settings.name,
      'settings': project.settings,
      'checklist': project.checklist,
      'financial': project.financial,
      'site': project.site,
      'unityData': project.unityData
    }).then(() => {
      console.log('project saved');
      if (isNew) history.replace({pathname: history.location.pathname.replace('new', projectId)});
      dispatch(projectsActions.setProjectId(projectId));
    });
  };
}

function updateProject(projectUpdate) {
  return dispatch => {
    dispatch({type: projectsConstants.UPDATE_PROJECT, payload: projectUpdate});
    return Promise.resolve();
  };
}
function updateProjectSettings(modelUpdate) {
  return updateProject({settings: modelUpdate});
}
function updateProjectChecklist(modelUpdate) {
  return updateProject({checklist: modelUpdate});
}
function updateProjectFinancials(modelUpdate) {
  return updateProject({financial: modelUpdate});
}
function updateProjectSite(modelUpdate) {
  return updateProject({site: modelUpdate});
}
function updateProjectLocation(modelUpdate) {
  return updateProject({projectLocation: modelUpdate});
}
function updateProjectEnergyProperty(modelUpdate) {
  return updateProject({projectEnergyProperty: modelUpdate});
}
function updateProjectUntyData(modelUpdate) {
  return updateProject({unityData: modelUpdate});
}
