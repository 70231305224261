import axios from 'axios';
import {plantUrls} from '../constants/plant';
import {getPlantId} from '../../helpers/store';


export const plantService = {
  getList,
  getDetails,
  getStreams,
  getStatus,
  resumeProduction,
  haltProduction,
  getStreamToken,
  getSkyImages
};

function getList() {
  return axios.request({
    method: 'GET',
    url: plantUrls.GET_LIST
  });
}

function getDetails(plantId) {
  return axios.request({
    method: 'GET',
    url: plantUrls.GET_DETAILS.replace('<plantId>', plantId)
  });
}

function getStreams() {
  return axios.request({
    method: 'GET',
    url: plantUrls.GET_STREAMS.replace('<plantId>', getPlantId())
  });
}

function getStatus() {
  return axios.request({
    method: 'GET',
    url: `${plantUrls.GET_STATUS}?projectId=${getPlantId()}`
  });
}

function getStreamToken(arn) {
  const url = plantUrls.GET_STREAM_TOKEN;
  return axios.request({
    method: 'GET',
    url,
    params: {
      channelArn: arn,
      originHost: '*'
    }
  });
}

function resumeProduction() {
  return axios.request({
    method: 'POST',
    url: `${plantUrls.RESUME_PRODUCTION}?projectId=${getPlantId()}`
  });
}

function haltProduction() {
  return axios.request({
    method: 'POST',
    url: `${plantUrls.HALT_PRODUCTION}?projectId=${getPlantId()}`
  });
}

function getSkyImages(date) {
  let params = {};
  if (date) params['date'] = date;
  return axios.request({
    method: 'GET',
    url: plantUrls.GET_SKY_IMAGES.replace('<plantId>', getPlantId()),
    params
  });
}
