import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../styles.scss';
import {formatLastSeen} from '../../../../helpers/utils';
import {connect} from 'react-redux';
import {isDeviceSelected} from '../../../../helpers/plant';
import {deviceStatus, deviceStatusMap, deviceTypes} from '../../../../store/constants/plantTes';
import {plantTesActions} from '../../../../store/actions/plantTes';
import {getTemperatureRow} from '../utils';

// TECHDEBT: move styles to component
// TECHDEBT: create more generic component for tables
class PipesTable extends React.Component {
  getPipeRow = (pipe, index, isSelected) => {
    // cols: id, last seen
    return (
      <tbody key={`pipe-${index}`} >
        <tr
          onClick={() => this.onPipeClick(pipe, isSelected)}
          className={clsx('table__row', {'table__row--selected': isSelected, 'table__row--error':
              pipe.status === deviceStatus.ERROR})}
        >
          <td scope="row">{pipe.object_id}</td>
          <td scope="row">{deviceStatusMap[pipe.status]}</td>
          <td className={'table__cell'}>{formatLastSeen(pipe.timestamp)}</td>
        </tr>
        {isSelected && pipe.temperature_sensors ?
          pipe.temperature_sensors.map((obj, idx) => getTemperatureRow(obj, idx, 2)):null}
      </tbody>
    );
  };

  isSelected = (pipe) => {
    const {selectedDevices} = this.props;
    return isDeviceSelected({selectedDevices, type: deviceTypes.PIPE, device: pipe});
  };

  onPipeClick(pipe, isSelected) {
    const {dispatch} = this.props;
    if (isSelected) dispatch(plantTesActions.unselectDevice(deviceTypes.PIPE, pipe));
    else dispatch(plantTesActions.selectDevice(deviceTypes.PIPE, pipe));
  }

  render() {
    const {pipes} = this.props;
    return (
      <div className={'table'}>
        <table>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Status</th>
              <th scope="col" className={'table__cell'}>Last seen</th>
            </tr>
          </thead>
          {
            pipes && pipes.length > 0 ? (
              <>
                {
                  pipes.map((hex, index) =>
                    (this.getPipeRow(hex, index, this.isSelected(hex))))
                }
              </>) : <tbody>
              <tr>
                <td>No pipes</td>
              </tr>
            </tbody>
          }
        </table>
      </div>
    );
  }
}

PipesTable.propTypes = {
  pipes: PropTypes.array,
  selectedDevices: PropTypes.array,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    selectedDevices: state.plant.selectedDevices
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PipesTable);
