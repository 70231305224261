import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import './styles.scss';
import CollapseMenu from '../../../containers/operate/menu/CollapseMenu';
import PlantLivePreview from '../../../containers/operate/PlantLivePreview';
import {plantActions} from '../../../store/actions/plant';
import {plantModules} from '../../../store/constants/plant';
import TesLiveView from '../../../components/operate/TesLiveView';
import config from '../../../config';
import {getPlantById} from '../../../helpers/store';
import {overrideUrls, removeUrlsOverride} from '../../../helpers/dynamicConfig';
import CriticalEventBar from '../../../components/operate/CriticalEventBar';
import {eventsActions} from '../../../store/actions/events';
import {menuActions} from '../../../store/actions/menu';
import {hasModuleActive} from '../../../helpers/plant';
import HasModuleActive from '../../../containers/HasModuleActive';
import CloudViewer from '../../../containers/operate/CloudViewer';

export class ProjectPage extends Component {

  initializeVideoFunc = null;

  componentDidMount() {
    const {plant} = this.props;
    if (!plant) this.getPlant();
  }

  componentWillUnmount() {
    removeUrlsOverride();
  }

  getPlant() {
    const {dispatch, match} = this.props;
    const plantId = parseInt(match.params.plantId);
    dispatch(plantActions.getPlant(plantId)).then(() => dispatch(plantActions.getStreams()));
  }

  setInitializeVideoFunc(initializeVideoFunc) {
    this.initializeVideoFunc = initializeVideoFunc;
  }

  onCriticalEventBarClick() {
    const {dispatch} = this.props;
    dispatch(eventsActions.setShowOnlyCritical(true));
    dispatch(menuActions.setEventsMenu());
  }

  render() {
    const {plant, menuInfo, criticalEvents, menuCloudViewer} = this.props;
    if (!plant) return <div />;

    let liveVideoProps = {};
    if (menuInfo) liveVideoProps.style = {'display': 'none'};

    let mainComponent;
    if (hasModuleActive({plant, modules: [plantModules.HELIOSTATS, plantModules.WEATHER]})) {
      mainComponent = <PlantLivePreview setInitializeVideoFunc={this.setInitializeVideoFunc.bind(this)} />;
    } else if (hasModuleActive({plant, module: plantModules.TES})) {
      mainComponent = <TesLiveView />;
    } else {
      mainComponent = <div />;
    }

    return (
      <div className={'page project-page'}>
        <CollapseMenu initializeLiveVideo={() => this.initializeVideoFunc && this.initializeVideoFunc()}/>
        {mainComponent}
        {criticalEvents && criticalEvents.length > 0 &&
          <CriticalEventBar events={criticalEvents} onClick={this.onCriticalEventBarClick.bind(this)}/>}
        {menuCloudViewer &&
          <HasModuleActive module={plantModules.SKY_CAM}>
            <CloudViewer />
          </HasModuleActive>
        }
      </div>
    );
  }
}

ProjectPage.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  menuInfo: PropTypes.bool,
  menuCloudViewer: PropTypes.bool,
  plant: PropTypes.object,
  criticalEvents: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    menuInfo: state.menu.menuInfo,
    menuCloudViewer: state.menu.menuCloudViewer,
    criticalEvents: state.events?.criticalEvents
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectPage));

