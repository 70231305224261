import {Button, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import FromBody from '../../FormBody';

const SignInForm = ({onInputChange, onButtonPress, onResetPress, disabled, defaultUsername}) => (
  <Form className={'login-form'}>
    <p>Please provide username and password to access system.</p>
    <FromBody
      onButtonPress={onButtonPress}
      fields={[
        {label: 'Username', type: 'text', name: 'username', default: defaultUsername},
        {label: 'Password', type: 'password', name: 'password'}
      ]}
      onInputChange={onInputChange}
    />
    <Form.Group className={'login-form__footer'}>
      <Button onClick={onButtonPress} disabled={disabled}>Login</Button><br/><br/>
      <Button onClick={onResetPress} disabled={disabled} variant={'secondary'}>Reset password</Button>
    </Form.Group>
  </Form>
);

SignInForm.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onButtonPress: PropTypes.func.isRequired,
  onResetPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultUsername: PropTypes.string
};

export default SignInForm;
