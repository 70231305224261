import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import {deviceTypes, deviceTypesMap} from '../../../store/constants/plantTes';
import ValveMenu from './ValveMenu';
import PipeMenu from './PipeMenu';

const MENU_MARGIN = 6;


const SchemaContextMenu = ({type, obj, position, isMaintenanceMode, isReadonly}) => {

  return (
    <div className="schema-context-menu" role="menu"
      style={{top: position.y + MENU_MARGIN, left: position.x + MENU_MARGIN}}>
      <div className="schema-context-menu__title">
        {deviceTypesMap[type].toUpperCase()}: {obj.object_id}
      </div>
      <div className="schema-context-menu__body">
        {type === deviceTypes.VALVE &&
          <ValveMenu valve={obj} isMaintenanceMode={isMaintenanceMode} isReadonly={isReadonly} />}
        {type === deviceTypes.PIPE &&
          <PipeMenu pipe={obj} />}
      </div>
    </div>
  );
};

SchemaContextMenu.propTypes = {
  type: PropTypes.number.isRequired,
  obj: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  isMaintenanceMode: PropTypes.bool.isRequired,
  isReadonly: PropTypes.bool.isRequired
};

export default SchemaContextMenu;



