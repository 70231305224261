import format from 'date-fns/format';

export const checkIfExists = (value) => {
  return value !== undefined && value !== null;
};

export const handleEnterPress = (event, callback) => {
  if (event.key === 'Enter') {
    callback();
    event.preventDefault();
  }
};

export const EVENT_SOURCE_MAP = {
  'IDS': 'Intruder detection system',
  'IR': 'Infrared camera',
  'TEMP_SENSOR': 'Temperature sensor',
  'UNKNOWN': 'Unknown'
};

export const downloadFromUrl = (url) => {
  let element = document.createElement('a');
  element.setAttribute('href', url);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};


const REGEX_ID = /([0-9]+)/;
export const sortDeviceById = (a, b) => {
  const aId = a.object_id.match(REGEX_ID)[1];
  const bId = b.object_id.match(REGEX_ID)[1];
  return parseInt(aId) - parseInt(bId);
};

export const formatLastSeen = (timestamp) => {
  const date = new Date(parseInt(timestamp) * 1000);
  return format(date, 'HH:mm:ss y-MM-dd');
};
