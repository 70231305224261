import React, {Component} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './styles.scss';


export class MenuIcon extends Component {

  render() {
    const {icon, label, onClick, point, isActive, children} = this.props;

    return (
      <div className={clsx('menu-item', {'menu-item--active': isActive})} onClick={onClick}>
        {point && <span className={'menu-item__point'} />}
        <div className="menu-item__icon">
          {icon}
        </div>
        <div className="menu-item__text">{label}</div>
        {children}
      </div>
    );
  }
}

MenuIcon.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func,
  point: PropTypes.bool,
  isActive: PropTypes.bool,
  children: PropTypes.element
};

export default MenuIcon;
