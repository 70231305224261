import React from 'react';
import { Nav } from 'react-bootstrap';
import { WizardStepMenuItem } from './WizardStepMenuItem';
import PropTypes from 'prop-types';

export const WizardNavigation = ({ steps, isMenuCollapsed }) => (
  <Nav style={{ display: 'grid' }}>
    {steps.map((step, index) =>
      <WizardStepMenuItem
        key={index}
        href={step.href}
        text={step.text}
        mdi={step.mdi}
        selected={step.selected}
        isMenuCollapsed={isMenuCollapsed} />)
    }
  </Nav>
);

WizardNavigation.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    mdi: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    selected: PropTypes.bool
  })),
  isMenuCollapsed: PropTypes.bool
};
