import React from 'react';
import format from 'date-fns/format';
import {filesService} from '../../store/services/files';
import CustomSpinner from '../../components/operate/CustomSpinner';
import {downloadFromUrl} from '../../helpers/utils';

class AvailableFiles extends React.Component {
  state = {
    loading: false,
    files: null,
    dirs: null,
    currentDir: '',
  }

  componentDidMount() {
    this.listDir();
  }

  listDir(dir='') {
    this.setState({loading: true});
    filesService.getCsvImports(dir).then(response => {
      const data = response.data;
      this.setState({files: data.files, dirs: data.dirs, loading: false, currentDir: dir});
    }).catch(e => {
      this.setState({loading: false});
      window.alert('Cannot load files: ' + e);
    });
  }

  goToDir(event, dir) {
    event.preventDefault();
    this.listDir(dir);
  }

  downloadFile(event, fileName) {
    event.preventDefault();
    filesService.getCsvImport(fileName).then(response => {
      downloadFromUrl(response.data);
    }).catch(e => {
      window.alert('Cannot download file: ' + e);
    });
  }

  renderTree() {
    const {files, dirs, currentDir} = this.state;
    let renderOptions;
    if (currentDir !== '') {
      let path = '';
      renderOptions = currentDir.split('/').map((dir, index) => {
        if (dir === '') return null;
        path += dir + '/';
        const dirPath = path.slice();
        return (
          <div key={'dir-opt-' + index}>
            <a href="#" onClick={(e) => this.goToDir(e, dirPath)}>{dirPath}</a>
          </div>
        );
      });
      const top = (
        <React.Fragment>
          <div key={'dir-info'}>Current directory: {currentDir}</div>
          <div key={'dir-opt-root'}>
            <a href="#" onClick={(e) => this.goToDir(e, '')}>Go to root</a>
          </div>
        </React.Fragment>
      );
      renderOptions.unshift(top);
    }
    const renderDirs = dirs.map((dir, index) => (
      <div key={'dir-' + index}>
        <a href="#" onClick={(e) => this.goToDir(e, dir)}>{dir}</a>
      </div>
    ));
    const renderFiles = files.map((file, index) => (
      <div key={'download-file-' + index}>
        <a href="#" onClick={(e) => this.downloadFile(e, file.name)}>
          {file.name}
        </a>
        &nbsp;(updated: {format(new Date(file.last_modified), 'dd/MM/yyyy HH:mm:ss')})
      </div>
    ));
    return (
      <React.Fragment>
        {renderOptions}
        {renderDirs}
        {renderFiles}
      </React.Fragment>
    );
  }

  render() {
    const {files, dirs, loading} = this.state;
    return (
      <div>
        <h2>Available files</h2>
        {loading && <CustomSpinner size={24} color={'#212529'} />}
        {!loading && !files && !dirs && <p>No files available</p>}
        {!loading && files && this.renderTree()}
      </div>
    );
  }
}

export default AvailableFiles;
