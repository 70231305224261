import React, {Component} from 'react';
import './styles.scss';
import {logo} from '../../../components/logo';
import routes from '../../../routes';
import {arrowLeft} from '../../../components/arrows';
import avatar from '../../../images/avatar.png';
import {usersActions} from '../../../store/actions/users';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';


export class OperateMenu extends Component {

  render() {
    const {user, history} = this.props;

    return (
      <div className={'enter-menu'}>
        <a href={routes.main} title="Go to Main page" className={'enter-menu__logo'}>
          {logo()}
          <div className="enter-menu__logo-subtitle">operate</div>
        </a>
        <div className={'enter-menu__item'}>
          <span className="enter-menu__item-title">operate</span>
          <span className="enter-menu__item-text">Once designed and built the KYOTO power plant can be
                        supervised and maintained here. Manage the power production of your KYOTO power plant and
                        each element of the existing infrastructure.</span>
          <a href="/" className={'enter-menu__item-back'}>{arrowLeft()} back</a>
        </div>
        <div className="enter-menu__user">
          <div className="media d-flex align-items-center">
            <img src={avatar}
              alt="Avatar"
              onClick={() => history.push(routes.profile)}
              width="40" className="rounded-circle shadow-sm cursor-pointer "/>
            <div className="media-body ml-3">
              <p className="m-0 logout-name cursor-pointer" onClick={() => history.push(routes.profile)}>
                {user.name}
              </p>
              <a onClick={() => usersActions.logout(history)} href='' >
                <p className="logout-logout">Logout</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


OperateMenu.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OperateMenu));
