import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import './styles.scss';
import {plantService} from '../../../store/services/plant';
import {checkVideoAutoPlay} from '../../../helpers/video';
import Video from '../Video';
import { getFlowModeById } from 'helpers/store';
import Schema from '../Schema';
import {plantTesActions} from '../../../store/actions/plantTes';
import {getTesStatus} from '../../../store/selectors/plant';


class TesLiveView extends React.Component {

  player = null;
  state = {
    showManualPlay: false,
    videoState: null,
    schemaConfig: null
  };

  componentDidMount() {
    const {streams} = this.props;

    if (streams && streams.length > 0) {
      this.delayInitializeVideo();
    }
  }

  componentDidUpdate(prevProps) {
    const {liveVideo, selectedStream, streams, status, flowModes} = this.props;

    // Schema change handler
    if (status.global) {
      const {status: prevStatus, flowModes: prevFlowModes} = prevProps;
      const {flow_mode: flowMode, is_maintenance: isMaintenance} = status.global;
      const flowModeObj = getFlowModeById(flowModes, isMaintenance, flowMode);
      if (flowModeObj) {
        const config = flowModeObj?.config;
        if (prevStatus && prevStatus.global && prevFlowModes) {
          const prevFlowModeObj = getFlowModeById(prevFlowModes, prevStatus.global.is_maintenance,
            prevStatus.global.flow_mode);
          if (prevFlowModeObj && prevFlowModeObj.config !== flowModeObj.config) {
            this.setState({schemaConfig: config});
          }
        } else {
          this.setState({schemaConfig: config});
        }
      }
    }

    // Video change hanlder
    if ((prevProps && prevProps.liveVideo === false && liveVideo === true) ||
        (prevProps && prevProps.selectedStream !== selectedStream) && streams.length > 0) {
      this.initializeVideo();
    }
    if (prevProps && prevProps.streams !== streams && streams.length > 0) {
      this.delayInitializeVideo();
    }
    if (prevProps && prevProps.liveVideo === true && liveVideo === false) {
      this.player = null;
    }
    if (prevProps && !prevProps.status?.global && status && status.global) {
      this.delayInitializeVideo();
    }
  }

  componentWillUnmount() {
    if (this.player) this.player.dispose();
  }

  delayInitializeVideo() {
    checkVideoAutoPlay(
      () => this.initializeVideo(),
      () => this.setState({showManualPlay: true})
    );
  }

  manualPlay() {
    this.setState({showManualPlay: false});
    this.initializeVideo();
  }

  initializeVideo() {
    if (!this.player) {
      const videoElement = document.getElementById('live-video');
      if (!videoElement) {
        return;
      }
      const _this = this;
      this.player = videojs(videoElement, { controls: false, autoplay: 'muted', width: '100%', height: '100vh' },
        function() {
          this.on('play', () => _this.setState({videoState: 'PLAYING'}));
          this.on('playing', () => _this.setState({videoState: 'PLAYING'}));
          this.on('waiting', () => _this.setState({videoState: 'BUFFERING'}));
          this.on('pause', () => _this.setState({videoState: 'PAUSED'}));
          this.on('stop', () => _this.setState({videoState: 'STOPPED'}));
        });
    }
    this.loadVideo();
  }

  loadVideo(attempt=1) {
    console.log('[TesLiveView] Loading new video for tes');
    const {selectedStream, streams} = this.props;

    if (streams === []) {
      console.log('streams returned empty array');
      return;
    }

    const stream = streams[selectedStream];
    if (!stream) return;
    console.log('Getting url for stream:', stream.arn);
    if (attempt === 1) this.setState({videoState: 'GETTING_URL'});
    plantService.getStreamToken(stream.arn).then(response => {
      const data = response.data?.data;
      const streamState = data.stream_state;
      if (streamState !== 'LIVE') {
        if (attempt === 10) {
          this.setState({videoState: 'ONDEMAND_ERROR'});
        } else {
          this.setState({videoState: 'ONDEMAND'});
          setTimeout(() => this.loadVideo(attempt + 1), 5000);
        }
      } else {
        const token = response.data.data.jwt?.body;
        const streamUrl = `${stream.url}?token=${token}`;
        this.setState({videoState: 'LOADING'});

        console.log('Call loadVideo', streamUrl);
        this.player.src({
          src: streamUrl
        });
      }
    }).catch(error => {
      if (error.response?.status === 404){
        alert('Video for this event is no available!');
      }
      else {
        alert('Error while getting stream url:' + error);
      }
    });
  }

  toggleDeviceSelect(type, device) {
    const {dispatch, selectedDevices} = this.props;
    let deviceFound = false;
    for (const selectedDevice of selectedDevices) {
      if (selectedDevice.device.object_id === device.object_id) {
        deviceFound = true;
        dispatch(plantTesActions.unselectDevice(type, device));
        break;
      }
    }
    if (!deviceFound) dispatch(plantTesActions.selectDevice(type, device));
  }

  render() {
    const {status, liveVideo, menuTesInfo, selectedDevices, isReadonly, plant} = this.props;
    const {showManualPlay, videoState, schemaConfig} = this.state;
    if (!status || !status.global) return <div />;

    // url for local testing = 'http://localhost:3000/images/local_schemas/schema.svg';
    return (
      <div className={'tes-page'}>
        {!liveVideo && plant &&
          <Schema
            url={plant.schemaUrl}
            config={schemaConfig}
            menuVisible={menuTesInfo}
            status={status}
            selectedDevices={selectedDevices}
            isReadonly={isReadonly}
            toggleDeviceSelect={this.toggleDeviceSelect.bind(this)}
          />
        }
        {liveVideo &&
          <Video
            showManualPlay={showManualPlay}
            manualPlayOnClick={this.manualPlay.bind(this)}
            state={videoState}
          />
        }
      </div>
    );
  }
}

TesLiveView.propTypes = {
  plant: PropTypes.object,
  status: PropTypes.object,
  flowModes: PropTypes.object,
  dispatch: PropTypes.func,
  streams: PropTypes.array,
  liveVideo: PropTypes.bool,
  selectedStream: PropTypes.number,
  menuTesInfo: PropTypes.bool,
  selectedDevices: PropTypes.array,
  isReadonly: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    status: getTesStatus(state),
    flowModes: state.plant.tes.modes,
    streams: state.plant.streams,
    liveVideo: state.menu.liveVideo,
    selectedStream: state.plant.selectedStream,
    menuTesInfo: state.menu.menuTesInfo,
    selectedDevices: state.plant.selectedDevices,
    isReadonly: state.general.isReadonly
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TesLiveView));
