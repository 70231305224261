import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import './styles.scss';
import InfoItem from '../../../components/operate/InfoItem';
import MenuHeaderTitle from '../../../components/operate/MenuHeaderTitle';
import MenuHeaderBack from '../../../components/operate/MenuHeaderBack';
import BigButton from '../../../components/operate/buttons/BigButton';
import {menuActions} from '../../../store/actions/menu';
import {heliostatStatus as heliostatStatusName, heliostatStatusTypes} from '../../../store/constants/heliostat';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import {heliostatService} from '../../../store/services/heliostat';

export class MenuInfoHeliostat extends Component {

  setMenuInfoPlant = () => {
    this.props.dispatch(menuActions.setMenuInfoPlant());
  };

  handleMaintenanceMode = () => {
    this.props.dispatch(menuActions.setMenuInfoMaintenance());
  };

  resumeProduction = () => {
    const {heliostat} = this.props;
    heliostatService.resumeProduction(heliostat.id).then(() => {
      console.log('Resuming production');
    }).catch(() => {
      alert('Error while resuming production');
    });
  };

  render() {
    const {heliostat, isReadonly} = this.props;

    const statusTitle = heliostatStatusName[heliostat.mode];
    const lastSeen = heliostat.last_seen ? new Date(heliostat.last_seen) : new Date();
    const lastSeenDistance = formatDistanceStrict(new Date(), lastSeen);
    let buttonComponent;
    if (!isReadonly && heliostat.status === 1) {
      buttonComponent = heliostat.mode === heliostatStatusTypes.OPERATIONAL ?
        <BigButton text={'maintenance mode'} onClick={this.handleMaintenanceMode.bind(this)}/> :
        <BigButton text={'resume production'} onClick={this.resumeProduction.bind(this)} />;
    }

    return (
      <React.Fragment>
        <MenuHeaderBack title={'Heliostat Details'} onClick={this.setMenuInfoPlant}/>

        <MenuHeaderTitle subtitle={'Selected Object:'} title={`Heliostat ${heliostat.name}`}
          online={heliostat.status === 1} />

        <div className="items-wrapper">
          <InfoItem title={'Status'} subtitle={'Current mode'} data={statusTitle}
            yellow={heliostat.mode === heliostatStatusTypes.MAINTENANCE}/>
          <InfoItem title={'Last update'} subtitle={'Last connection'} data={`${lastSeenDistance} ago`}/>
        </div>

        {buttonComponent}
      </React.Fragment>
    );
  }
}

MenuInfoHeliostat.propTypes = {
  heliostat: PropTypes.object,
  isReadonly: PropTypes.bool,
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    menuInfo: state.menu.menuInfo,
    heliostat: state.heliostat.heliostat,
    isReadonly: state.general.isReadonly
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuInfoHeliostat);
