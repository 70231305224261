import React from 'react';
import { Nav } from 'react-bootstrap';
import ProjectStageMenuItem from './ProjectStageMenuItem';
import PropTypes from 'prop-types';

export const ProjectStageNavigation = ({ stages }) => (
  <Nav>
    {stages.map((stage, index) => <ProjectStageMenuItem key={index} href={stage.href} title={stage.title} description={stage.description} numberOfProjects={stage.numberOfProjects} />)}
  </Nav>
);

ProjectStageNavigation.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      description: PropTypes.string,
      numberOfProjects: PropTypes.number
    })
  )
};
