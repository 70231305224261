import {Button, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

const PasswordResetSuccessForm = ({onButtonPress, disabled}) => (
  <Form className={'login-form'}>
    <p>New password has been set. Try to login with new password</p>
    <Form.Group className={'login-form__footer'}>
      <Button onClick={onButtonPress} disabled={disabled}>Back to login</Button><br/><br/>
    </Form.Group>
  </Form>
);

PasswordResetSuccessForm.propTypes = {
  onButtonPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default PasswordResetSuccessForm;
