import React, {Component} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './styles.scss';
import {deviceTypes} from '../../../../store/constants/plantTes';
import TanksTable from '../../tables/TanksTable';
import {deviceErrors} from '../../../../helpers/plant';
import ValvesTable from '../../tables/ValvesTable';
import HeatersTable from '../../tables/HeatersTable';
import HexesTable from '../../tables/HexesTable';
import PumpsTable from '../../tables/PumpsTable';
import PipesTable from '../../tables/PipesTable';


export class DeviceTypeInfo extends Component {

  state = {
    errorCount: 0,
    expanded: false
  }

  componentDidUpdate(prevProps) {
    const {devices} = this.props;
    if (prevProps && prevProps.devices !== devices) {
      let errorCount = 0;
      devices.forEach(device => errorCount += deviceErrors({device}).errorCount);
      this.setState({errorCount});
    }
  }

  renderDevices = () => {
    const {type, devices} = this.props;
    if (type === deviceTypes.TANK) return <TanksTable tanks={devices} />;
    if (type === deviceTypes.VALVE) return <ValvesTable valves={devices} />;
    if (type === deviceTypes.HEATER) return <HeatersTable heaters={devices} />;
    if (type === deviceTypes.HEX) return <HexesTable hexes={devices} />;
    if (type === deviceTypes.PUMP) return <PumpsTable pumps={devices} />;
    if (type === deviceTypes.PIPE) return <PipesTable pipes={devices} />;
  }

  toggleExpand = () => this.setState({expanded: !this.state.expanded});

  render() {
    const {title, subtitle} = this.props;
    const {errorCount, expanded} = this.state;
    return (
      <div className={clsx('device-info', {'device-info--error': errorCount})}>
        <div className="device-info__head" onClick={this.toggleExpand}>
          <div>
            <div className="device-info__title">{title}</div>
            <div className="device-info__subtitle">{subtitle}</div>
          </div>
          <div>
            <div className="device-info__data">{errorCount ? 'Error':'Operational'}</div>
            <div className="device-info__subdata">{errorCount ? `Issues: ${errorCount}`: 'No issues'}</div>
          </div>
        </div>
        <div className="device-info__body">
          {expanded && this.renderDevices()}
        </div>
      </div>
    );
  }
}

DeviceTypeInfo.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  devices: PropTypes.array,
  type: PropTypes.number.isRequired
};

export default DeviceTypeInfo;
