import React, {Component} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './styles.scss';

export class MenuBorderItem extends Component {

  render() {
    const {icon, label, isActive, isDanger, onClick} = this.props;
    return (
      <div
        className={clsx(['menu-item__border', {
          'menu-item__border--danger-active': isDanger && isActive,
          'menu-item__border--danger': isDanger,
          'menu-item__border--active': isActive
        }])}
        onClick={onClick}>
        <div className="menu-item__icon">
          {icon}
        </div>
        <div className="menu-item__text">{label}</div>
      </div>
    );
  }
}

MenuBorderItem.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  isActive: PropTypes.bool,
  isDanger: PropTypes.bool,
  onClick: PropTypes.func
};

export default MenuBorderItem;
