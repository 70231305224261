import React from 'react';

export const arrowRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12">
    <g transform="rotate(45 348.486 -208.898)">
      <path id="a" d="M258 191a1 1 0 01-2 0v-5h-5a1 1 0 010-2h6a1 1 0 011 1z"/>
    </g>
  </svg>
);

export const arrowLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12">
    <g  transform="rotate(-134 -43.958 205.426)">
      <path d="M70 383a1 1 0 0 1-2 0v-5h-5a1 1 0 0 1 0-2h6a1 1 0 0 1 1 1z"/>
    </g>
  </svg>
);
