import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#4e5153',
    backgroundColor: '#474848',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#fff'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff',
    fontSize: '14px',
    fontWeight: '500'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
    fontSize: '14px',
    fontWeight: '500'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#474848'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#727474':'#fff',
    backgroundColor: state.isDisabled ? '#4c4d4d' : (state.isSelected ? '#727474':'transparent'),
    cursor: state.isDisabled ? 'not-allowed':'pointer',
    '&:hover': {
      backgroundColor: state.isDisabled ? '#4c4d4d' : '#8b8d8d',
    }
  })
};

export default class CustomSelect extends React.Component {

  render() {
    return (
      <Select
        styles={customStyles}
        {...this.props} />
    );
  }

}
