import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {plantModules, plantStatusTypes} from '../../../store/constants/plant';
import {plantService} from '../../../store/services/plant';
import PlantNotWorking from '../../../components/operate/PlantNotWorking';
import {hasModuleActive} from '../../../helpers/plant';

export class MenuHaltEmergency extends Component {

    state = {
      loading: false,
    };

    resumeProduction = () => {
      this.setState({loading: true});
      plantService.resumeProduction().then(() => {
        console.log('Resuming production...');
      }).catch(() => {
        this.setState({loading: false});
        alert('Error while resuming production');
      });
    };

    mapStatusToProps = () => {
      const {plant, plantStatus, tesStatus} = this.props;
      if (hasModuleActive({plant, module: plantModules.HELIOSTATS})) {
        return {
          lastSeenTimestamp: plantStatus.last_seen,
          isOnline: plantStatus.online,
          status: plantStatus.status
        };
      }
      if (hasModuleActive({plant, module: plantModules.TES}) && tesStatus.global) {
        // TODO: modules should have unified structure
        let status;
        if (tesStatus.global.emergency_stop !== undefined && parseInt(tesStatus.global.emergency_stop) === 1) {
          status = plantStatusTypes.MANUALLY_HALTED;
        }
        if (tesStatus.global.error_stop !== undefined && parseInt(tesStatus.global.error_stop) === 1) {
          status = plantStatusTypes.ERROR_HALTED;
        }
        return {
          lastSeenTimestamp: tesStatus.global.timestamp * 1000,
          isOnline: tesStatus.online,
          status: status,
          errorMessage: tesStatus.global.error_message
        };
      }
    };

    render() {
      const {loading} = this.state;
      const plantProps = this.mapStatusToProps();

      return (
        <PlantNotWorking
          isLoading={loading}
          onResumeButtonClick={this.resumeProduction.bind(this)}
          {...plantProps}
        />
      );
    }
}

MenuHaltEmergency.propTypes = {
  plant: PropTypes.object,
  plantStatus: PropTypes.object,
  tesStatus: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    plantStatus: state.plant.status,
    tesStatus: state.plant.tes.status
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuHaltEmergency);
