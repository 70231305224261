import React, { Component } from 'react';
import { Alert, Container, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {generatePath, withRouter} from 'react-router';
import PropTypes from 'prop-types';

import routes from '../../routes';
import config from '../../config';
import { ProjectsGrid } from '../../components/projectsGrid';
import {removeUrlsOverride} from '../../helpers/dynamicConfig';
import CustomSpinner from '../../components/operate/CustomSpinner';
import { projectService } from 'store/services/project';
import { projectsActions } from 'store/actions/projects';
import {menuActions} from '../../store/actions/menu';

export class DesignMain extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, projectsLoaded: false };
  }

  componentDidMount() {
    if (config.IS_DEMO) removeUrlsOverride();
    this.props.dispatch(menuActions.setDesignMenuCollapse(false));
    projectService.getList()
      .then(response => {
        this.setState({ isLoading: false, projectsLoaded: true, projects: response.data });
      }).catch(() => {
        this.setState({ isLoading: false, projectsLoaded: false });
      });
  }

  newProject = () => {
    const {dispatch} = this.props;
    dispatch(projectsActions.newProject());
    this.props.history.push(generatePath(routes.designLocation, {projectId: 'new'}));
  }

  loadProject = (project) => {
    const {dispatch} = this.props;
    return dispatch(projectsActions.loadProject(project));
  }

  render() {
    const { projects, isLoading, projectsLoaded } = this.state;
    return (
      <Container>
        <Button onClick={this.newProject} style={{float: 'right'}}>Start a new project</Button>
        <h1>
          Current projects&nbsp;
          {isLoading && <CustomSpinner size={24} color={'#212529'} />}
        </h1>
        {config.IS_DEMO &&
          <div style={{marginBottom: '20px'}}>
            You&apos;re in demo mode. We&apos;re collecting data from all servers so it could
            take longer than usual.
          </div>
        }
        {isLoading && (<Alert variant="info">Loading projects...</Alert>)}
        {!isLoading && !projectsLoaded && (<Alert variant="danger">Could not load projects.</Alert>)}
        {!isLoading && projectsLoaded && (<ProjectsGrid projects={projects} loadProject={this.loadProject} />)}
      </Container>
    );
  }
}

DesignMain.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object
};


function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withRouter(DesignMain));
