export const plantTesConstants = {
  GET_TES_STATUS_SUCCEEDED: 'GET_TES_STATUS_SUCCEEDED',
  UPDATE_TES_STATUS: 'UPDATE_TES_STATUS',
  GET_MODES_SUCCEEDED: 'GET_MODES_SUCCEEDED',
  UPDATE_TES_ONLINE: 'UPDATE_TES_ONLINE',
  SELECT_DEVICE: 'SELECT_DEVICE',
  UNSELECT_DEVICE: 'UNSELECT_DEVICE'
};

export const plantTesUrls = {
  GET_STATUS: '/plant/<plantId>/tes',
  GET_MODES: '/plant/<plantId>/tes/modes',
  SET_FLOW_MODE: '/plant/<plantId>/tes/set-flow-mode',
  SET_MAINTENANCE_MODE: '/plant/<plantId>/tes/set-maintenance-mode',
  SET_PUMP_FREQUENCY: '/plant/<plantId>/tes/pump/<id>/set-frequency',
  SET_VALVE_STATE: '/plant/<plantId>/tes/valve/<id>/set-state',
  SET_TANK_DESIRED_TEMPERATURE: '/plant/<plantId>/tes/tank/<id>/set-desired-temperature'
};

export const deviceTypes = {
  TANK: 1,
  HEATER: 2,
  HEX: 3,
  PUMP: 4,
  VALVE: 5,
  PIPE: 6
};

export const deviceTypesMap = {
  [deviceTypes.TANK]: 'Tank',
  [deviceTypes.HEATER]: 'Heater',
  [deviceTypes.HEX]: 'Hex',
  [deviceTypes.PUMP]: 'Pump',
  [deviceTypes.VALVE]: 'Valve',
  [deviceTypes.PIPE]: 'Pipe'
};

export const deviceStatus = {
  INACTIVE: 0,
  ACTIVE: 1,
  ERROR: 2
};

export const deviceStatusMap = {
  [deviceStatus.INACTIVE]: 'Inactive',
  [deviceStatus.ACTIVE]: 'Active',
  [deviceStatus.ERROR]: 'Error'
};
