import {eventLevel, eventsConstants} from '../constants/events';

const initialState = {
  events: [],
  criticalEvents: [],
  isEventSystemOn: false,
  loadingEvents: false,
  loadingEventsError: null,
  showOnlyCritical: false
};

export const events = (state = initialState, action) => {
  let event, newState;
  switch (action.type) {
    case eventsConstants.GETTING_EVENTS:
      return {...state, loadingEvents: true, loadingEventsError: null};
    case eventsConstants.GET_EVENTS:
      return {
        ...state,
        events: action.payload.events,
        criticalEvents: action.payload.critical_events,
        loadingEvents: false,
        loadingEventsError: null
      };
    case eventsConstants.GET_EVENTS_ERROR:
      return {...state, loadingEvents: false, loadingEventsError: action.payload};
    case eventsConstants.UPDATE_EVENTS:
      event = action.payload;
      newState = {
        ...state,
        events: [
          event,
          ...state.events
        ]
      };
      if (event.level === eventLevel.CRITICAL) newState.criticalEvents = [event, ...state.criticalEvents];
      return newState;
    case eventsConstants.REMOVE_EVENT:
      return {
        ...state,
        events: state.events.filter(event => event.id !== action.payload),
        criticalEvents: state.criticalEvents.filter(event => event.id !== action.payload),
      };
    case eventsConstants.CLEAR_EVENTS:
      return {
        ...state,
        events: [],
        criticalEvents: []
      };
    case eventsConstants.SET_SHOW_ONLY_CRITICAL:
      return {
        ...state,
        showOnlyCritical: action.payload
      };
    case eventsConstants.SET_EVENT_SYSTEM:
      return {
        ...state,
        isEventSystemOn: action.payload
      };
    default:
      return state;
  }
};
