import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../styles.scss';
import {formatLastSeen} from '../../../../helpers/utils';
import {connect} from 'react-redux';
import {isDeviceSelected} from '../../../../helpers/plant';
import {deviceStatus, deviceStatusMap, deviceTypes} from '../../../../store/constants/plantTes';
import {plantTesActions} from '../../../../store/actions/plantTes';
import {getTemperatureRow} from '../utils';
import {getIsMaintenanceMode} from '../../../../store/selectors/plant';

// TECHDEBT: move styles to component
// TECHDEBT: create more generic component for tables
class HeatersTable extends React.Component {

  getHeaterRow = (heater, index, isSelected) => {
    // cols: id, status, last seen
    return (
      <tbody key={`heater-${index}`}>
        <tr onClick={() => this.onHeaterClick(heater, isSelected)}
          className={clsx('table__row', {'table__row--selected': isSelected, 'table__row--error':
              heater.status === deviceStatus.ERROR})}>
          <td scope="row">{heater.object_id}</td>
          <td>{deviceStatusMap[heater.status]}</td>
          <td className={'table__cell'}>{formatLastSeen(heater.timestamp)}</td>
        </tr>
        {isSelected && heater.temperature_sensors ?
          heater.temperature_sensors.map((obj, idx) => getTemperatureRow(obj, idx, 3)):null}
      </tbody>
    );
  };

  isSelected = (heater) => {
    const {selectedDevices} = this.props;
    return isDeviceSelected({selectedDevices, type: deviceTypes.HEATER, device: heater});
  };

  onHeaterClick(heater, isSelected) {
    const {dispatch} = this.props;
    if (isSelected) dispatch(plantTesActions.unselectDevice(deviceTypes.HEATER, heater));
    else dispatch(plantTesActions.selectDevice(deviceTypes.HEATER, heater));
  }

  render() {
    const {heaters} = this.props;
    return (
      <div className={'table'}>
        <table>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Status</th>
              <th scope="col" className={'table__cell'}>Last seen</th>
            </tr>
          </thead>
          {
            heaters && heaters.length > 0 ? (
              <>
                {
                  heaters.map((heater, index) =>
                    (this.getHeaterRow(heater, index, this.isSelected(heater))))
                }
              </>) : <tbody>
              <tr>
                <td>No heaters</td>
              </tr>
            </tbody>
          }
        </table>
      </div>
    );
  }
}


HeatersTable.propTypes = {
  heaters: PropTypes.array,
  selectedDevices: PropTypes.array,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    selectedDevices: state.plant.selectedDevices
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeatersTable);
