import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import './StageSubtitle.scss';
import routes from '../routes';

export const StageSubtitle = ({ location }) => {
  const pathName = location.pathname;
  let stage;
  if (pathName.startsWith(routes.designMain))
    stage = 'Design';
  else if (pathName.startsWith(routes.buildMain))
    stage = 'Build';
  else if (pathName.startsWith(routes.operateMain))
    stage = 'Operate';
  else return null;
  return (<p className="stage-subtitle">{stage} stage</p>);
};

export default withRouter(StageSubtitle);

StageSubtitle.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};
