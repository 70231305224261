import React from 'react';
import PropTypes from 'prop-types';

export const PaddedMenuContainer = ({ padding, paddingTop, children }) => (
  <div style={{ paddingLeft: padding, paddingRight: padding, paddingTop: paddingTop }}>
    {children}
  </div>
);

PaddedMenuContainer.propTypes = {
  padding: PropTypes.number,
  paddingTop: PropTypes.number,
  children: PropTypes.any
};
